/**
 * Đưa vào cái stream, lấy ra hiển thị aggregate Trade Streams
 */

import { useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";

import { Badge, Button, Flex, Input, InputRef, Row, Slider, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ColumnsType, FilterConfirmProps } from 'antd/es/table/interface';
import { LineChartOutlined, SearchOutlined, } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import BinanceFuture from '../utils/BinanceFuture';
import { change, settingsEvent } from '../store/settings';
import i18n from "../services/i18n";
import { BtnCopy, SliderFight } from '../components';
import { percentChange } from '../utils/std';
import { StableCoins } from '../utils/AltcoinSeason';
import dayjs from 'dayjs';


const { log, } = console



type RowType = {
    symbol: string, point: number,
}

type RowIndex = keyof RowType;

type Props = {
    stream: BinanceFuture,
    symbol: string
}

const AggregateTradeStreams = <PROPS extends Props,>({ stream, symbol }: PROPS) => {
    const dispatch = useStoreDispatch();
    const { t } = i18n;
    const settings = useStore((state) => state.settings);
    const mounted = useRef(false);

    const [count, setcount] = useState(0)
    const [isConnected, setisConnected] = useState(false)

    const [aggregateTradeStreams, setaggregateTradeStreams] = useState<any[]>([])

    const [dataSource, setdataSource] = useState([])
    const [columns, setcolumns] = useState<ColumnsType>()



    useEffect(() => {
        if (!mounted.current) {
            setcolumns([{
                title: <Tooltip title={t("symbol")} color='blue'><div>{t("symbol")}</div></Tooltip>,
                dataIndex: "s",
                key: "s",
            }, {
                title: <Tooltip title={t("Price")} color='blue'><div>{t("Price")}</div></Tooltip>,
                dataIndex: "p",
                key: "p",
            }, {
                title: <Tooltip title={t("Quantity")} color='blue'><div>{t("Quantity")}</div></Tooltip>,
                dataIndex: "q",
                key: "q",
            }, {
                title: <Tooltip title={t("Quantity quote")} color='blue'><div>{t("Quantity quote")}</div></Tooltip>,
                dataIndex: "q",
                key: "qq",
                render: (value, record) => {
                    return (Number(value) * Number(record?.p)).toFixed(2)
                }
            }, {
                title: <Tooltip title={t("Trade time")} color='blue'><div>{t("Trade time")}</div></Tooltip>,
                dataIndex: "T",
                key: "T",
                render: (value, record) => dayjs(value).format("YY/MM/DD HH:mm:ss")
            }, {
                title: <Tooltip title={t("market maker")} color='blue'><div>{t("market maker")}</div></Tooltip>,
                dataIndex: "m",
                key: "m",
                render: (value, record) => value ? "true" : "false"
            }])
        }
        mounted.current = true;
    }, [])

    useEffect(() => {
        if (symbol && symbol !== "" && stream && stream?.ws?.readyState === 1) {
            stream?.subscribe(symbol, "aggTrade").then(stream => {
                stream.on(undefined, (r) => {
                    setisConnected(stream?.ws?.readyState === 1)

                    if (r.s.toLowerCase() === symbol.toLowerCase()) {
                        r.q = Number(r.q)
                        r.p = Number(r.p)
                        aggregateTradeStreams.unshift(r)
                        showTable()
                    }
                })
            })
        }
    }, [symbol])

    useEffect(() => {
        log(stream)
        setisConnected(stream?.ws?.readyState === 1)
        if (stream?.ws?.readyState === 1)
            stream.on(undefined, (r) => {
                setisConnected(stream?.ws?.readyState === 1)

                if (r.s.toLowerCase() === symbol.toLowerCase()) {
                    r.q = Number(r.q)
                    r.p = Number(r.p)
                    aggregateTradeStreams.unshift(r)
                    showTable()
                }
            })
    }, [stream, stream?.ws?.readyState])


    const showTable = () => {
        // tỉ lệ % của market maker

        let countMarketMaker = 0, // tỉ lệ % lệnh của market maker
            quantityQuoteMarketMaker = 0, // khối lượng quote của market maker
            sumQuantityQuote = 0,// tổng khối lượng quote  
            quantityMarketMaker = 0, // khối lượng của market maker
            sumQuantity = 0;// tổng khối lượng

        aggregateTradeStreams.map(trade => {
            let qq = trade.q * trade.p
            if (trade.m) {
                countMarketMaker++;
                quantityQuoteMarketMaker += qq
                quantityMarketMaker += (trade.q)
            }
            sumQuantityQuote += qq
            sumQuantity += trade.q
            return trade
        })

        let dataSource = [aggregateTradeStreams[0]]
        setdataSource(dataSource)

        columns.forEach((c) => {
            switch (c?.key) {
                case "m": // market maker
                    c.title = <Tooltip title={t("market maker")} color='blue'><div>
                        {t("market maker")}
                        <SliderFight percent={Number((countMarketMaker / aggregateTradeStreams.length * 100).toFixed())} />
                    </div></Tooltip>
                    break;

                case "q": // khối lượng
                    c.title = <Tooltip title={t("quantity")} color='blue'><div>
                        {t("quantity")}
                        <SliderFight percent={Number((quantityMarketMaker / sumQuantity * 100).toFixed())} />
                    </div></Tooltip>
                    break;

                case "qq": // khối lượng quote
                    c.title = <Tooltip title={t("quote")} color='blue'><div>
                        {t("quote")}
                        <SliderFight percent={Number((quantityQuoteMarketMaker / sumQuantityQuote * 100).toFixed())} />
                    </div></Tooltip>
                    break;
            }
        })
        setcolumns(columns)
    }

    return (<>
        <Table dataSource={dataSource} columns={columns} style={{ width: "100%" }} />
    </>);

}
export default AggregateTradeStreams;