import { FC, useEffect, useRef, useState, CSSProperties, } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Col, Form, InputNumber, Row, Tag } from 'antd';
import millify from "millify";
import i18n from '../services/i18n';

import "./InterestCompound.scss";
import BigNumber from 'bignumber.js';
import MathML from '../components/MathML';

interface EquationProps {
    A?: number | string,
    P?: number | string,
    r?: number | string,
    n?: number | string,
    t?: number | string,
}

const Equation: FC<EquationProps> = ({ A = 'A', P = 'P', r = 'r', n = 'n', t = 't' }) => {
    const html = `<math xmlns="http://www.w3.org/1998/Math/MathML">
        <semantics>
            <mrow>
                <mi>${typeof A === 'string' ? A : millify(A)}</mi>
                <mo>=</mo>
                <mi>${P}</mi>
                <msup>
                    <mrow>
                        <mo fence="true">(</mo>
                        <mn>1</mn>
                        <mo>+</mo>
                        <mfrac>
                            <mi>${r}</mi>
                            <mi>${n}</mi>
                        </mfrac>
                        <mo fence="true">)</mo>
                    </mrow>
                    <mrow>
                        <mi>${typeof n === "string" ? n : `${n}×`}</mi>
                        <mi>${t}</mi>
                    </mrow>
                </msup>
            </mrow>
        </semantics>
    </math>`

    return <MathML mathml={html} />
};

const InterestCompound = () => {
    const [states, setStates] = useState({
        A: 0,
        P: 1,
        r: 100,
        n: 5,
        t: 10
    })

    const [count, setcount] = useState(0)

    const mounted = useRef(false);
    const { t } = i18n

    // do componentDidMount logic
    useEffect(() => {
        if (!mounted.current) {
            calInterestCompound()
        }
        mounted.current = true;
    }, []);

    const onValueChanged = (name: string, value: number) => {
        states[name] = isNaN(Number(value)) ? 0 : value
        setcount(count + 1)
        setStates(states)
        calInterestCompound()
    }

    const calInterestCompound = () => {
        let { P, r, n } = states;
        const A = P * Math.pow((1 + ((r / 100) / n)), (n * states.t));
        setStates({ ...states, A })
        // setcount(count + 1)
    }

    const equationStyle: CSSProperties = {
        textAlign: "center",
        color: "#369507",
        fontSize: 30
    }

    return (
        <Content style={{ fontSize: "22px" }}>
            <Row justify="center">
                <Col md={12}>
                    <figure className="wp-block-table" style={equationStyle}>
                        <Equation />
                    </figure>

                    <ul>
                        <li><Tag className='tag'>A</Tag> là số tiền tương lai (tổng số tiền sau khi kết thúc kỳ hạn)</li>
                        <li><Tag className='tag'>P</Tag> là số tiền gốc ban đầu (principal)</li>
                        <li><Tag className='tag'>r</Tag> là lãi suất hàng ngày (tính theo dạng thập phân, ví dụ 5% thì r = 0.05)</li>
                        <li><Tag className='tag'>n</Tag> là số lần tính lãi trong một ngày</li>
                        <li><Tag className='tag'>t</Tag> là thời gian đầu tư (tính bằng ngày)</li>
                    </ul>
                    <p><em>Ví dụ:
                        <ul>
                            <li>Bạn có tiền vốn <Tag className='tag'>{states.P}$</Tag> (P) </li>
                            <li>giao dịch với lợi nhuận <Tag className='tag'>{states.r}%</Tag> 1 ngày (r)</li>
                            <li><Tag className='tag'>{states.n}</Tag> lệnh thắng liên tiếp trong 1 ngày (n)</li>
                            <li>Sau <Tag className='tag'>{states.t}</Tag> ngày (t)</li>
                            <li>Tổng số tiền (A) nhận được là <Tag className='tag'>{millify(states.A)}$</Tag></li>
                        </ul>
                    </em> </p>
                    <p><em>Áp dụng công thức lãi kép, ta có:
                        <p style={equationStyle}>
                            <Equation A={states.A} P={states.P} r={states.r} n={states.n} t={states.t} />
                        </p>

                        (Số tiền này bao gồm cả vốn lẫn lãi).</em></p>
                </Col>
            </Row >

            <Row justify="center">
                <Col md={12}>
                    <Form className='formInterestCompound' style={{ fontSize: "22px" }}>
                        <Form.Item label={t("P: Vốn ban đầu")}>
                            <InputNumber name={"P"} value={states.P}
                                onChange={v => onValueChanged("P", v)}
                                changeOnWheel={true}
                                style={{ width: "100%" }}
                                formatter={(value) => new BigNumber(value ?? 0).toFormat()} // 
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                            />
                        </Form.Item>

                        <Form.Item label={t("r: là lãi tổng hàng ngày (%)")}>
                            <InputNumber name={"r"} value={states.r}
                                onChange={v => onValueChanged("r", v)}
                                changeOnWheel={true}
                                style={{ width: "100%" }}
                                addonAfter={'%'}
                                formatter={(value) => new BigNumber(value ?? 0).toFormat()} // 
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                            />
                        </Form.Item>

                        <Form.Item label={t("n: số lần tính lãi trong một ngày")}>
                            <InputNumber name={"n"}
                                value={states.n} onChange={v => onValueChanged("n", v)}
                                changeOnWheel={true}
                                style={{ width: "100%" }}
                                formatter={(value) => new BigNumber(value ?? 0).toFormat()} // 
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                            />
                        </Form.Item>

                        <Form.Item label={t("t: là số ngày giao dịch")}>
                            <InputNumber name={"n"}
                                value={states.t} onChange={v => onValueChanged("t", v)}
                                changeOnWheel={true}
                                style={{ width: "100%" }}
                                formatter={(value) => new BigNumber(value ?? 0).toFormat()} // 
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                            />
                        </Form.Item>

                        <Form.Item label={t("Kết quả")}>
                            <InputNumber
                                value={states.A}
                                onChange={() => { }}
                                style={{ width: "100%", textAlign: 'end' }}
                                formatter={(value) => new BigNumber(value ?? 0).toFormat()} // 
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Content >
    )

}


export default InterestCompound;

