import { useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";

import { Badge, Button, Col, Flex, Input, InputRef, Row, Select, Slider, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ColumnsType, FilterConfirmProps } from 'antd/es/table/interface';
import { LineChartOutlined, SearchOutlined, } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import BinanceFuture from '../utils/BinanceFuture';
import { change, settingsEvent } from '../store/settings';
import i18n from "../services/i18n";
import { BtnCopy, SliderFight } from '../components';
import { percentChange } from '../utils/std';
import { StableCoins } from '../utils/AltcoinSeason';
import AggregateTradeStreams from '../components/AggregateTradeStreams';

import dayjs from 'dayjs';
import { PairInfo } from '../utils/Exchanges';
import AltcoinSeasonPointsChart from '../components/AltcoinSeasonPointsChart';


const { log, } = console



type RowType = {
    symbol: string, point: number,
}

type RowIndex = keyof RowType;

type Props = {

}

const Heatmap = <PROPS extends Props,>({ }: PROPS) => {
    const dispatch = useStoreDispatch();
    const { t } = i18n;
    const settings = useStore((state) => state.settings);
    const mounted = useRef(false);

    const [count, setcount] = useState(0)
    const [exchange, setexchange] = useState<BinanceFuture>(new BinanceFuture())
    const [isConnected, setisConnected] = useState(false)

    const [allSymbols, setallSymbols] = useState<any[]>([])
    const [symbol, setsymbol] = useState('')
    const [aggregateTradeStream, setaggregateTradeStream] = useState<BinanceFuture>()

    useEffect(() => {
        if (!mounted.current) {
            // setTimeout(() => {
            //     stream()
            // }, 1000);

            exchange.connect()

        }
        mounted.current = true;
    }, [])


    useEffect(() => {
        window.exchange = exchange
        setisConnected(exchange?.ws?.readyState === 1)

        exchange.getFutureAllSymbolsWiths().then((pairs: PairInfo[]) => {
            setallSymbols(pairs)
            if (!symbol || symbol === "") {
                setsymbol(pairs[0].symbol)
            }
        })
    }, [exchange?.ws?.readyState])

    const stream = async (method: string) => {

        let stream = window.stream = await exchange.subscribe(symbol, method)
        setaggregateTradeStream(stream)

        stream.on(undefined, (r) => {
            setisConnected(stream?.ws?.readyState === 1)
            log(r)
        })
    }

    const onSelectSymbol = (s: string) => {
        setsymbol(s)
    }


    return (<>


        <Badge.Ribbon text={t(isConnected ? "connected" : "closed")} color={isConnected ? "green" : "red"}>
            <Row style={{ alignItems: "center", display: "flex", justifyContent: "center", padding: "5px" }}>
                <b style={{ fontSize: "large" }}>
                    {t("Heatmap")}
                </b>&nbsp;
            </Row>

            <Flex>
                <Select options=
                    {allSymbols.map((s, i) => ({ value: s.symbol, label: `${s.baseAsset}-${s.quoteAsset}`, }))}
                    onChange={onSelectSymbol} value={symbol} showSearch
                />
                <Button onClick={e => stream('depth')}>depth</Button>
                <Button onClick={e => stream('aggTrade')}>aggTrade</Button>
            </Flex>
        </Badge.Ribbon>

        <Row>
            <Col span={12 + 6}>
                <AltcoinSeasonPointsChart />
            </Col>

            <Col span={6}>
                <AggregateTradeStreams exchange={aggregateTradeStream} symbol={symbol} />
            </Col>


        </Row>
    </>);

}
export default Heatmap;