import { EventEmitter } from "events"
import { PairPoint } from "../utils/AltcoinSeason";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import duration from 'dayjs/plugin/duration'
import BinanceFuture from "./BinanceFuture";

dayjs.extend(relativeTime)
dayjs.extend(duration)

const { error } = console;

type MapDownUnit = {
    [key: string]: any;
}
// Intervals: 1m,3m,5m,15m,30m,1h,2h,4h,6h,8h,12h,1d,3d,1w,1M
export class TIMEFRAMES {
    // s1: "1s",
    static m1 = "1m"
    static m3 = "3m"
    static m5 = "5m"
    static m15 = "15m"
    static m30 = "30m"
    static h1 = "1h"
    static h2 = "2h"
    static h4 = "4h"
    static h6 = "6h"
    static h8 = "8h"
    static d1 = "1d"
    static d3 = "3d"
    static w = "1w" // week
    static M1 = "1M" // month
    static M3 = "3M" // 3 months
    static Y1 = "1Y" // 1 year
    static Y3 = "3Y" // 1 year

    static toMiliSecond(timeframe = TIMEFRAMES.m5): number {
        switch (timeframe) {
            // case "1s":
            //     return 1000;
            case TIMEFRAMES.m1:
                return 60000; // 1000 * 60;
            case TIMEFRAMES.m3:
                return 180000; // 1000 * 60 * 3;
            case TIMEFRAMES.m5:
                return 300000; // 1000 * 60 * 5;
            case TIMEFRAMES.m15:
                return 900000; // 1000 * 60 * 15;
            case TIMEFRAMES.m30:
                return 1800000; // 1000 * 60 * 30;
            case TIMEFRAMES.h1:
                return 3600000; // 1000 * 60 * 60;
            case TIMEFRAMES.h2:
                return 7200000; // 1000 * 60 * 60 * 2;
            case TIMEFRAMES.h4:
                return 14400000; // 1000 * 60 * 60 * 4;
            case TIMEFRAMES.h6:
                return 21600000; // 1000 * 60 * 60 * 6;
            case TIMEFRAMES.h8:
                return 28800000; // 1000 * 60 * 60 * 8;
            case TIMEFRAMES.d1:
                return 86400000; // 1000 * 60 * 60 * 24;
            case TIMEFRAMES.d3:
                return 259200000; // 1000 * 60 * 60 * 24 * 3;
            case TIMEFRAMES.w: // week
                return 604800000; // 1000 * 60 * 60 * 24 * 7;
            case TIMEFRAMES.M1: // month
                return 2592000000; // 1000 * 60 * 60 * 24 * 30;
            case TIMEFRAMES.M3:
                return 7776000000; // 1000 * 60 * 60 * 24 * 30 * 3;
            case TIMEFRAMES.Y1:
                return 31536000000; // 1000 * 60 * 60 * 24 * 365;
            case TIMEFRAMES.Y3:
                return 94608000000; // 1000 * 60 * 60 * 24 * 365 * 3;

            default: // 1d
                return 86400000; // 1000 * 60 * 60 * 24;
        }
    }

    static plusTimeWait(timeframe = TIMEFRAMES.m5): number {
        switch (timeframe) {
            case "1s":
                return 1;
            case "1m":
                return 1;
            case "3m":
                return 1;
            case TIMEFRAMES.m5:
                return 1; // 1000 * 30;
            case "15m":
                return 40000; // 1000 * 40;
            case "30m":
                return 50000; // 1000 * 50;
            case "1h":
                return 60000; // 1000 * 60;
            case "4h":
                return 70000; // 1000 * 70;
            case "8h":
                return 80000; // 1000 * 80;
            case "1d":
                return 90000; // 1000 * 90;
            case "3d":
                return 100000; // 1000 * 100;
            case "1w": // week
                return 120000; // 1000 * 120;
            case "1M": // month
                return 140000; // 1000 * 140;
            case "3M": // month
                return 140000; // 1000 * 140;
            case "1Y": // month
                return 140000; // 1000 * 140;
            case "3Y": // month
                return 140000; // 1000 * 140;

            default: // 1d
                return 1000 * 90;
        }
    }

    /**
     * chuyển đổi từ timeframe sang số chu kì so với đơn vị, ví dụ 1h thì có bao nhiêu 1phút. hoặc bao nhiêu 15 phút 
     * @param {string} timeframe 
     * @param {string} periodTimeframe 
     * @return {number} period 
     */
    static toPeriod(timeframe: string = TIMEFRAMES.m5, periodTimeframe: string = TIMEFRAMES.m1): number {
        return Math.floor(TIMEFRAMES.toMiliSecond(timeframe) / TIMEFRAMES.toMiliSecond(periodTimeframe))
    }

    static mapDownUnit: MapDownUnit = {
        "m": "m",
        "h": "m",
        "d": "h",
        "w": "d",
        "M": "d",
        "Y": "M",
    }


    public static get names(): string[] {
        return Object.values(TIMEFRAMES).filter(v => typeof v === "string")
    }

    /**
     * hạ đơn vị, ví dụ: 1h => 1m, 4h => 1h
     * @param {string} timeframe 
     * @returns {string}
     */
    static downUnit(timeframe: string = TIMEFRAMES.m5): string {
        const regex = /^(\d+)([a-zA-Z]+)$/;
        const matches = timeframe.match(regex);
        if (matches) {
            const [_, number, unit] = matches;
            if (unit === "Y")
                return 1 + TIMEFRAMES.mapDownUnit[unit];
            else if (TIMEFRAMES.mapDownUnit[unit] === "m")
                return TIMEFRAMES.m5;
            else if (Number(number) === 1)
                return 1 + TIMEFRAMES.mapDownUnit[unit];
            else
                return 1 + unit;
        } else
            return ""
    }

    /**
     * tìm các timeframe lớn hơn cùng đơn vị , ví dụ: 1m => 3m, 5m,... 1h, 1h => 2h, 4h, 1d
     * @param {string} timeframe 
     * @returns {string[]}
     */
    static upUnits(timeframe: string = TIMEFRAMES.m5): string[] {
        // tìm phần tử cùng hoặc trên đơn vị có chung downUnit, ví dụ 1h => 1d
        let down = TIMEFRAMES.downUnit(timeframe)
        let time = TIMEFRAMES.toMiliSecond(timeframe)

        return Object.values(TIMEFRAMES).filter(v => typeof (v) == "string")
            .filter(v => ((TIMEFRAMES.toMiliSecond(v)) > time && (TIMEFRAMES.downUnit(v) == down)))
    }

    /**
     * lấy tất cả các đơn vị thời gian lớn hơn
     * @param {string} timeframe 
     * @returns {string[]}
     */
    static allUpUnits(timeframe: string = TIMEFRAMES.m5): string[] {
        let time = TIMEFRAMES.toMiliSecond(timeframe);
        return Object.values(TIMEFRAMES).filter(v => typeof (v) == "string")
            .filter(v => (time < TIMEFRAMES.toMiliSecond(v)));
    }

    /**
     * Tự động tính chu kì của timeframe có bao nhiêu chu kì con
     * @param {string} timeframe 
     * @returns {number}
     */
    static toPeriodDownUnit(timeframe: string = TIMEFRAMES.m5): number {
        return TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe))
    }

    static toColor(timeframe: string = TIMEFRAMES.m5) {
        type Color = {
            [key: string]: string
        }
        let Colors: Color = {
            m1Color: "#c0c0c0",
            m3Color: "#fbaed2",
            m5Color: "#4f81bc",
            m15Color: "#c0504e",
            m30Color: "#9bbb58",
            h1Color: "#23bfaa",
            h2Color: "#af69ee",
            h4Color: "#8064a1",
            h6Color: "#702963",
            h8Color: "#f79647",
            d1Color: "#3bb143",
            d3Color: "#0b6623",
            w1Color: "#066399", // week
            M1Color: "red", // month
            M3Color: "#5e3906",
            M6Color: "#325a04",
            Y1Color: "#9c27b0", // year
            Y3Color: "#92083a",
            Performed: "#4208ad",
            changed: "#4208ad",
            VolumeChange: "#ffc107",
            volume: "#ffc107",
            numberOfTrades: "#f0f8ffad",
            EMA48: "#4CAF50",
            EMA30: "#4CAF50",
            EMA96: "#ffeb3b",
            EMA60: "#ffeb3b",
            EMA288: "#ff5252",
            EMA240: "#ff5252",
            EMA864: "#0f92b4",
            EMA480: "#0f92b4",
        }
        if (Colors[timeframe])
            return Colors[timeframe]

        function splitstring(str: string) {
            const pattern = /([a-zA-Z]+)|(\d+)/g; // Regular expression để phân tách chữ và số
            const result = [];

            let match;
            while ((match = pattern.exec(str)) !== null) {
                if (match[1]) {
                    result.push(match[1]); // Thêm chữ vào mảng kết quả
                } else {
                    result.push(match[2]); // Thêm số vào mảng kết quả
                }
            }

            return result;
        }
        let tf = splitstring(timeframe)
        return Colors[tf[1] + tf[0] + "Color"]

    }
}

export type TIMEZONESType = { offset: number, name: string, deviation: number }

export const TIMEZONES: TIMEZONESType[] = [{
    "offset": -12, "name": "UTC-12:00", "deviation": -43200000
}, {
    "offset": -11, "name": "UTC-11:00", "deviation": -39600000
}, {
    "offset": -10, "name": "UTC-10:00", "deviation": -36000000
}, {
    "offset": -9.5, "name": "UTC-09:30", "deviation": -34200000
}, {
    "offset": -9, "name": "UTC-09:00", "deviation": -32400000
}, {
    "offset": -8, "name": "UTC-08:00", "deviation": -28800000
}, {
    "offset": -7, "name": "UTC-07:00", "deviation": -25200000
}, {
    "offset": -6, "name": "UTC-06:00", "deviation": -21600000
}, {
    "offset": -5, "name": "UTC-05:00", "deviation": -18000000
}, {
    "offset": -4, "name": "UTC-04:00", "deviation": -14400000
}, {
    "offset": -3.5, "name": "UTC-03:30", "deviation": -12600000
}, {
    "offset": -3, "name": "UTC-03:00", "deviation": -10800000
}, {
    "offset": -2, "name": "UTC-02:00", "deviation": -7200000
}, {
    "offset": -1, "name": "UTC-01:00", "deviation": -3600000
}, {
    "offset": 0, "name": "UTC±00:00", "deviation": 0
}, {
    "offset": 1, "name": "UTC+01:00", "deviation": 3600000
}, {
    "offset": 2, "name": "UTC+02:00", "deviation": 7200000
}, {
    "offset": 3, "name": "UTC+03:00", "deviation": 10800000
}, {
    "offset": 3.5, "name": "UTC+03:30", "deviation": 12600000
}, {
    "offset": 4, "name": "UTC+04:00", "deviation": 14400000
}, {
    "offset": 4.5, "name": "UTC+04:30", "deviation": 16200000
}, { "offset": 5, "name": "UTC+05:00", "deviation": 18000000 }, {
    "offset": 5.5, "name": "UTC+05:30", "deviation": 19800000
}, {
    "offset": 5.75, "name": "UTC+05:45", "deviation": 20700000
}, {
    "offset": 6, "name": "UTC+06:00", "deviation": 21600000
}, {
    "offset": 6.5, "name": "UTC+06:30", "deviation": 23400000
}, {
    "offset": 7, "name": "UTC+07:00", "deviation": 25200000
}, {
    "offset": 8, "name": "UTC+08:00", "deviation": 28800000
}, {
    "offset": 8.75, "name": "UTC+08:45", "deviation": 31500000
}, {
    "offset": 9, "name": "UTC+09:00", "deviation": 32400000
}, { "offset": 9.5, "name": "UTC+09:30", "deviation": 34200000 }, {
    "offset": 10, "name": "UTC+10:00", "deviation": 36000000
}, {
    "offset": 10.5, "name": "UTC+10:30", "deviation": 37800000
}, {
    "offset": 11, "name": "UTC+11:00", "deviation": 39600000
}, {
    "offset": 11.5, "name": "UTC+11:30", "deviation": 41400000
}, {
    "offset": 12, "name": "UTC+12:00", "deviation": 43200000
}, {
    "offset": 12.75, "name": "UTC+12:45", "deviation": 45900000
}, {
    "offset": 13, "name": "UTC+13:00", "deviation": 46800000
}]

/**
 * Chuyển đổi thời gian của giờ sang múi giờ khác, 
 * @param timestamp giờ cần chuyển đổi, đơn vị milisecond hoặc second
 * @param timezone múi giờ 
 * @param roundSecond có muốn làm tròn thành đơn vị giây không
 */
export function timeToTimezone(timestamp: number, timezone: TIMEZONESType, roundSecond = true): number {
    return roundSecond ? Math.floor((timestamp + timezone.deviation) / 1000) : (timestamp + timezone.deviation)
}

export type PairInfo = {
    symbol: string,
    // nến
    Klines?: Kline[],
    minTimeframe?: string,
    // altcoin season pair
    points?: {
        [timeframe: string]: PairPoint[]
    },
    emas?: {
        [timeframe: string]: {
            time: number,
            value: number
        }[]
    },
    [key: string]: any,
}

export const CandlestickMap = {
    OpenTime: 0,
    Open: 1,
    High: 2,
    Low: 3,
    Close: 4,
    Volume: 5,
    CloseTime: 6,
    QuoteAssetVolume: 7,
    NumberOfTrades: 8,
    TakerBuyBaseAssetVolume: 9,
    TakerBuyQuoteAssetVolume: 10,
}

type Kline = [
    OpenTime: number,
    Open: string,
    High: string,
    Low: string,
    Close: string,
    Volume: string,
    CloseTime: number,
    QuoteAssetVolume: string,
    NumberOfTrades: number,
    TakerBuyBaseAssetVolume: string,
    TakerBuyQuoteAssetVolume: string,
    Ignore: string,
]

export type RealTimeframes = {
    [name: string]: {
        left?: number,
        leftPercent?: number,
        leftString?: string,
        long: number,
        [key: string]: any
    }
}

export enum ORDERTYPE {
    BUY = "BUY",
    SELL = "SELL"
}


export enum ExchangeName {
    Binance = "Binance",
    BinanceFuture = "BinanceFuture",
    Bybit = "Bybit"
}

class Exchange extends EventEmitter {
    isConnected = false;
    name;

    /**
     * Tất cả các timeframe,
     *  left là thời gian miliseconds còn lại của timeframe đó
     *  long là độ dài miliseconds của timeframe đó 
     */
    Timeframes: RealTimeframes = Object.values(TIMEFRAMES)
        .map(tf => tf)
        .filter(tf => typeof (tf) === "string")
        .reduce((tfs, tf) => {
            tfs[tf] = {
                left: 0,
                leftPercent: 0,
                long: TIMEFRAMES.toMiliSecond(tf)
            }
            return tfs
        }, {})

    constructor() {
        super()
        this.timeframeTimers()
    }

    disconnect(): void { }

    getAll(futureOnly?: boolean): Promise<any[]> {
        return []
    }

    getAllSymbolsWiths(quoteAssets?: string[]): Promise<string[]> {
        return []
    }

    getAllSymbolsFutureWiths(quoteAssets?: string[]): Promise<string[]> {
        return []
    }

    getKlines(Symbol: string, StartTime: number, EndTime: number, timeframe: string, Limit: number = 1000): Promise<Kline[]> {
        return []
    }

    getSymbolsKlines(Symbols: string[], StartTime: number, EndTime: number, timeframe: string, index?: number, TimeWait?: number): Promise<any> {
        return undefined
    }


    /**
     * Có 1 danh sách symbols, lấy cái đầu tiên làm chuẩn
     * số nến ở các symbol = cái đầu tiên
     * @param {number} limit số lượng symbol, tức là lấy đủ thì thôi
     * @returns {EventEmitter} :
     *  emit @param getSymbolsKlinesLimit nếu lấy được nến 1 cặp tiền
     *  emit @param getSymbolsKlinesLimitDone nếu hoàn thành tất cả
     */
    getSymbolsKlinesLimit(Symbols: string[] = [], StartTime: number, EndTime: number, timeFrame: string = TIMEFRAMES.h1, limit = 50, isStartBefore = true): EventEmitter {
        return new EventEmitter()
    }

    getFutureAllSymbolsWiths(quoteAssets: string[] = ["USDT"]): Promise<PairInfo[]> {
        return []
    }
    getSpotAllSymbolsWiths(quoteAssets: string[] = ["USDT"]): Promise<PairInfo[]> {
        return []
    }

    /**
     * Tính thời gian còn lại của tất cả timeframe
     */
    timeframeTimers(Timeframes: string[] = Object.keys(this.Timeframes)) {

        const _timeframeTimers = async (i = 0) => {
            if (i < Timeframes.length) {
                try {
                    let timeframe = Timeframes[i]
                    if (!this.Timeframes[timeframe])
                        this.Timeframes[timeframe] = { long: TIMEFRAMES.toMiliSecond(timeframe) }

                    // lấy cây nến cuối
                    let Klines = await this.getKlines('BTCUSDT', Date.now() - this.Timeframes[timeframe].long, Date.now(), timeframe, 1);
                    if (Klines.length > 0) {
                        let closeTime = Number(Klines[Klines.length - 1][CandlestickMap.OpenTime]) + this.Timeframes[timeframe].long;
                        let left = closeTime - Date.now()
                        this.Timeframes[timeframe].left = left
                        this.Timeframes[timeframe].leftPercent = left / this.Timeframes[timeframe].long * 100

                        let customTemplateTimeDuration = Math.abs(left) > TIMEFRAMES.toMiliSecond(TIMEFRAMES.d1) ? "D[d] HH:mm" :
                            (Math.abs(left) > TIMEFRAMES.toMiliSecond(TIMEFRAMES.h1)) ? "H:m:ss" : "m:ss";
                        this.Timeframes[timeframe].leftString = dayjs.duration(left, 'milliseconds').format(customTemplateTimeDuration)

                        this.Timeframes[timeframe].loop = setInterval(() => {
                            left = closeTime - Date.now()
                            this.Timeframes[timeframe].left = left
                            this.Timeframes[timeframe].leftPercent = left / this.Timeframes[timeframe].long * 100

                            this.Timeframes[timeframe].leftString = dayjs.duration(left, 'milliseconds').format(customTemplateTimeDuration)
                            if (left <= 0)
                                closeTime = closeTime + this.Timeframes[timeframe].long
                        }, 1000);
                    }
                } catch (err) { error(err) }
                await _timeframeTimers(i + 1)
            }
        }
        _timeframeTimers()
    }


    subscribe?(symbol: string, method = "depth"): Promise<Exchange> {
        return undefined;
    }

    unsubscribe?(symbol: string, method = "depth") { }

    streamPrice(symbol: string) { }

}

export type { Kline }


export default Exchange