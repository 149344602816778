import Service from "./Service"

import AltcoinSeason, { AltcoinSeasonPoint, PairPoint, SmoothType, } from "../utils/AltcoinSeason"
import { Binance, Telegram } from "../utils"
import Exchange, { CandlestickMap, PairInfo, TIMEFRAMES } from "../utils/Exchanges"
import localforage from "localforage";
import { SMA } from "technicalindicators"
import { percentChange } from "../utils/std";
import dayjs from "dayjs";
import { MESSAGETYPE } from "../utils/Telegram";

const { log, } = console

export const default_listenTimeframes = [TIMEFRAMES.h1, TIMEFRAMES.h8, TIMEFRAMES.w, TIMEFRAMES.M1]

/**
 * 1 dịch vụ chạy ngầm
 * - Khởi động, lấy tất cả đồ thị các đồng coin top 50 market cap mà có trên future USDT
 * - Tính Altcoin season toàn thị trường
 * - Tính altcoin season của từng cặp
 * - Cứ 1 khoảng thời gian, lấy thêm dữ liệu nến, tính altcoin season của toàn thị trường và từng cặp
 * Những biến số thay đổi:
 * - Danh sách những cặp tiền Pairs
 * - Các khung thời gian để tính toán, Timeframe nhỏ nhất được dùng làm đơn vị nhỏ nhất và vòng lặp tự động tính listenTimeframes
 */
class AltcoinSeasonService extends Service {
    // Danh sách những cặp tiền và nến tương ứng
    private pairs: {
        // tên cặp tiền
        [symbol: string]: PairInfo
    } = {}

    // Các khung thời gian để tính toán, Timeframe nhỏ nhất được dùng làm đơn vị nhỏ nhất và vòng lặp tự động tính
    listenTimeframes: string[] = default_listenTimeframes

    exchange: Exchange
    altcoinSeason: AltcoinSeason

    // Số lượng cặp tiền muốn quét theo dõi
    amountPairs = 500
    startTime = 1651363200000
    isStartBefore = false
    futureOnly = true

    // altcoin season của toàn thị trường
    points: {
        [timeframe: string]: AltcoinSeasonPoint[]
    } = {}

    mode: {
        watch?: string // symbol / pair
    } = {}

    /**
     * lưu trữ thông số cài đặt của timeframe để lọc ra điều kiện mua bán:
     * - làm mượt smooth
     */
    timeframes: {
        [timeframe: string]: {
            active: boolean,
            smooth: number,
            min: number,
            max: number,
            inRange: boolean,
        }
    } = {
            '30m': {
                active: false,
                smooth: 9,
                min: -60,
                max: 60,
                inRange: false,
            },
        }

    /**
     * lưu trữ điểm altcoin mới nhất của từng pairs quét được, 
     * điểm này có thể thay đổi nếu người dùng sử dụng smooth tự động
     * Mỗi timeframe có 1 cài đặt smooth riêng
     */
    currentPoints: {
        [symbol: string]: {
            point?: number
            points?: {
                [timeframe: string]: {
                    volume: number,
                    changed: number,

                    // khoảng cách đường MA với giá đóng cửa
                    MA?: number,
                }
            },
            quoteVolume?: number,
            baseAsset?: string,
            quoteAsset?: string,
        }
    } = {}

    autoUpdate = true;
    sendAlert = false;


    /**
     * bot telegram
     */
    telegram: Telegram
    domain = "bot.coinx.trade"

    constructor() {
        super()
        this.getAmountPairs()
        this.getFutureOnly()

        localforage.getItem("AltcoinSeasonService").then((settings: any) => {

            if (settings && settings.timeframes) {
                this.timeframes = { ...this.timeframes, ...settings.timeframes }
            } else {
                localforage.setItem("AltcoinSeasonService", { ...settings, timeframes: this.timeframes })
            }

            if (settings && settings.telegram) {
                this.telegram = new Telegram(settings.telegram.token)
                this.telegram.signalsBot = settings.telegram.signalsBot
            } else {
                let token = "5774789751:AAF1DadM9LjK9FnonAbhJ_zwDRqy6WN7qD0",
                    chatId = 4063513524
                this.telegram = new Telegram(token)
                this.telegram.signalsBot = chatId
                localforage.setItem("AltcoinSeasonService", {
                    ...settings, telegram: {
                        token,
                        signalsBot: chatId
                    }
                })
            }
            if (settings && settings.isStartBefore !== undefined) {
                this.isStartBefore = settings.isStartBefore
            }
        })
    }

    /**
     * events:
     * newCandles: khi có 1 nến đóng, có thêm dữ liệu mới được cập nhật
     * @param { symbol: string, Klines: Kline[], info:any }
     */
    init(): Promise<typeof this.pairs> {
        return new Promise(async (rs, rj) => {
            // tất cả có trên future USDT, sắp xếp theo quoteVolume giảm dần 
            let pairs: any[] = this.futureOnly ? (await this.altcoinSeason.exchange.getFutureAllSymbolsWiths()) : (await this.altcoinSeason.exchange.getSpotAllSymbolsWiths())
            let allSymbols = pairs//.map(v =>  v.symbol)// {symbol: v.symbol, })
            this.emit("pairs", pairs)
            if (this.amountPairs > pairs.length)
                this.amountPairs = pairs.length;

            // Số lượng nến cần lấy
            let minTimeframe = this.listenTimeframes[0];

            let symbols = this.mode.watch ? [this.mode.watch] : pairs.map(v => v.symbol)

            // lấy tất cả đồ thị các đồng coin 
            let e = this.altcoinSeason.exchange.getSymbolsKlinesLimit(symbols, this.startTime, Date.now(), minTimeframe, this.amountPairs, this.isStartBefore)
                .on("getSymbolsKlinesLimit", ({ symbol, klines, countSymbolsKlines }) => {
                    // Duyệt nếu số lượng nến = với nến BTCUSDT thì lưu vào this.pairs
                    pairs.findIndex(p => {

                        if (symbol === p.symbol) {
                            this.pairs[symbol] = {
                                symbol,
                                ...p,
                                Klines: klines,
                                minTimeframe: minTimeframe,
                            }


                            if (!this.pairs[symbol].points)
                                this.pairs[symbol].points = {}
                            if (!this.pairs[symbol].smas)
                                this.pairs[symbol].smas = {}

                            this.emit("init", { symbol: symbol, pairs: this.pairs, klines, timeframe: minTimeframe, countSymbolsKlines, allSymbols })

                            let currentPoints: { [timeframe: string]: { volume: number, changed: number, MA?: number } };

                            // tính altcoin season và sma của pair
                            for (let i = 1; i < this.listenTimeframes.length; i++) {
                                const timeframe = this.listenTimeframes[i];
                                if (timeframe === minTimeframe)
                                    continue;

                                let period = TIMEFRAMES.toPeriod(timeframe, minTimeframe)

                                let points = this.altcoinSeason.calPair(klines, period)
                                if (points.length > 0) {
                                    this.pairs[symbol].points[timeframe] = points;

                                    // đưa vào currentPoints
                                    let { volume, changed } = points[points.length - 1]
                                    if (!currentPoints)
                                        currentPoints = {}
                                    currentPoints[timeframe] = { volume, changed }
                                    // nếu có cài smooth thì tính lại
                                }


                                if (period >= 12) {
                                    let smas = SMA.calculate({ period: period, values: klines.map(kl => Number(kl[CandlestickMap.Close])) })
                                    let startIndex = klines.length - smas.length

                                    if (smas.length > 0) {
                                        this.pairs[symbol].smas[timeframe] = smas.map((sma, i) => {
                                            return ({
                                                time: klines[i + startIndex][CandlestickMap.OpenTime],
                                                value: sma
                                            })
                                        })

                                        // đưa vào currentPoints
                                        // khoảng cách đường MA với giá đóng cửa
                                        if (currentPoints)
                                            currentPoints[timeframe].MA = percentChange(smas[smas.length - 1], Number(klines[klines.length - 1]?.[CandlestickMap.Close]))
                                    }
                                }
                            }

                            // đưa vào currentPoints
                            if (currentPoints) {
                                this.currentPoints[symbol] = {
                                    point: Object.values(currentPoints).reduce((s, tf) => s + (tf.changed ?? 0), 0) / Object.keys(currentPoints).length,
                                    points: currentPoints,
                                    quoteVolume: Number(p.quoteVolume),
                                    baseAsset: p.baseAsset,
                                    quoteAsset: p.quoteAsset,
                                }
                                if (!this.mode?.watch)
                                    localforage.setItem("currentPoints", this.currentPoints)
                                // kiểm tra xem hiện tại sau khi smooth thì điểm là bao nhiêu
                                this.alert(symbol, this.pairs[symbol].points)
                            }

                            return true;
                        }
                        return false
                    })
                    this.emit("pairs", this.pairs)
                })
                .on("getSymbolsKlinesLimitDone", ({ symbolsKlines }) => {
                    // lưu lại số pair theo dõi dựa trên dữ liệu đã lấy được
                    this.amountPairs = Object.keys(this.pairs).length
                    if (!this.mode?.watch) {
                        localforage.setItem("pairs", this.pairs)
                        localforage.getItem("AltcoinSeasonService").then((settings: any) => {
                            if (!this.mode?.watch)
                                localforage.setItem("AltcoinSeasonService", ({ ...settings, amountPairs: this.amountPairs, }))
                        })

                        // tính altcoin season BTC của từng timeframe
                        for (let i = 1; i < this.listenTimeframes.length; i++) {
                            if (this.listenTimeframes[i] === minTimeframe)
                                continue;

                            let altcoinSeasonPoints = this.altcoinSeason.calAltcoinSeasonByHolger(symbolsKlines, this.listenTimeframes[i], { amountOfTops: this.amountPairs })
                            this.points[this.listenTimeframes[i]] = altcoinSeasonPoints
                            this.emit("point", { timeframe: this.listenTimeframes[i], point: altcoinSeasonPoints })
                        }
                        this.emit("points", this.points)

                        localforage.setItem("points", this.points)
                    }

                    rs(this.pairs)
                })
                .off("getSymbolsKlinesLimitDone", () => { e.off("getSymbolsKlinesLimit", () => { }) })
        })
    }

    /**
     * lấy thêm cây nến mới: 
     */
    async getNewData() {
        if (!this.autoUpdate) {
            this.amountPairs = Object.keys(this.pairs).length
            this.emit("points", this.points)
            return;
        }

        let minTimeframe = this.listenTimeframes[0];
        // làm tuần tự, lấy từng cặp tiền symbol, bổ sung cây nến cuối tới hiện tại
        let pairs = Object.entries(this.pairs)

        const browse = async (index = 0) => {
            if (index < pairs.length && pairs[index][0]) {
                let [symbol, info] = pairs[index]
                if (!info) {
                    this.pairs[symbol] = info = { symbol, points: {}, smas: {}, Klines: [], minTimeframe }
                }
                let lastTime = this.startTime
                // nến cuối cùng đã có
                if (info && info?.Klines && info?.Klines.length > 0)
                    lastTime = Number(info.Klines[info.Klines.length - 1][CandlestickMap.OpenTime])
                else
                    info.Klines = []
                // lấy nến
                let Klines = await this.exchange.getKlines(symbol, lastTime, Date.now(), minTimeframe)
                // if (Number(Klines[Klines.length - 1][CandlestickMap.CloseTime]) - Number(Klines[Klines.length - 1][CandlestickMap.OpenTime]) !==
                //     (TIMEFRAMES.toMiliSecond(minTimeframe) - 1))
                //     Klines.pop()

                if (Klines.length > 0) {
                    info.Klines.pop()
                    // Nối cây nến
                    info.Klines = info.Klines.concat(Klines)

                    let currentPoints: { [timeframe: string]: { volume: number, changed: number, MA?: number } }

                    // tính altcoin pair của đoạn bị thiếu
                    for (let i = 1; i < this.listenTimeframes.length; i++) {
                        const timeframe = this.listenTimeframes[i];
                        if (timeframe === minTimeframe)
                            continue;

                        let period = TIMEFRAMES.toPeriod(timeframe, minTimeframe)

                        if (!this.pairs[symbol].points[timeframe])
                            this.pairs[symbol].points[timeframe] = []
                        if (!this.pairs[symbol].smas[timeframe])
                            this.pairs[symbol].smas[timeframe] = []

                        // Tính altcoin season pair
                        // tách nến của đoạn cần tính
                        let pointsOld = this.pairs[symbol].points[timeframe]

                        // log(timeframe, this.pairs[symbol], pointsOld)
                        // if (!pointsOld || pointsOld.length < 1) {
                        //     continue;
                        // }

                        // tìm nến của đoạn cuối cùng
                        let index = info.Klines.findIndex(k => k[CandlestickMap.OpenTime] === pointsOld[pointsOld.length - 1]?.Time)

                        let newKlines = (index + 1 >= period) ? info.Klines.slice(index + 1 - period) : info.Klines

                        let pointsNew = this.altcoinSeason.calPair(newKlines, period)
                        // nối vào
                        if (pointsNew.length > 0) {
                            this.pairs[symbol].points[timeframe] = this.pairs[symbol].points[timeframe].concat(pointsNew)
                                .filter((v, i, self) => ((i + 1) < self.length && v.Time !== self[i + 1].Time) || (i + 1) === self.length)

                            // đưa vào currentPoints
                            let { volume, changed } = this.pairs[symbol].points[timeframe][this.pairs[symbol].points[timeframe].length - 1]
                            if (!currentPoints)
                                currentPoints = {}
                            currentPoints[timeframe] = { volume, changed }
                        }

                        // tính MA
                        if (period >= 12) {
                            let smasNew = SMA.calculate({ period: period, values: newKlines.map(kl => Number(kl[CandlestickMap.Close])) });
                            if (smasNew.length > 0) {
                                let deviation = newKlines.length - smasNew.length;
                                for (let i = 0; i < smasNew.length; i++) {
                                    this.pairs[symbol].smas[timeframe]?.push({
                                        time: <number>newKlines[i + deviation][CandlestickMap.OpenTime],
                                        value: smasNew[i]
                                    })
                                }

                                // đưa vào currentPoints
                                // khoảng cách đường MA với giá đóng cửa
                                if (currentPoints)
                                    currentPoints[timeframe].MA = percentChange(smasNew[smasNew.length - 1], Number(newKlines[newKlines.length - 1]?.[CandlestickMap.Close]))
                            }
                        }
                    }
                    // đưa vào currentPoints
                    if (currentPoints) {
                        this.currentPoints[symbol] = {
                            point: Object.values(currentPoints).reduce((s, tf) => s + (tf.changed ?? 0), 0) / Object.keys(currentPoints).length,
                            points: currentPoints,
                            quoteVolume: Number(info.quoteVolume),
                            baseAsset: info.baseAsset,
                            quoteAsset: info.quoteAsset,
                        }
                        if (!this.mode?.watch)
                            localforage.setItem("currentPoints", this.currentPoints)
                        // kiểm tra xem hiện tại sau khi smooth thì điểm là bao nhiêu
                        this.alert(symbol, this.pairs[symbol].points)
                    }

                    this.emit("newCandles", ({ symbol: symbol, info, index, length: pairs.length }))
                    await browse(index + 1)
                }
            }
        }

        await browse().then(() => {
            this.amountPairs = Object.keys(this.pairs).length
            // tính altcoinSeason của đoạn bị thiếu
            if (!this.mode?.watch) {
                localforage.setItem("pairs", this.pairs)
                // lưu lại số pair theo dõi dựa trên dữ liệu đã lấy được
                localforage.getItem("AltcoinSeasonService").then((settings: any) => {
                    localforage.setItem("AltcoinSeasonService", ({ ...settings, amountPairs: this.amountPairs, }))
                })

                // tính altcoin season của từng timeframe
                let symbolsKlines = Object.values(this.pairs).reduce((s, v) => { s[v.symbol] = v.Klines; return s }, {})

                for (let i = 1; i < this.listenTimeframes.length; i++) {
                    if (this.listenTimeframes[i] === minTimeframe)
                        continue;

                    let altcoinSeasonPoints = this.altcoinSeason.calAltcoinSeasonByHolger(symbolsKlines, this.listenTimeframes[i], { amountOfTops: this.amountPairs, SymbolAltcoin: "BTCUSDT" })
                    this.points[this.listenTimeframes[i]] = altcoinSeasonPoints
                    this.emit("point", { timeframe: this.listenTimeframes[i], point: altcoinSeasonPoints })
                }
                this.emit("points", this.points)
                localforage.setItem("points", this.points)
            }
        })
        if (this.mode?.watch) {
            let pairs: any[] = this.futureOnly ? (await this.altcoinSeason.exchange.getFutureAllSymbolsWiths()) : (await this.altcoinSeason.exchange.getSpotAllSymbolsWiths())
            this.emit("allSymbols", { allSymbols: pairs })
        }
    }

    /**
     * Tự động tính altcoin của các khung thời gian, lấy khung thời gian nhỏ nhất làm vòng lặp tính
     * @param timeframes mảng các khung thời gian để tính toán
     */
    timers() {
        this.altcoinSeason.timer(this.listenTimeframes[0], this.getNewData.bind(this))
    }

    /**
     * Khi đang chạy mà người dùng muốn thêm 1 timeframe
     * @param timeframe 
     */
    addTimeframe(timeframe: string) {
        this.listenTimeframes.push(timeframe)
        this.setListenTimeframes(this.listenTimeframes)

        const minTimeframe = this.listenTimeframes[0];
        let period = TIMEFRAMES.toPeriod(timeframe, minTimeframe)
        // Tính altcoin season pair
        // duyệt tất cả các symbol
        Object.entries(this.pairs).forEach(([symbol, info]) => {
            let points = this.altcoinSeason.calPair(info.Klines, period)
            // nối vào
            info.points[timeframe] = points
                .filter((v, i, self) => ((i + 1) < self.length && v.Time !== self[i + 1].Time) || (i + 1) === self.length)

            // tính sma  
            if (period >= 12) {
                let smas = SMA.calculate({ period: period, values: info.Klines.map(kl => Number(kl[CandlestickMap.Close])) })

                let startIndex = info.Klines.length - smas.length

                info.smas[timeframe] = smas.map((sma, i) => ({
                    time: <number>info.Klines[i + startIndex][CandlestickMap.OpenTime],
                    value: sma
                }))
            }
            this.emit("newTimeframe", ({ ...info }))
        })
        if (!this.mode?.watch)
            localforage.setItem("pairs", this.pairs)

        let symbolsKlines = Object.values(this.pairs).reduce((s, v) => { s[v.symbol] = v.Klines; return s }, {})

        if (!this.mode?.watch) {
            let altcoinSeasonPoints = this.altcoinSeason.calAltcoinSeasonByHolger(symbolsKlines, timeframe, { amountOfTops: this.amountPairs })
            this.points[timeframe] = altcoinSeasonPoints
            this.emit("points", this.points)
            localforage.setItem("points", this.points)
        }
    }

    /*************************** */
    async start(params?: any) {
        if (params) {
            if (params.watch)
                this.mode.watch = params.watch
        }

        // lấy dữ liệu cũ lên
        await localforage.getItem("points").then(points => {
            if (points) {
                this.points = points as typeof this.points
                this.emit("points", this.points)
            }
        })
        await localforage.getItem("pairs").then((pairs: typeof this.pairs) => {
            if (pairs) {
                if (this.mode?.watch) {
                    this.pairs = {
                        [this.mode?.watch]:
                            Object.values(pairs).find((info) => info.symbol === this.mode?.watch),
                    }
                } else
                    this.pairs = pairs as typeof this.pairs
                this.emit("pairs", this.pairs)
            }
        })
        if (!this.mode?.watch)
            await localforage.getItem("currentPoints").then(points => {
                if (points) {
                    this.currentPoints = points as typeof this.currentPoints
                    this.emit("pairs", this.currentPoints)
                }
            })

        return await localforage.getItem("AltcoinSeasonService").then((_settings: any) => {
            let settings = { amountPairs: 200, listenTimeframes: default_listenTimeframes };
            if (!_settings) {
                if (!this.mode?.watch)
                    localforage.setItem("AltcoinSeasonService", settings);
            } else
                settings = { ...settings, ..._settings }
            let listenTimeframes = settings?.listenTimeframes ? [...new Set(settings?.listenTimeframes)] : []

            let exchange = new Binance()

            setTimeout(() => {
                setInterval(() => {
                    if (!exchange.ws || exchange.ws.readyState !== 1) {
                        exchange.reconnect()
                    }
                }, 1000)
            }, 5000);

            this.listenTimeframes = listenTimeframes.sort((a, b) => TIMEFRAMES.toMiliSecond(a) - TIMEFRAMES.toMiliSecond(b))
            this.altcoinSeason = new AltcoinSeason(exchange)
            this.exchange = exchange

            // nếu dữ liệu cũ đã có và listenTimeframes giống thì lấy dữ liệu mới ghép vào
            let timeframes = Object.keys(this.points)

            if (timeframes.length > 0
                && timeframes.every((tf, i) => (
                    (i === 0 || this.listenTimeframes.includes(tf))
                )
                    && (this.listenTimeframes.length - 1) === timeframes.length)) {

                this.getNewData().then(this.timers.bind(this))
            } else
                this.init().then(this.timers.bind(this))
        })
    }
    /*************************** */

    stop() { }


    get Pairs(): typeof this.pairs {
        return this.pairs
    }

    /**
     * listenTimeframes
     * @param timeframes 
     */
    setListenTimeframes(timeframes: typeof this.listenTimeframes) {
        this.listenTimeframes = [... new Set(timeframes)].sort((a, b) => TIMEFRAMES.toMiliSecond(a) - TIMEFRAMES.toMiliSecond(b));
        localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            localforage.setItem("AltcoinSeasonService", ({ ...settings, listenTimeframes: timeframes, }))
        })
    }

    getListenTimeframes(): Promise<typeof this.listenTimeframes> {
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            if (settings?.listenTimeframes) {
                // log(settings?.listenTimeframes)
                this.listenTimeframes = <typeof this.listenTimeframes>settings?.listenTimeframes || default_listenTimeframes
                return <typeof this.listenTimeframes>settings?.listenTimeframes || default_listenTimeframes;
            }
            return default_listenTimeframes;
        })
    }


    /**
     * AmountPairs
     * @param timeframes 
     */
    setAmountPairs(amount: number) {
        this.amountPairs = amount

        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            return localforage.setItem("AltcoinSeasonService", ({ ...settings, amountPairs: amount, }))
        })
    }

    getAmountPairs(): Promise<number> {
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            if (settings?.amountPairs) {
                this.amountPairs = settings?.amountPairs
                return <typeof this.amountPairs>settings?.amountPairs;
            }
            return this.amountPairs
        })
    }

    setFutureOnly(state = true) {
        this.futureOnly = state
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            return localforage.setItem("AltcoinSeasonService", ({ ...settings, futureOnly: state, }))
        })
    }

    getFutureOnly(): Promise<boolean> {
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            if (settings) {
                this.futureOnly = settings?.futureOnly || false
                return <boolean>settings?.futureOnly;
            }
            return this.futureOnly
        })
    }

    setSendAlert(state = true) {
        this.sendAlert = state
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            return localforage.setItem("AltcoinSeasonService", ({ ...settings, sendAlert: state, }))
        })
    }

    getSendAlert(): Promise<boolean> {
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            if (settings) {
                this.sendAlert = settings?.sendAlert || false
                return <boolean>settings?.sendAlert;
            }
            return this.sendAlert
        })
    }

    setStartTime(dateTime: dayjs.Dayjs) {
        if (dateTime.valueOf() < (dayjs().valueOf() - TIMEFRAMES.toMiliSecond(this.listenTimeframes[0]))) {
            this.startTime = dateTime.valueOf();
        } else
            throw {
                message: "Date time must be before (now - minTimeframe)",
                before: dayjs().subtract(TIMEFRAMES.toMiliSecond(this.listenTimeframes[0]), "milliseconds").valueOf()
            }
    }

    async changeisStartBefore() {
        this.isStartBefore = !this.isStartBefore
        const settings: any = await localforage.getItem("AltcoinSeasonService");
        return await localforage.setItem("AltcoinSeasonService", ({ ...settings, isStartBefore: this.isStartBefore, }));
    }

    changeTimeframes(keyPath: string, value: any): Promise<any> {
        let path = keyPath.split(".")
        let obj = this.timeframes[path[0]]

        if (!obj)
            this.timeframes[path[0]] = obj = {
                active: false,
                smooth: 7,
                min: 15,
                max: 85,
                inRange: false,
            }
        obj[path[1]] = value

        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            return localforage.setItem("AltcoinSeasonService", { ...settings, timeframes: this.timeframes })
        })
    }

    async deactiveTimeframes() {
        Object.values(this.timeframes).forEach((tf: any) => tf.active = false)
        return localforage.getItem("AltcoinSeasonService").then((settings: any) => {
            return localforage.setItem("AltcoinSeasonService", { ...settings, timeframes: this.timeframes })
        })
    }

    cleanData() {
        localforage.removeItem("pairs")
        localforage.removeItem("currentPoints")
        return localforage.removeItem("points")
    }

    reset() {
        localforage.removeItem("pairs")
        localforage.removeItem("points")
        return localforage.removeItem("AltcoinSeasonService")
    }

    smooth(
        pointsType = "PairPointTypes",
        points: PairPoint[] | AltcoinSeasonPoint[],
        period: number,
        smoothType: string = SmoothType.SMA): PairPoint[] | AltcoinSeasonPoint[] {

        switch (pointsType) {
            case "PairPointTypes":
                return this.altcoinSeason.smoothPair(points, period, smoothType);

            case "AltcoinSeasonPointTypes":
                return this.altcoinSeason.smoothPoints(points, period, smoothType);

            default:
                return this.altcoinSeason.smoothPair(points, period, smoothType);
        }
    }

    /**
     * sau khi có được điểm altcoin pair, tính MA của điểm hiện tại
     * nếu điểm đồng thuận lớn hơn MAX, nhỏ hơn MIN thì báo động
     * @param {[timeframe: string]: PairPoint[]} timeframes các timeframe và điểm chỉ số
     * @param {number} period chu kì làm mượt dữ liệu
     * @param {number} min nhỏ hơn số min thì đạt yêu cầu
     * @param {number} max lớn hơn số max thì đạt yêu cầu
     * @param {SmoothType} smoothType kiểu làm mượt dữ liệu
     */
    alert(symbol: string, timeframePoints: { [timeframe: string]: PairPoint[] } = {}, renew = false) {

        let count = 0, qualified = 0;
        Object.entries(timeframePoints).forEach(([timeframe, points]) => {
            count++;
            if (this.timeframes[timeframe] && (this.timeframes[timeframe].active === true || this.timeframes[timeframe].active === 1)) {
                let { smooth, min, max, inRange } = this.timeframes[timeframe];

                if (points.length - smooth >= 0) {

                    // chỉ lấy đoạn dữ liệu đủ để tính điểm hiện tại
                    let _points = this.altcoinSeason.smoothPair(points.slice((points.length - smooth - 1 >= 0) ? (points.length - smooth - 1) : 0), smooth, SmoothType.SMA);

                    if (_points && points[_points.length - 1]) {
                        const lastPoint = _points[_points.length - 1];
                        const prelastPoint = _points[_points.length - 2];

                        this.currentPoints[symbol].points[timeframe].volume = lastPoint.volume;
                        this.currentPoints[symbol].points[timeframe].changed = lastPoint.changed;

                        let message: string
                        if (inRange) {
                            if ((lastPoint.volume >= min) && (lastPoint.volume <= max)) {
                                if (lastPoint.volume < 0) {
                                    qualified++
                                    message = `🟢 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} LONG BUY ↥ ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                    this.sendTele(message, MESSAGETYPE.success)
                                }
                                else if (lastPoint.volume >= 0) {
                                    message = `🔴 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} SELL SHORT ↧ ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                    this.sendTele(message, MESSAGETYPE.error)
                                }
                            }
                        } else {
                            // nếu nhỏ hơn min thì báo
                            if (lastPoint.volume < min) {
                                qualified++
                                message = `🟢 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} LONG BUY ↥ ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                this.sendTele(message, MESSAGETYPE.success)
                            }
                            // nếu lớn hơn max thì báo
                            else if (lastPoint.volume > max) {
                                message = `🔴 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} SELL SHORT ↧ ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                this.sendTele(message, MESSAGETYPE.error)
                            }

                            // nếu vừa vượt qua 0 thì báo
                            else if (prelastPoint.changed <= 0 && lastPoint.changed >= 0) {
                                message = `🟢 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} ↗ LONG BUY  ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                this.sendTele(message, MESSAGETYPE.success)
                            }
                            else if (prelastPoint.changed >= 0 && lastPoint.changed <= 0) {
                                message = `🔴 <a href="${this.domain}/pairs/${symbol}">${symbol}</a>  ${timeframe} ↘ SELL SHORT  ${dayjs(lastPoint.Time).format("YYYY-M-D H:m")}`
                                this.sendTele(message, MESSAGETYPE.success)
                            }
                        }
                    }
                }
            }
            // nếu cần tính lại hết do thay đổi thông số timeframes
            else if (renew) {
                const lastPoint = points[points.length - 1];
                this.currentPoints[symbol].points[timeframe].volume = lastPoint.volume;
                this.currentPoints[symbol].points[timeframe].changed = lastPoint.changed;
            }

            // nếu sau khi trung bình trước đó ko có điểm nào vượt qua 0, mà hiện tại vượt qua 0 thì thông báo
            // ví dụ: 8h/5m=96, sau khi MA(96), đếm xem có khi nào nó vượt qua mức giới hạn đó hay ko

        })

        // tính lại điểm trung bình tổng
        if (this.currentPoints[symbol]?.points)
            this.currentPoints[symbol].point = Object.values(this.currentPoints[symbol].points)
                .reduce((s, tf) => s + tf.changed, 0) / Object.keys(this.currentPoints[symbol].points).length;
        else
            log(symbol, this.currentPoints[symbol])
    }

    /**
     * gửi thông báo tới nhóm tele
     * @param message nội dung
     */
    sendTele(message = "", type: MESSAGETYPE = MESSAGETYPE.success) {
        if (this.sendAlert)
            return this.telegram.send(message, this.telegram.signalsBot, type)
    }
}

export default AltcoinSeasonService;