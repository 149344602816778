import { EventEmitter } from "events";
import AltcoinSeasonService from "./AltcoinSeasonService";
import Service from "./Service";
import HeatService from "./HeatService";

const { log, error } = console;

/**
 * Dịch vụ có các chức năng:
 * Đăng kí dịch vụ
 * Khởi chạy dịch vụ
 * Tắt dịch vụ
 */


export { AltcoinSeasonService, Service, HeatService };