import { EventEmitter } from 'events';
import { v4 as uuid } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { StableCoins, filterDuplicateBaseAssets, FIATS } from './AltcoinSeason';
import Coinmarketcap from './Coinmarketcap';
import Exchange, { TIMEFRAMES, Kline, CandlestickMap, ExchangeName, } from './Exchanges';
import { PairInfo } from './Exchanges';

const { log, error } = console

export interface Trade {
    "e": string,    // "trade",   // Event type
    "E": number,    // Event time
    "s": string,    // "BNBBTC", // Symbol
    "t": number,    // 12345, // Trade ID
    "p": string,    // "0.001",     // Price
    "q": string,    // "100",     // Quantity
    "T": number,    // Trade time
    "m": true,      // Is the buyer the market maker?
    "M": true
}

class Binance extends Exchange {
    name = ExchangeName.Binance

    ws: WebSocket
    url: string

    constructor(url = "wss://ws-api.binance.com:443/ws-api/v3") {
        super()
        this.ws = new WebSocket(url)
        log("Start connect", url)

        this.url = url
        this.ws.onerror = (err: any) => {
            this.isConnected = false;
            if (err)
                error(err);
        };

        // mở websocket server
        this.ws.onopen = (e: any) => {
            this.isConnected = true
            let loop = setInterval(() => {
                if (this.ws.readyState == 1) {
                    this.emit("connected", e, this)
                    clearInterval(loop)
                    let ping = setInterval(() => {
                        if (this.ws.readyState === 1)
                            this.ws.send(JSON.stringify({
                                method: 'PING',
                                id: 'PING' + Date.now(),
                            }))
                        if (this.ws.readyState === WebSocket.CLOSED)
                            clearInterval(ping)
                    }, 3000)
                }
            }, 50);
        }

        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        this.ws.onmessage = (msg: any) => {
            // console.log(msg.data);
            let data = JSON.parse(msg.data)
            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data)
            }
            this.emit(data.id, data)
        }

        // Khi mất kết nối với Binance thì tắt chương trình
        this.ws.onclose = (r: any) => {
            this.isConnected = false;
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài")
            }
            console.error("WebSocket.onclosed", r);
            this.emit("closed", r)
        }

    }


    Send(object: any) {
        let loop = setInterval(() => {
            if (this.ws.readyState == 1) {
                this.ws.send(JSON.stringify(object))
                clearInterval(loop)
            }
        }, 50);
    }

    disconnect() {
        try {
            this.ws.close()
            this.ws.onopen = this.ws.onmessage = this.ws.onclose = this.ws.onerror = null;
        } catch (err) { }
    }

    reconnect() {
        this.disconnect()
        this.ws = new WebSocket(this.url)

        this.ws.onerror = (err: any) => {
            this.isConnected = false;
            if (err) {
                error(err);
                this.emit("error", err)
            }
        };

        // mở websocket server
        this.ws.onopen = (e: any) => {
            this.isConnected = true
            let loop = setInterval(() => {
                if (this.ws.readyState == 1) {
                    this.emit("connected", e, this)
                    clearInterval(loop)
                    let ping = setInterval(() => {
                        this.ws.send(JSON.stringify({
                            method: 'PING',
                            id: 'PING' + Date.now(),
                        }))
                        if (this.ws.readyState === WebSocket.CLOSED)
                            clearInterval(ping)
                    }, 3000)
                }
            }, 50);
        }

        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        this.ws.onmessage = (msg: any) => {
            // console.log(msg.data);
            let data = JSON.parse(msg.data)
            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data)
            }
            this.emit(data.id, data)
        }

        // Khi mất kết nối với Binance thì tắt chương trình
        this.ws.onclose = (r: any) => {
            this.isConnected = false;
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài")
            }
            console.error("WebSocket.onclosed", r);
            this.emit("closed", r)
        }

        return this.ws;
    }

    /**
     * lấy danh sách toàn bộ coins Spot mà có cặp với USDT, BUSD
     * @param {string[]} quoteAssets 
     * @returns {string[]} danh sách các symbols
     */
    async getAllSymbolsWiths(quoteAssets: string[] = StableCoins): Promise<string[]> {
        return new Promise((rs, rj) => {
            let Ename = "getAllSymbolsWiths";
            // làm gì đó sau khi có kết quả trả về
            this.once(Ename, (r) => {
                try {
                    // lọc cặp với quoteAssets và không phải cặp BUSD/USDT..
                    let symbols = r.result.map((v: any) => v.symbol as string).filter((v: any) => !v.match(/BEAR|BULL|UP|DOWN/))
                        .filter((v: any) =>
                            v.match(`(.+)(${quoteAssets.join("|")})$`)
                            && !v.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`)
                        )

                    // lọc trùng
                    symbols = filterDuplicateBaseAssets(symbols, quoteAssets)
                    rs(symbols);
                } catch (err) {
                    error(r);
                    rj(err)
                }
            })

            // gửi yêu cầu lên server 
            this.Send({
                "id": Ename,
                "method": "ticker.24hr",
            })
        })
    }


    /**
     * lấy danh sách toàn bộ coins Future mà có cặp với USDT, BUSD
     * @param {string[]} quoteAssets 
     * @returns {string[]} danh sách các symbols
     */
    async getAllSymbolsFutureWiths(quoteAssets: string[] = StableCoins): Promise<string[]> {

        let r: AxiosResponse<any, any> & { error: any } = await axios.get("https://fapi.binance.com/fapi/v1/ticker/24hr")
        if (r.error)
            throw r.error
        // lọc cặp với quoteAssets và không phải cặp BUSD/USDT..
        let symbols = r.data.map((v: { symbol: any; }) => v.symbol).filter((v: string) => !v.match(/BEAR|BULL|UP|DOWN/))
            .filter((v: string) =>
                v.match(`(.+)(${quoteAssets.join("|")})$`)
                && !v.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`)
            )

        // lọc trùng
        return filterDuplicateBaseAssets(symbols, quoteAssets)
    }

    /**
     * lấy biểu đồ nến của 1 cặp symbol
     * @param {string} Symbol 
     * @param {dayjs} StartTime 
     * @param {dayjs} EndTime 
     * @param {string} timeframe 
     * @param {number} Limit 
     * @returns {Klines}
     */
    getKlines(Symbol: string, StartTime: number, EndTime: number, timeframe: string = "1d", Limit: number = 1000): Promise<Kline[]> {
        if (!Symbol)
            throw new Error("Symbol undefined: " + Symbol)
        return new Promise((rs, rj) => {
            // let t = Math.round(Date.now() / 1000).toString()
            // t = t.slice(t.length / 2 - 1)
            let params: { symbol: string; interval: string; startTime: number; endTime: number; limit: number; }
            let Ename = "getKlines" + Symbol + timeframe + uuid().slice(-4);
            if (Ename.length > 34) {
                error("quá dài Ename ", Ename, Ename.length, Symbol, timeframe)
                this.emit("getKlines", {
                    error: Ename + " Ename quá dài " + Ename.length,
                    Ename: Ename
                })
            }
            // làm gì đó sau khi có kết quả trả về
            this.once(Ename, (r) => {
                if (r.error) {
                    switch (r.error.code) {
                        case -1003:
                            // console.error(r)
                            error(dayjs(r.error.data.retryAfter).format("DD/MM hh:mm +7"))
                            this.emit("retryAfter", r.error.data.retryAfter)
                            setTimeout(() => {
                                rs(this.getKlines(Symbol, StartTime, EndTime, timeframe, Limit))
                            }, r.error.data.retryAfter - dayjs().valueOf() + 500);
                            break;
                        case -1121:
                            r.error.symbol = Symbol
                            rj(r.error);
                            break;
                        default:
                            console.error(r.error, params);
                            rj(r.error);
                            break;
                    }
                } else try {
                    let Klines = r.result
                    // logsuccess("nhận " + Symbol + " " + timeframe + " " + r.id + " " + Klines.length);

                    // sắp xếp thời gian tăng dần
                    Klines.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => a[CandlestickMap.OpenTime] - b[CandlestickMap.OpenTime])
                    if (Klines[Klines.length - 1]) {
                        let now = dayjs().valueOf()
                        let openTime = Klines[Klines.length - 1][CandlestickMap.OpenTime]
                        let closeTime = Klines[Klines.length - 1][CandlestickMap.CloseTime]
                        // log(dayjs(now).toString(), dayjs(openTime).toString(), dayjs(closeTime).toString(), (closeTime - openTime) / (now - openTime))
                        // nến cuối, nếu chưa quá 50% thời gian thì loại
                        if ((closeTime - openTime) / (now - openTime) <= (100 / 77)) {
                            Klines[Klines.length - 1][CandlestickMap.CloseTime] = dayjs().valueOf();
                        } else if (Klines.length > 2) {
                            Klines.pop()
                        } else
                            Klines[Klines.length - 1][CandlestickMap.CloseTime] = dayjs().valueOf();
                    }
                    this.emit("getKlinesFinished", Klines)
                    rs(Klines);
                } catch (err) {
                    console.error(r);
                    rj(err)
                }
            })

            if ((EndTime - StartTime) / TIMEFRAMES.toMiliSecond(timeframe) > 1000)
                StartTime = EndTime - TIMEFRAMES.toMiliSecond(timeframe) * 1001;

            if (TIMEFRAMES.toMiliSecond(timeframe) > TIMEFRAMES.toMiliSecond(TIMEFRAMES.M1))
                timeframe = TIMEFRAMES.M1

            params = {
                "symbol": Symbol,
                "interval": timeframe,
                "startTime": StartTime,
                "endTime": EndTime,
                limit: Limit
            }
            // gửi yêu cầu lên server 
            // console.log(params.symbol, params.interval);
            this.Send({
                "id": Ename,
                "method": "klines",
                "params": params
            },)
            this.emit("getKlines", { Symbol, StartTime, EndTime, timeframe, Limit })
        })
    }

    /**
     * lấy biểu đồ nến của danh sách symbols
     * @param {string[]} Symbols danh sách cặp tiền
     * @param {number} StartTime thời gian bắt đầu
     * @param {number} EndTime thời gian kết thúc
     * @param {string} timeframe khung thời gian
     * @param {number} index thứ tự
     * @param {number} TimeWait thời gian chờ mỗi truy vấn milisecond
     * @returns {object} danh sách {Symbol : Kline}
     */
    getSymbolsKlines(Symbols: string[] = [], StartTime: number, EndTime: number, timeframe: string = TIMEFRAMES.d1, index: number = 0, TimeWait: number = 10): Promise<{ [symbol: string]: Kline[] }> {
        return new Promise(async (rs, rj) => {
            if (index < Symbols.length) {
                setTimeout(async () => {
                    let Symbol = Symbols[index]
                    // logwarn(" getSymbolsKlines >>> " + index + " " + Symbol + " " + Symbols.length + " " + timeframe)

                    let next = await this.getSymbolsKlines(Symbols, StartTime, EndTime, timeframe, index + 1)

                    try {
                        let Klines = await this.getKlines(Symbol, StartTime, EndTime, timeframe);

                        this.emit("getKlinesFinish", { Symbols, Klines, StartTime, EndTime, timeframe, index })
                        if (Klines && Klines.length > 0) {
                            next[Symbol] = Klines;
                        }
                    } catch (err) {
                        // error(err)
                        // rj(err)
                    }

                    if (index == (Symbols.length - 1)) this.emit("getSymbolsKlinesFinished", next)
                    rs(next);

                }, TimeWait);
            } else {
                rs({})
            };
        })
    }

    /**
     * Có 1 danh sách symbols, lấy cái đầu tiên làm chuẩn
     * số nến ở các symbol = cái đầu tiên
     * @param {number} limit số lượng symbol, tức là lấy đủ thì thôi
     * @returns {EventEmitter} :
     *  emit @param getSymbolsKlinesLimit nếu lấy được nến 1 cặp tiền
     *  emit @param getSymbolsKlinesLimitDone nếu hoàn thành tất cả
     */
    getSymbolsKlinesLimit(Symbols: string[] = [], StartTime: number, EndTime: number, timeframe: string = TIMEFRAMES.h1, limit = 50, isStartBefore = true): EventEmitter {
        let e = new EventEmitter();
        let first = Symbols[0]
        let amountCandle = Math.floor((EndTime - StartTime) / TIMEFRAMES.toMiliSecond(timeframe))
        let startTime = StartTime
        if (!isStartBefore)
            startTime = 0;

        this.getKlines(first, StartTime, EndTime, timeframe).then(firstKlines => {
            let symbolsKlines = { [first]: firstKlines }
            let countSymbolsKlines = 1;
            e.emit("getSymbolsKlinesLimit", { symbol: first, klines: firstKlines, countSymbolsKlines })

            const getAll = async (index = 1) => {
                if (countSymbolsKlines >= limit || index >= Symbols.length) {
                    e.emit("getSymbolsKlinesLimitDone", { symbolsKlines, Symbols, startTime, EndTime, timeframe, countSymbolsKlines })
                    return symbolsKlines
                }
                if (!Symbols[index])
                    throw error(Symbols[index], index, Symbols.length)

                let Klines = await this.getKlines(Symbols[index], startTime, EndTime, timeframe);


                // nếu số lượng nến đủ thì lấy
                if (isStartBefore) {
                    if (Klines.length >= firstKlines.length) {
                        symbolsKlines[Symbols[index]] = Klines
                        countSymbolsKlines++
                        e.emit("getSymbolsKlinesLimit", { symbol: Symbols[index], klines: Klines, countSymbolsKlines })
                    }
                }
                // nếu số lượng nến KHÔNG đủ thì lấy
                else {
                    if (Klines.length <= amountCandle) {
                        symbolsKlines[Symbols[index]] = Klines
                        countSymbolsKlines++
                        e.emit("getSymbolsKlinesLimit", { symbol: Symbols[index], klines: Klines, countSymbolsKlines })
                    }
                }
                return await getAll(index + 1)
            }
            getAll();
        })
        return e;
    }

    // Tìm các đồng tiền mà có cặp với BTC, trả về đồng cặp với StableCoins, ví dụ: ETH/BTC => ETH/USDT
    async getSymbolsQuoteBTCQuoteWith(With = StableCoins): Promise<string[]> {
        let QuoteAssets = ["BTC", ...With] // 
        let _Symbols = await this.getAllSymbolsWiths([])
        let endsWithBTC = _Symbols.filter(Symbol => Symbol.endsWith("BTC"))
        let Symbols: string[] = []
        endsWithBTC.forEach(Symbol => {
            let matched = Symbol.match(`(.+)(BTC)$`)
            if (matched) {
                const [baseAsset, quoteAsset] = matched.slice(1);
                // console.log(baseAsset);
                let symbol = _Symbols.find(S => {
                    return (S.startsWith(baseAsset) && !S.endsWith("BTC"));
                })
                if (symbol !== undefined)
                    Symbols.push(symbol)
            }
        })
        // log(s, _Symbols.filter(v => {
        //     return QuoteAssets.some(q => v.endsWith(q))
        // }))

        return filterDuplicateBaseAssets(Symbols);
    }

    /**
     * lấy trên future các cặp tiền 
     * @param {string[]} quoteAssets /USDT /BUSD
     * @returns 
     */
    async getFutureAllSymbolsWiths(quoteAssets: string[] = ["USDT"]): Promise<PairInfo[]> {
        // lấy tất cả các đồng spot để lọc những đồng có future nhưng ko có spot 
        let all = (await this.getAll()).filter(s => !s.quoteVolume.startsWith("0"))
        let symbols = all.map((k) => k.symbol).filter(s => quoteAssets.some(v => s.endsWith(v) &&
            !s.match(/1000|BEAR|BULL|UP|DOWN/) &&
            !s.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`)
        ))

        const r = await axios.get('https://fapi.binance.com/fapi/v1/ticker/24hr');
        const pairs = r.data.filter((pair) =>
            quoteAssets.some(v => pair.symbol.endsWith(v) &&
                !pair.symbol.match(/1000|BEAR|BULL|UP|DOWN/) &&
                !pair.symbol.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`) &&
                symbols.includes(pair.symbol)
            ));
        pairs.forEach(p => {
            quoteAssets.findIndex(q => {
                if (p.symbol.endsWith(q)) {
                    p.quoteAsset = q;
                    p.baseAsset = p.symbol.replace(q, "");
                    return true;
                }
            })
        })
        // sắp xếp theo giảm dần quoteVolume
        return pairs.sort((b, a) => a.quoteVolume - b.quoteVolume) //symbols.filter((v: string) => !v.match(/1000/))
    }
    /**
     * lấy trên spot các cặp tiền 
     * @param {string[]} quoteAssets /USDT /BUSD
     * @returns 
     */
    async getSpotAllSymbolsWiths(quoteAssets: string[] = ["USDT"]): Promise<PairInfo[]> {
        // lấy tất cả các đồng spot để lọc những đồng có future nhưng ko có spot 
        let all = (await this.getAll()).filter(s => !s.quoteVolume.startsWith("0"))
        let pairs = all.filter(p => quoteAssets.some(q => {
            let y = p.symbol.endsWith(q) &&
                !p.symbol.match(/1000|BEAR|BULL|UP|DOWN/) &&
                !p.symbol.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`)
            if (y) {
                p.quoteAsset = q;
                p.baseAsset = p.symbol.replace(q, "");
            }
            return y
        }))

        // sắp xếp theo giảm dần quoteVolume
        return pairs.sort((b, a) => a.quoteVolume - b.quoteVolume) //symbols.filter((v: string) => !v.match(/1000/))
    }

    /**
    * lấy thông tin toàn bộ trên sàn trong 24h
    */
    async getAll(futureOnly = false) {
        const url = futureOnly ? "https://fapi.binance.com/fapi/v1/ticker/24hr" : `https://api.binance.com/api/v3/ticker/24hr`
        return axios.get(url, { responseType: 'json', })
            .then(response => response.data)
    }


    /**
     * Lấy top những cặp tiền có giá trị vốn hóa lớn hơn...
     * @param {number} min 
     * @param {number} limit 
     */

    async getTopLargestCap(min: number = 1_000_000, limit: number = 100, quoteAssets = StableCoins,) {
        // tìm những đồng coin có cap lớn nhất trên coin marketcap
        let coinmarketcap = new Coinmarketcap();
        let coins = await coinmarketcap.getTopLargestCap(limit * 2);

        // lấy tất cả từ binance
        let all = await this.getAll();
        let found = all
            .filter((v: { symbol: string; }) =>
                // có cặp tiền với quote là Stable coins, ví dụ USDT, BUSD
                quoteAssets.some((quote: string) =>
                    v.symbol.endsWith(quote)
                )
                && quoteAssets.every((quote: string) =>
                    !v.symbol.startsWith(quote)
                )
                // cap tối thiểu lớn hơn min
                // && parseFloat(v.quoteVolume) >= min
                // && parseFloat(v.quoteVolume) * parseFloat(v.weightedAvgPrice) >= min
            )
            .sort((a: { quoteVolume: number; }, b: { quoteVolume: number; }) => b.quoteVolume - a.quoteVolume)
        // chuyển thành mảng Symbols rồi mới lọc trùng 
        let filterDuplicate = filterDuplicateBaseAssets(found.map((v: { symbol: any; }) => v.symbol), quoteAssets)
        // đưa những cái không trùng vào mảng binanceCoins
        let binanceCoins: any[] = found.filter((v: { symbol: any; }) => filterDuplicate.includes(v.symbol))
        let Symbols: { symbol: any; marketCap: any; }[] = []
        // log(coins, binanceCoins)

        // tìm những đồng có trên binance
        coins.forEach((c: { symbol: any; marketCap: any; }) => {
            let index: number = binanceCoins.findIndex((s: { symbol: string; }) => s.symbol.startsWith(c.symbol))
            if (index >= 0) {
                binanceCoins[index].marketCap = c.marketCap
                // log(binanceCoins[index])
                Symbols.push(binanceCoins[index]);
            }
        })

        Symbols.sort((a: { symbol: any; marketCap: any; }, b: { symbol: any; marketCap: any; }) => b.marketCap - a.marketCap)

        return Symbols.slice(0, limit);
    }

    /**
     * đưa vào 1 mảng timeframes, tính xem còn bao lâu nữa đóng nến, 
     * cứ 1s, emit sự kiện "percentageRemaining" : { Timeframe, openTime, closeTime, percentageRemaining, elapsedTime }
     * @param {string[]} Timeframe
     * @return {EventEmitter}
     */
    timers(Timeframe: string = "4h"): EventEmitter {
        let event = new EventEmitter()
        new Promise(async (rs, rj) => {
            try {
                let now = Date.now()
                let TimeframeMS = TIMEFRAMES.toMiliSecond(Timeframe)

                // lấy cây nến cuối
                let Klines = await this.getKlines('BTCUSDT', now - TimeframeMS, now, Timeframe, 1);

                if (Klines.length > 0) {
                    let openTime = Number(Klines[Klines.length - 1][CandlestickMap.OpenTime])
                    let closeTime = openTime + TimeframeMS;

                    setInterval(() => {
                        const elapsedTime = Date.now() - closeTime;
                        if (elapsedTime >= 0) {
                            openTime = closeTime
                            closeTime += TimeframeMS
                        }
                        const percentageRemaining = (elapsedTime / TimeframeMS) * 100;
                        // console.log("timer ", Timeframe, percentageRemaining);
                        event.emit("percentageRemaining", { Timeframe, openTime, closeTime, percentageRemaining, elapsedTime });
                    }, 1000);

                }
            } catch (err) { error(Timeframe); }
        })

        return event;
    }


    streamPrice(symbol: string, callback?: (trade: Trade) => void): WebSocket {
        const wsUrl = `wss://stream.binance.com:9443/ws/${symbol.trim().toLowerCase()}@trade`;
        // Tạo kết nối WebSocket
        const ws = new WebSocket(wsUrl);

        ws.onopen = () => {
            log(`streamPrice ${symbol} WebSocket connection opened`);
        };
        ws.onmessage = (msg) => {
            const trade = JSON.parse(msg.data);
            /*
            {
                "e": "trade",     // Event type
                "E": 1672515782136,   // Event time
                "s": "BNBBTC",    // Symbol
                "t": 12345,       // Trade ID
                "p": "0.001",     // Price
                "q": "100",       // Quantity
                "T": 1672515782136,   // Trade time
                "m": true,        // Is the buyer the market maker?
                "M": true         // Ignore
            }
            */
            // log(symbol, trade)
            // console.log(`Price: ${trade.p}, Quantity: ${trade.q}, Trade Time: ${new Date(trade.T)}`);
            if (callback) {
                callback(trade)
            }
        };

        ws.onclose = () => {
            log(`streamPrice ${symbol} WebSocket connection closed`);
        };

        ws.onerror = (err) => {
            error(`streamPrice ${symbol} WebSocket error:`, err);
        };
        return ws
    }
}

export default Binance;