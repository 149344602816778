import React from 'react';
import { Slider } from 'antd';

type Props = {
    [name: string]: any
    percent: number
}

const SliderFight: React.FC<Props> = ({ percent, }) => {
    return (<div style={{ textAlign: 'center', }}>
        <Slider
            range
            value={[0, percent, 100]}
            marks={{ percent }}
            styles={{
                track: {
                    background: 'transparent',
                },
                tracks: {
                    background: `linear-gradient(90deg, rgba(3,128,24) ${percent}%, rgba(255,0,0) ${percent}%)`,
                },
            }}
        />
    </div>);
};

SliderFight.defaultProps = {
    percent: 50
}

export default SliderFight;
