import { useEffect, useRef, useState } from "react";
import TimeframesSelect from "../components/TimeframesSelect";
import { Badge, Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Space, Switch, Tooltip } from "antd";
import {
    LineChartOutlined, DeleteOutlined,
    FullscreenOutlined, ExpandAltOutlined, FullscreenExitOutlined,
    FundViewOutlined, BuildOutlined,
    EyeOutlined, EyeInvisibleOutlined,
} from '@ant-design/icons';

import QueueAnim from 'rc-queue-anim';
import i18n from "../services/i18n";

import Bybit from "../utils/Bybit";
import PriceVolumeChart, { Data } from "../components/PriceVolumeChart";

import { useStore, useStoreDispatch } from "store/hooks";
import { SettingsInitialType, change } from "../store/settings";
import BigNumber from "bignumber.js";

const { log, error } = console

type Props = {
    pair: {
        symbol: string
    }
}
const defaultProps = {
    pair: {
        symbol: 'solusdt'
    }
}

const BybitPage: React.FC<Props> = ({ pair }) => {
    const mounted = useRef(false);

    const services = useStore((state) => state.services);
    const [isConnected, setisConnected] = useState(false)
    const settings = useStore<SettingsInitialType>((state) => state.settings);
    const dispatch = useStoreDispatch();

    const [state, setstate] = useState<any>({
        count: 1
    })

    const [dataSource, setdataSource] = useState<any[]>([])
    const { t } = i18n

    const connect = () => {
        const ex = new Bybit()
        log(ex.ws)
    }

    const data: Data = [
        {
            "price": 27.925,
            "orderType": "bid",
            "volume": 167.6
        },
        {
            "price": 27.93,
            "orderType": "bid",
            "volume": 373.5
        },
        {
            "price": 27.467,
            "orderType": "bid",
            "volume": 873.4
        },
        {
            "price": 27.921,
            "orderType": "bid",
            "volume": 501.8
        },
        {
            "price": 27.958,
            "orderType": "bid",
            "volume": 116.7
        },
        {
            "price": 27.878,
            "orderType": "bid",
            "volume": 485
        },
        {
            "price": 27.926,
            "orderType": "bid",
            "volume": 161.2
        },
        {
            "price": 27.841,
            "orderType": "bid",
            "volume": 445
        },
        {
            "price": 27.936,
            "orderType": "bid",
            "volume": 351.7
        },
        {
            "price": 27.861,
            "orderType": "bid",
            "volume": 721.6
        },
        {
            "price": 27.843,
            "orderType": "bid",
            "volume": 604.7
        },
        {
            "price": 27.735,
            "orderType": "bid",
            "volume": 615.1
        },
        {
            "price": 27.947,
            "orderType": "bid",
            "volume": 188.9
        },
        {
            "price": 27.947,
            "orderType": "ask",
            "volume": 188.9
        },
        {
            "price": 27.974,
            "orderType": "ask",
            "volume": 154.3
        },
        {
            "price": 28.014,
            "orderType": "ask",
            "volume": 260.9
        },
        {
            "price": 28.25,
            "orderType": "ask",
            "volume": 725.9
        },
        {
            "price": 27.98,
            "orderType": "ask",
            "volume": 182.8
        },
        {
            "price": 27.995,
            "orderType": "ask",
            "volume": 281.2
        },
        {
            "price": 27.973,
            "orderType": "ask",
            "volume": 50.8
        },
        {
            "price": 27.983,
            "orderType": "ask",
            "volume": 77.4
        },
        {
            "price": 28.88,
            "orderType": "ask",
            "volume": 509.2
        },
        {
            "price": 28.031,
            "orderType": "ask",
            "volume": 390.8
        },
        {
            "price": 27.988,
            "orderType": "ask",
            "volume": 261.2
        },
        {
            "price": 28.026,
            "orderType": "ask",
            "volume": 338.6
        },
        {
            "price": 30.191,
            "orderType": "ask",
            "volume": 149
        },
        {
            "price": 28.019,
            "orderType": "ask",
            "volume": 124.2
        },
        {
            "price": 27.993,
            "orderType": "ask",
            "volume": 442.6
        },
        {
            "price": 28.02,
            "orderType": "ask",
            "volume": 426.9
        },
        {
            "price": 28.125,
            "orderType": "ask",
            "volume": 367.8
        },
        {
            "price": 28.158,
            "orderType": "ask",
            "volume": 754.6
        },
        {
            "price": 28.046,
            "orderType": "ask",
            "volume": 304.3
        },
        {
            "price": 28.999,
            "orderType": "ask",
            "volume": 485.4
        },
        {
            "price": 28.999,
            "orderType": "bid",
            "volume": 485.4
        },
        {
            "price": 27.925,
            "orderType": "bid",
            "volume": 167.6
        },
        {
            "price": 27.93,
            "orderType": "bid",
            "volume": 373.5
        },
        {
            "price": 27.467,
            "orderType": "bid",
            "volume": 873.4
        },
        {
            "price": 27.921,
            "orderType": "bid",
            "volume": 501.8
        },
        {
            "price": 27.958,
            "orderType": "bid",
            "volume": 116.7
        },
        {
            "price": 27.878,
            "orderType": "bid",
            "volume": 485
        },
        {
            "price": 27.926,
            "orderType": "bid",
            "volume": 161.2
        },
        {
            "price": 27.841,
            "orderType": "bid",
            "volume": 445
        },
        {
            "price": 27.936,
            "orderType": "bid",
            "volume": 351.7
        },
        {
            "price": 27.861,
            "orderType": "bid",
            "volume": 721.6
        },
        {
            "price": 27.843,
            "orderType": "bid",
            "volume": 604.7
        },
        {
            "price": 27.735,
            "orderType": "bid",
            "volume": 615.1
        },
        {
            "price": 27.947,
            "orderType": "bid",
            "volume": 188.9
        },
        {
            "price": 27.947,
            "orderType": "ask",
            "volume": 188.9
        },
        {
            "price": 27.974,
            "orderType": "ask",
            "volume": 154.3
        },
        {
            "price": 28.014,
            "orderType": "ask",
            "volume": 260.9
        },
        {
            "price": 28.25,
            "orderType": "ask",
            "volume": 725.9
        },
        {
            "price": 27.98,
            "orderType": "ask",
            "volume": 182.8
        },
        {
            "price": 27.995,
            "orderType": "ask",
            "volume": 281.2
        },
        {
            "price": 27.973,
            "orderType": "ask",
            "volume": 50.8
        },
        {
            "price": 27.983,
            "orderType": "ask",
            "volume": 77.4
        },
        {
            "price": 28.88,
            "orderType": "ask",
            "volume": 509.2
        },
        {
            "price": 28.031,
            "orderType": "ask",
            "volume": 390.8
        },
        {
            "price": 27.988,
            "orderType": "ask",
            "volume": 261.2
        },
        {
            "price": 28.026,
            "orderType": "ask",
            "volume": 338.6
        },
        {
            "price": 30.191,
            "orderType": "ask",
            "volume": 149
        },
        {
            "price": 28.019,
            "orderType": "ask",
            "volume": 124.2
        },
        {
            "price": 27.993,
            "orderType": "ask",
            "volume": 442.6
        },
        {
            "price": 28.02,
            "orderType": "ask",
            "volume": 426.9
        },
        {
            "price": 28.125,
            "orderType": "ask",
            "volume": 367.8
        },
        {
            "price": 28.158,
            "orderType": "ask",
            "volume": 754.6
        },
        {
            "price": 28.046,
            "orderType": "ask",
            "volume": 304.3
        },
        {
            "price": 28.999,
            "orderType": "ask",
            "volume": 485.4
        },
        {
            "price": 28.999,
            "orderType": "bid",
            "volume": 485.4
        },
        {
            "price": 27.925,
            "orderType": "bid",
            "volume": 167.6
        },
        {
            "price": 27.93,
            "orderType": "bid",
            "volume": 373.5
        },
        {
            "price": 27.467,
            "orderType": "bid",
            "volume": 873.4
        },
        {
            "price": 27.921,
            "orderType": "bid",
            "volume": 501.8
        },
        {
            "price": 27.958,
            "orderType": "bid",
            "volume": 116.7
        },
        {
            "price": 27.878,
            "orderType": "bid",
            "volume": 485
        },
        {
            "price": 27.926,
            "orderType": "bid",
            "volume": 161.2
        },
        {
            "price": 27.841,
            "orderType": "bid",
            "volume": 445
        },
        {
            "price": 27.936,
            "orderType": "bid",
            "volume": 351.7
        },
        {
            "price": 27.861,
            "orderType": "bid",
            "volume": 721.6
        },
        {
            "price": 27.843,
            "orderType": "bid",
            "volume": 604.7
        },
        {
            "price": 27.735,
            "orderType": "bid",
            "volume": 615.1
        },
        {
            "price": 27.947,
            "orderType": "bid",
            "volume": 188.9
        },
        {
            "price": 27.947,
            "orderType": "ask",
            "volume": 188.9
        },
        {
            "price": 27.974,
            "orderType": "ask",
            "volume": 154.3
        },
        {
            "price": 28.014,
            "orderType": "ask",
            "volume": 260.9
        },
        {
            "price": 28.25,
            "orderType": "ask",
            "volume": 725.9
        },
        {
            "price": 27.98,
            "orderType": "ask",
            "volume": 182.8
        },
        {
            "price": 27.995,
            "orderType": "ask",
            "volume": 281.2
        },
        {
            "price": 27.973,
            "orderType": "ask",
            "volume": 50.8
        },
        {
            "price": 27.983,
            "orderType": "ask",
            "volume": 77.4
        },
        {
            "price": 28.88,
            "orderType": "ask",
            "volume": 509.2
        },
        {
            "price": 28.031,
            "orderType": "ask",
            "volume": 390.8
        },
        {
            "price": 27.988,
            "orderType": "ask",
            "volume": 261.2
        },
        {
            "price": 28.026,
            "orderType": "ask",
            "volume": 338.6
        },
        {
            "price": 30.191,
            "orderType": "ask",
            "volume": 149
        },
        {
            "price": 28.019,
            "orderType": "ask",
            "volume": 124.2
        },
        {
            "price": 27.993,
            "orderType": "ask",
            "volume": 442.6
        },
        {
            "price": 28.02,
            "orderType": "ask",
            "volume": 426.9
        },
        {
            "price": 28.125,
            "orderType": "ask",
            "volume": 367.8
        },
        {
            "price": 28.158,
            "orderType": "ask",
            "volume": 754.6
        },
        {
            "price": 28.046,
            "orderType": "ask",
            "volume": 304.3
        },
        {
            "price": 28.999,
            "orderType": "ask",
            "volume": 485.4
        },
        {
            "price": 28.999,
            "orderType": "bid",
            "volume": 485.4
        },
    ]

    const [currentPrice, setcurrentPrice] = useState(27.972)

    useEffect(() => {
        if (!mounted.current) {
            let i = 0
            const loop = setInterval(() => {
                if (i < data.length) { //
                    dataSource.push(data[i])
                    setdataSource([...dataSource])
                    setcurrentPrice(data[i - 1]?.price ?? 0)
                    i += 1// data.length
                } else {
                    clearInterval(loop)
                }
            }, 500);
        }
        mounted.current = true;
    }, [])

    async function toggleVisibleLimitDepthPrices() {
        const symbol = pair?.symbol.toLowerCase();
        let key = `limitDepthPrices.${symbol}`;
        const value = settings?.limitDepthPrices[symbol]?.active ?? false;

        let p = settings?.limitDepthPrices[symbol];

        // nếu chưa có thì thêm vào
        if (!p) {
            p = {
                active: !value,
                min: -Infinity, // %
                max: Infinity,  // %}
            }
            await dispatch(change({
                [key]: p
            }));
        }
        else {
            key += '.active'
            await dispatch(change({ [key]: !value }))
        }
    }

    async function changeLimitDepthPrices(name: 'min' | 'max', value: number) {
        log(name, value)
        const symbol = pair?.symbol.toLowerCase();
        let key = `limitDepthPrices.${symbol}`;
        let p = settings?.limitDepthPrices[symbol];
        if (!p) {
            p = {
                active: true,
                min: name === 'min' ? value : -Infinity, // %
                max: name === 'max' ? value : Infinity,  // %
            }
            await dispatch(change({
                [key]: p
            }));
        }
        else {
            key += ('.' + name)
            await dispatch(change({ [key]: value }))
        }
    }

    useEffect(() => {
        const limitDepthPrices_s = settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()];
        const limitDepthPrices = services?.heat?.limitDepthPrices;

        if (limitDepthPrices && limitDepthPrices_s) {
            limitDepthPrices.min = limitDepthPrices_s.active ? currentPrice * ((100 + limitDepthPrices_s.min) / 100) : -Infinity;
            limitDepthPrices.max = limitDepthPrices_s.active ? currentPrice * ((100 + limitDepthPrices_s.max) / 100) : Infinity;
            setstate({ ...state, count: state.count + 1 })
        }
    }, [
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.active,
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.min,
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.max,
        currentPrice
    ])

    return (<>
        <Badge.Ribbon text={t(isConnected ? "connected" : "closed")} color={isConnected ? "green" : "red"}>
            <Space wrap>
                <Button onClick={connect}>Connect</Button>

            </Space>

            {/* <TimeframesSelect timeframes={services?.altcoinSeason?.exchange?.Timeframes || {}} selected={listenTimeframes} onChange={onTimeframesChanged} /> */}


        </Badge.Ribbon >

        <Drawer
            title={<>
                {t("Price Volume")} {pair?.symbol?.toLowerCase()}&nbsp;
            </>}
            placement={"left"}
            mask={false}
            // onClose={toggleDrawerPriceVolume}
            open={true}
        // style={{ backgroundColor: 'gray' }}
        >

            <Row style={{ paddingBottom: '1em' }}>
                <Switch checked={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.active} onChange={toggleVisibleLimitDepthPrices} size="small" />&nbsp;

                {t('Change price limits')}: {pair?.symbol.toLowerCase()}: {currentPrice}

                <QueueAnim>

                    {settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.active
                        ? <Row key='1'>

                            <Col span={12}>
                                <Form.Item label={t("Min")}>
                                    <InputNumber name={"minDepthPrice"}
                                        value={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.min}
                                        changeOnWheel={true}
                                        style={{ width: "100%" }}
                                        formatter={(value) => BigNumber(value ?? 0).toFormat()} // 
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        suffix='%'
                                        onChange={v => changeLimitDepthPrices('min', v)}
                                    />
                                    <i>{services?.heat?.limitDepthPrices?.min}</i>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label={t("Max")}>
                                    <InputNumber name={"maxDepthPrice"}
                                        value={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.max}
                                        changeOnWheel={true}
                                        style={{ width: "100%" }}
                                        formatter={(value) => BigNumber(value ?? 0).toFormat()} // 
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        suffix='%'
                                        onChange={v => changeLimitDepthPrices('max', v)}
                                    />
                                    <i>{services?.heat?.limitDepthPrices?.max}</i>
                                </Form.Item>
                            </Col>

                        </Row>
                        : null
                    }
                </QueueAnim>

            </Row>

            <PriceVolumeChart data={dataSource} currentPrice={currentPrice} />
            {/* <PriceVolumeChart data={data1} currentPrice={27.973} /> */}
        </Drawer>
    </>)
}
BybitPage.defaultProps = defaultProps
export default BybitPage;
