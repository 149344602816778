import { useCallback, useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";

import { Badge, Button, Flex, Input, InputRef, Row, Slider, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ColumnsType, FilterConfirmProps } from 'antd/es/table/interface';
import { LineChartOutlined, SearchOutlined, } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// import { UMFutures } from "@binance/futures-connector";

import BinanceFuture, { OrderSide, OrderStatus, OrderType, TimeInForce } from '../utils/BinanceFuture';
import { change, settingsEvent } from '../store/settings';
import i18n from "../services/i18n";
import { BtnCopy, SliderFight } from '../components';
import { percentChange } from '../utils/std';
import { StableCoins } from '../utils/AltcoinSeason';
import dayjs from 'dayjs';


const { log, } = console

type Order = {
    "s": string,
    "S": OrderSide,
    "o": OrderType,
    "f": TimeInForce,
    "q": number,
    "p": string,
    "ap": string,
    "X": OrderStatus,
    "l": string,
    "z": string,
    "T": number,
    "countSell": number,
    "countBuy": number,
    "count": number
}

type RowType = {
    symbol: string, point: number,
}
type RowIndex = keyof RowType;

type Props = {
    only?: string
}


const OrdersBook = <PROPS extends Props,>({ only }: PROPS) => {
    const dispatch = useStoreDispatch();
    const { t } = i18n;
    const settings = useStore((state) => state.settings);
    const services = useStore((state) => state.services);
    const mounted = useRef(false);

    const [count, setcount] = useState(0)

    const [isConnected, setisConnected] = useState(false)
    const [exchange, setexchange] = useState<BinanceFuture>()
    const [forceOrders, setforceOrders] = useState<{ [symbol: string]: Order }>({})

    const [dataSource, setdataSource] = useState([])
    const [columns, setcolumns] = useState<ColumnsType>()

    const [balanceBuySell, setbalanceBuySell] = useState(50)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [props] = useState({ only })

    const [soundAlerts] = useState<{
        speechUtterance: SpeechSynthesisUtterance,
        [name: string]: HTMLAudioElement | SpeechSynthesisUtterance,
    }>({
        speechUtterance: new SpeechSynthesisUtterance('BTC')
    })

    const [volumeNewOrder, setvolumeNewOrder] = useState<number>(50)

    useEffect(() => {
        if (!mounted.current) {
            soundAlerts.Bitcoin = new Audio("/sounds/Bitcoin.mp3")
            soundAlerts.BUY = new Audio("/sounds/" + t('BUY') + '.mp3')
            soundAlerts.SELL = new Audio("/sounds/" + t('SELL') + '.mp3')
            soundAlerts.Bitcoin.load();
            soundAlerts.BUY.load();
            soundAlerts.SELL.load();
            window.soundAlerts = soundAlerts;


            settingsEvent.on("loaded", (settings) => {
                if (!isNaN(props.only ? settings.volumeNewOrderOnly : settings.volumeNewOrder)) {
                    let volume = props.only ? settings.volumeNewOrderOnly : settings.volumeNewOrder
                    setvolumeNewOrder(volume)
                    soundAlerts.speechUtterance.volume = volume / 100

                    soundAlerts.Bitcoin.volume = volume / 100;
                    soundAlerts.BUY.volume = volume / 100;
                    soundAlerts.SELL.volume = volume / 100;


                }
            })
            i18n.on('languageChanged', function () {
                (soundAlerts.BUY as HTMLAudioElement).src = "/sounds/" + t('BUY') + '.mp3';
                (soundAlerts.SELL as HTMLAudioElement).src = "/sounds/" + t('SELL') + '.mp3'
            })

            setTimeout(() => {

                const sorterString = (key, a, b) => {
                    return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : (a[key].toUpperCase() > b[key].toUpperCase() ? 1 : 0)
                };
                const sorter = (key, a, b) => a[key] - b[key];

                let columns: ColumnsType = [{
                    title: "#",
                    dataIndex: "#",
                    key: "#",
                    sorter: (a, b) => sorter("#", a, b),
                },
                {
                    title: <Tooltip title={t("symbol")} color='blue'><div>{t("symbol")}</div></Tooltip>,
                    dataIndex: "symbol",
                    key: "symbol",
                    sorter: (a, b) => sorterString("symbol", a, b),
                    ...getColumnSearchProps("symbol"),
                    render: (value, record) => {
                        return {
                            children: <>{value} <BtnCopy value={value} /></>
                        };
                    }
                }, {
                    title: "view",
                    dataIndex: "view",
                    key: "view",
                },
                {
                    title: <Tooltip title={t("Count orders")} color='blue'><div>{t("Count orders")}</div></Tooltip>,
                    dataIndex: "count",
                    key: "count",
                    sorter: (a, b) => sorter("count", a, b),
                    render: (value, record: any) => {
                        return {
                            children: <>{value} <Button icon="🧹" onClick={e => clearAmountOrder(record.symbol)} type="text" /> </>
                        };
                    }
                },
                {
                    title: <Tooltip title={t("Count Buy")} color='blue'><div>{t("Buy")}</div></Tooltip>,
                    dataIndex: "countBuy",
                    key: "countBuy",
                    sorter: (a, b) => sorter("countBuy", a, b),
                },
                {
                    title: <Tooltip title={t("Count Sell")} color='blue'><div>{t("Sell")}</div></Tooltip>,
                    dataIndex: "countSell",
                    key: "countSell",
                    sorter: (a, b) => sorter("countSell", a, b),
                },
                {
                    title: <Tooltip title={t("Balance BUY ⚡ SELL")} color='blue'><Tag color='green'>BUY</Tag>⚡ <Tag color='red'>SELL</Tag></Tooltip >,
                    dataIndex: "balance",
                    key: "balance",
                    sorter: (a, b) => sorter("balance", a, b),
                    render: (value, record) => {
                        return {
                            children: <div style={{ textAlign: "center" }}><SliderFight percent={value} /></div>
                        };
                    }
                },
                {
                    title: <Tooltip title={<>
                        {t(`GTC - Good Till Cancel`)} <br />
                        {t(`IOC - Immediate or Cancel`)} <br />
                        {t(`FOK - Fill or Kill`)} <br />
                        {t(`GTX - Good Till Crossing (Post Only)`)} <br />
                        {t(`GTD - Good Till Date`)}</>}
                        color='blue'><div>{t("Force type")}</div></Tooltip>,
                    dataIndex: "timeInForce",
                    key: "timeInForce",
                    sorter: (a, b) => sorterString("timeInForce", a, b),
                    render: (value, record) => {
                        return {
                            children: <Tag color={record.orderType === "BUY" ? "green" : "red"}>{value}</Tag>
                        };
                    }

                },
                {
                    title: <Tooltip title={t("Order Trade Time")} color='blue'><div>{t("Time")}</div></Tooltip>,
                    dataIndex: "orderTradeTime",
                    key: "orderTradeTime",
                    sorter: (a, b) => sorter("orderTradeTime", a, b),
                    render: (value, record) => {
                        return {
                            children: dayjs(value).format("H:m:s")
                        };
                    }
                },
                {
                    title: <Tooltip title={t("Price")} color='blue'><div>{t("Price")}</div></Tooltip>,
                    dataIndex: "price",
                    key: "price",
                    sorter: (a, b) => sorter("price", a, b),
                },
                {
                    title: <Tooltip title={t("Slip price") + " %"} color='blue'><div>{t("Slip price")} %</div></Tooltip>,
                    dataIndex: "averagePrice",
                    key: "averagePrice",
                    sorter: (a, b) => sorter("averagePrice", a, b),
                    render: (value, record: any) => {
                        return {
                            children: <>{percentChange(record.price, record.averagePrice).toFixed(3)}</>
                        }
                    }
                },
                {
                    title: <Tooltip title={t("Order Last Filled Quantity")} color='blue'><div>{t("Last quantity")}</div></Tooltip>,
                    dataIndex: "orderLastFilledQuantity",
                    key: "orderLastFilledQuantity",
                    sorter: (a, b) => sorter("orderLastFilledQuantity", a, b),
                },
                {
                    title: <Tooltip title={t("original quantity / order filled accumulated quantity")} color='blue'><div>{t("Quantity")}</div></Tooltip>,
                    dataIndex: "orderFilledAccumulatedQuantity",
                    key: "orderFilledAccumulatedQuantity",
                    sorter: (a, b) => sorter("orderFilledAccumulatedQuantity", a, b),
                    render: (value, record: any) => {
                        return {
                            children: <>{record.originalQuantity} / {record.orderFilledAccumulatedQuantity}</>
                        }
                    }
                },
                ]

                setcolumns(columns)


            }, 2000);
            stream()
        }
        mounted.current = true;
    }, [])

    useEffect(() => {
        if (exchange?.ws?.readyState === 1)
            setisConnected(true)
        else
            setisConnected(false)
    }, [exchange?.ws?.readyState])

    // const getMarketOrders = async (symbol) => {
    //     let ex = new BinanceFuture(settings?.apiKey)
    //     setexchange(ex)

    //     // const orders = await future.getOpenOrders();
    //     // return orders.filter((order) => order.symbol === symbol && order.type === "MARKET");
    // }

    const onChangeApiKey = (e) => {
        let apiKey = e.target.value
        dispatch(change({ apiKey }))
    }

    const onChangeSecretKey = (e) => {
        let secretKey = e.target.value
        dispatch(change({ secretKey }))
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: RowIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    /**
     * Lọc theo cột
     * @param dataIndex 
     */
    const getColumnSearchProps = (dataIndex: RowIndex): ColumnType<RowType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t("Search") + " " + dataIndex}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />


                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("Search")}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("Reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        {t("Filter")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const updateTable = useCallback((newOrder: Order) => {
        if (newOrder) {
            newOrder.count = newOrder.countBuy = newOrder.countSell = 0;

            if (!forceOrders[newOrder.s]) {
                newOrder.count = 1;
                if (newOrder.S === "BUY")
                    newOrder.countBuy = 1;
                else
                    newOrder.countSell = 1;
            } else {
                newOrder.count = forceOrders[newOrder.s].count + 1;
                newOrder.countBuy = forceOrders[newOrder.s].countBuy;
                newOrder.countSell = forceOrders[newOrder.s].countSell;

                if (newOrder.S === "BUY")
                    newOrder.countBuy += 1;
                else
                    newOrder.countSell += 1;
            }

            forceOrders[newOrder.s] = newOrder

            const symbol = newOrder.s.endsWith('USDT') ? newOrder.s.slice(0, -4) : newOrder.s;
            if (settings?.volumeNewOrder) {
                try {
                    (soundAlerts[newOrder.S] as HTMLAudioElement)?.play()
                    setTimeout(() => {
                        const { speechUtterance } = soundAlerts
                        if (soundAlerts.speechUtterance?.voice?.voiceURI !== 'Google US English') {
                            const voice = speechSynthesis.getVoices().find(v => v.voiceURI === "Google US English");
                            if (voice)
                                soundAlerts.speechUtterance.voice = voice;
                        }

                        if (newOrder.s.trim().toLowerCase() === "btcusdt")
                            (soundAlerts.Bitcoin as HTMLAudioElement)?.play();
                        else if (only && only.trim() !== '') {
                            if (speechUtterance) {
                                speechUtterance.text = only
                                speechSynthesis.speak(speechUtterance);
                            }
                        } else {
                            if (speechUtterance) {
                                speechUtterance.text = symbol
                                speechSynthesis.speak(speechUtterance);
                            }
                        }

                    }, 300);

                } catch (err) {
                }
            }
        }
        let sumBalance = 0

        let dataSource = Object.entries(forceOrders).map(([symbol, values], i) => {

            let linkBinance = services?.altcoinSeason?.futureOnly ? `https://www.binance.com/futures/${symbol}` : `https://www.binance.com/trade/${symbol}?type=spot`
            let linkBybit = services?.altcoinSeason?.futureOnly ? `https://www.bybit.com/trade/usdt/${symbol}` : `https://www.bybit.com/trade/spot/${symbol}`
            let pair = symbol
            for (let i = 0; i < StableCoins.length; i++) {
                const q = StableCoins[i];
                if (symbol.endsWith(q)) {
                    let b = symbol.replace(q, "")
                    linkBinance = services?.altcoinSeason?.futureOnly ? `https://www.binance.com/futures/${b}${q}` : `https://www.binance.com/trade/${b}_${q}?type=spot`
                    linkBybit = services?.altcoinSeason?.futureOnly ? `https://www.bybit.com/trade/usdt/${b}${q}` : `https://www.bybit.com/trade/spot/${b}/${q}`
                    pair = b + "-" + q
                }
            }

            let balance = (values.countBuy / values.count) * 100
            sumBalance += isNaN(balance) ? 50 : balance

            return {
                "#": i + 1,
                "symbol": symbol,
                "view": <Flex style={{ alignItems: "center" }}>
                    <Button type='link' href={linkBinance} target="_blank" icon={<img src='/images/binance.png' height={23} />}></Button>
                    <Button type='link' href={linkBybit} target="_blank" icon={<img src='/images/bybit.svg' height={10} />} style={{ border: "1px solid #424242", backgroundColor: "#4a8209" }}></Button>
                    <Button type='link' href={`/pairs/${pair}`} target="_blank" icon={<LineChartOutlined />} style={{ border: "1px solid #424242" }}></Button>
                </Flex>,


                count: values.count,
                countBuy: values.countBuy,
                countSell: values.countSell,
                balance: isNaN(balance) ? 50 : Math.round(balance),

                "side": values.s,               // Side
                "orderType": values.S,          // Order Type
                "timeInForce": values.f,        // Time in Force

                "originalQuantity": Number(values.q),   // Original Quantity
                "price": Number(values.p),              // Price
                "averagePrice": Number(values.ap),      // Average Price
                "orderStatus": values.X,                // Order Status
                "orderLastFilledQuantity": Number(values.l),        // Order Last Filled Quantity
                "orderFilledAccumulatedQuantity": Number(values.z), // Order Filled Accumulated Quantity
                "orderTradeTime": Number(values.T),                 // Order Trade Time
            }
        })
        setdataSource(dataSource)
        if (dataSource.length > 0)
            setbalanceBuySell(Math.floor(sumBalance / dataSource.length))
    }, [])

    useEffect(() => {
        props.only = only
    }, [only])

    const stream = useCallback(async (e?: any) => {
        let ex = BinanceFuture.forceOrder("*")
        ex.on(undefined, (r: any) => {
            if (r && r?.o && StableCoins.every(s => !r.o.s.startsWith(s))) {
                if (props.only) {
                    if (props.only === r.o.s)
                        updateTable(r.o)
                } else if (!(r.o.s as string).startsWith('1000'))
                    updateTable(r.o)
            }

        }).on("connected", r => {
            setisConnected(ex.ws && ex.ws.readyState === 1)
            setcount(count + 1)
        }).on("closed", r => {
            setisConnected(ex.ws && ex.ws.readyState === 1)
        });

        // cứ 1 giây, nếu mất kết nối thì tự kết nối lại
        setInterval(() => {
            setisConnected(ex.ws && ex.ws.readyState === 1)
            if (!ex.ws || ex.ws.readyState !== 1) {
                ex.reconnect()
            }
        }, 1000)

        setexchange(ex)
    }, [])

    const clearAmountOrder = (pair: string) => {
        if (pair === "*" || pair === "all") {
            Object.values(forceOrders).forEach((v: any) => {
                v.count = 0;
                v.countBuy = 0;
                v.countSell = 0;
            })
        } else {
            forceOrders[pair].count = 0;
            forceOrders[pair].countBuy = 0;
            forceOrders[pair].countSell = 0;
        }
        updateTable(null)
    }

    const changeVolumeNewOrder = (volume: number) => {
        if (props.only)
            dispatch(change({ "volumeNewOrderOnly": volume }))
        else
            dispatch(change({ "volumeNewOrder": volume }))
        setvolumeNewOrder(volume)
        soundAlerts.speechUtterance.volume = volume / 100

        if (soundAlerts?.Bitcoin)
            soundAlerts.Bitcoin.volume = volume / 100;
        if (soundAlerts?.BUY)
            soundAlerts.BUY.volume = volume / 100;
        if (soundAlerts?.SELL)
            soundAlerts.SELL.volume = volume / 100;
    }

    return (<>
        <Badge.Ribbon text={t(isConnected ? "connected" : "closed")} color={isConnected ? "green" : "red"}>
            <Row style={{ alignItems: "center", display: "flex", justifyContent: "center", padding: "5px" }}>
                <b style={{ fontSize: "large" }}>
                    {t("Force Orders Book")}
                </b>&nbsp;{t("Market orders execute immediately, causing prices to move")}
            </Row>

            <Flex>
                <Input value={settings?.apiKey} placeholder='api key'
                    onChange={onChangeApiKey} />
                <Input value={settings?.secretKey} placeholder='secret key'
                    onChange={onChangeSecretKey} />

                <Button onClick={stream} disabled={isConnected}>{t("Connect")}</Button>

            </Flex>
        </Badge.Ribbon >

        <SliderFight percent={balanceBuySell} />

        <Badge.Ribbon text={<>
            <Tooltip title={<div>{t("Ring when there is a new order")} <br /><Slider value={volumeNewOrder} onChange={changeVolumeNewOrder} /></div>}>
                <Button icon={volumeNewOrder === 0 ? "🔇" : "🔊"} shape="circle" />
            </Tooltip>

            <Tooltip title={t("Clear all count orders")}>
                <Button icon="🧹" onClick={e => clearAmountOrder("all")} shape="circle" />
            </Tooltip>

            &nbsp;{dataSource?.length}</>} placement='start'>

            <Table dataSource={dataSource} columns={columns} scroll={{ x: 100 }} pagination={{ pageSize: 25 }} bordered />
        </Badge.Ribbon>
    </>)

}

export default OrdersBook;