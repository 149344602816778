import React, { useRef, } from 'react';
import { Card, Col, Flex, Progress, Row, Skeleton, Statistic, } from 'antd';
import i18n from '../services/i18n';

import { Bar } from "react-chartjs-2";
import { PriceVolume } from '@services/HeatService';
import { Element } from 'chart.js/auto';
import millify from 'millify';
import BtnCopy from './BtnCopy';
import './PriceVolumeChart.scss';
import { percentChange } from '../utils/std';

export type Item = {
    price: number,
    orderType: 'ask' | 'bid',
    volume: number
}
export type Data = Item[];

type Props = {
    title?: string
    data: Data
    currentPrice?: number
    sumVolumeAsk?: number
    sumVolumeBid?: number

    isDarkTheme?: boolean
    [name: string]: any

    onMaxBid?: (max: PriceVolume & { index: number }) => void
    onMaxAsk?: (max: PriceVolume & { index: number }) => void
}

const defaultProps = {
    title: "Price volume liquid",
    isDarkTheme: true,
    currentPrice: 0
}

// const { log, error } = console;
export enum colors {
    up = '#139904',
    upTransparent = '#13990482',
    down = '#f6465d',
    downTransparent = '#f6465d82',
    blue = '#2962ff',
    orange = '#ff9800',
    gray = 'gray'
};


const PriceVolumeChart: React.FC<Props> = ({ data, title, isDarkTheme, currentPrice, sumVolumeAsk, sumVolumeBid, onMaxBid, onMaxAsk }) => {
    const { t } = i18n;
    const chartContainerRef = useRef();
    const chartRef = useRef(null);

    const styles = {
        color: isDarkTheme ? 'white' : 'black'
    }

    const newData = data.reduce<{
        [price: number]: {
            bid: number, // volume
            ask: number, // volume
        }
    }>((_newData, item: Item) => {
        if (_newData[item.price]) {
            if (item.orderType === 'bid')
                _newData[item.price].bid = item.volume;

            else if (item.orderType === 'ask')
                _newData[item.price].ask = item.volume;
        } else
            _newData[item.price] = {
                bid: (item.orderType === 'bid' ? item.volume : 0),
                ask: (item.orderType === 'ask' ? item.volume : 0),
            }

        return _newData;
    }, {});

    const data2 = Object.entries(newData).sort((a, b) => Number(a[0]) - Number(b[0]));

    const currentPriceAbs = Math.abs(currentPrice);

    const { labels, dataAsks, dataBids, currentPrice: _currentPrice, maxBid, maxAsk } = data2.reduce<{
        labels: number[]
        dataAsks: number[]
        dataBids: number[]
        currentPrice: PriceVolume & { index: number }
        maxBid: PriceVolume & { index: number }
        maxAsk: PriceVolume & { index: number }
    }>((sets, item, index) => {
        const price = Number(item[0]);
        sets.labels.push(price);
        sets.dataAsks.push(item[1].ask);
        sets.dataBids.push(item[1].bid);

        // giá gần nhất
        if (
            Math.abs(Math.abs(price) - currentPriceAbs)
            < Math.abs(Math.abs(sets.currentPrice.price) - currentPriceAbs)
        ) {
            sets.currentPrice.index = index;
            sets.currentPrice.price = price;
            sets.currentPrice.volume = item[1].bid + item[1].ask;
        }

        // tìm khối lượng lớn nhất
        if (sets.maxBid.volume < item[1].bid) {
            sets.maxBid.index = index;
            sets.maxBid.price = price;
            sets.maxBid.volume = item[1].bid;
        }

        if (sets.maxAsk.volume < item[1].ask) {
            sets.maxAsk.index = index;
            sets.maxAsk.price = price;
            sets.maxAsk.volume = item[1].ask;
        }
        return sets;

    }, {
        labels: [],
        dataAsks: [],
        dataBids: [],
        currentPrice: {
            index: 0,
            price: 0,
            volume: 0
        },
        maxBid: {
            index: 0,
            price: 0,
            volume: 0
        },
        maxAsk: {
            index: 0,
            price: 0,
            volume: 0
        }
    });

    onMaxBid?.(maxBid);
    onMaxAsk?.(maxAsk);

    const chartHeightMin = 700;

    let chartHeight = chartHeightMin;
    let h = 0;
    if (chartContainerRef.current) {
        const element = chartContainerRef.current as HTMLElement
        h = Math.floor(element.offsetHeight)
        chartHeight = chartHeightMin > h ? chartHeightMin : h
    }
    if (chartHeightMin < h)
        chartRef.current?.resize();

    const percentChangeBid = percentChange(currentPrice, maxBid.price),
        percentChangeAsk = percentChange(currentPrice, maxAsk.price);

    const percentChangeBidAbs = Math.abs(percentChangeBid),
        percentChangeAskAbs = Math.abs(percentChangeAsk);


    let bidAdvantage = 0,
        askAdvantage = 0;
    if (percentChangeBidAbs > percentChangeAskAbs) {
        askAdvantage = Math.abs(percentChange((percentChangeBidAbs - percentChangeAskAbs), percentChangeAskAbs));
    } else if (percentChangeAskAbs > percentChangeBidAbs) {
        bidAdvantage = Math.abs(percentChange((percentChangeAskAbs - percentChangeBidAbs), percentChangeBidAbs));
    }

    const dataAsksAccumulation: number[] = [];
    const dataBidsAccumulation: number[] = [];

    dataAsks.reduce((sum, v, i) => {
        const s = sum + v;
        dataAsksAccumulation.push(s);
        return s;
    }, 0);

    dataBids.toReversed().reduce((sum, v, i) => {
        const s = sum + v;
        dataBidsAccumulation.push(s);
        return s;
    }, 0);

    const sumVolumeAsk_ = dataAsks.reduce((sum, v, i) => (maxAsk.index <= i ? (sum + v) : sum), 0);
    const sumVolumeBid_ = dataBids.reduce((sum, v, i) => (maxBid.index >= i ? (sum + v) : sum), 0);
    const sumVolume = ((sumVolumeAsk_ ?? maxAsk.volume) + (sumVolumeBid_ ?? maxBid.volume));
    const volumeAdvantage = (sumVolumeBid_ ?? maxBid.volume) / sumVolume * 100;

    return (data && data.length > 0) ? <Flex vertical
        style={{ height: '100%' }}>

        {/* current price , price count */}
        <Row gutter={24}>
            <Col span={12} style={{ padding: '2px' }}>
                <Card bordered={false} style={{ padding: '0px' }} size='small'>
                    <Statistic
                        title={<>{t('Market price')} <BtnCopy value={currentPrice} /></>}
                        value={currentPrice}
                        valueStyle={{ color: '#d89614' }}
                    />
                </Card>
            </Col>

            <Col span={12} style={{ padding: '2px' }}>
                <Card bordered={false} style={{ padding: '0px' }} size='small'>
                    <Statistic
                        title={<>{t('Price quantity')} <BtnCopy value={data.length} /></>}
                        value={data.length}
                    />
                </Card>
            </Col>
        </Row>

        <Row style={{ fontSize: '5px' }}>&nbsp;</Row>

        {/* price, volume Advantage*/}
        <Row gutter={24}>
            <Col span={12} style={{ padding: '2px' }}>
                <Card bordered={false} style={{ padding: '0px' }} size='small'
                    title={<div style={{ color: '#05b90c73' }}>{t('Max Bid')} ▲</div>}>

                    <Statistic
                        title={<>{t('Price')} <BtnCopy value={maxBid.price} /></>}
                        value={maxBid.price}
                        valueStyle={{ color: '#49aa19' }}
                    />

                    <Statistic
                        value={percentChangeBid.toFixed(2)}
                        suffix={'%'}
                        valueStyle={{ color: '#49aa19' }}
                    />
                    <Progress percent={bidAdvantage} size="small" strokeColor={colors.up} showInfo={false} />

                    <Statistic
                        title={<>{t('Volume')} <BtnCopy value={sumVolumeBid_ ?? maxBid.volume} /></>}
                        value={sumVolumeBid_ ?? maxBid.volume}
                        valueStyle={{ color: '#49aa19' }}
                        precision={2}
                    />
                </Card>
            </Col>

            <Col span={12} style={{ padding: '2px' }}>
                <Card bordered={false} style={{ padding: '0px' }} size='small'
                    title={<div style={{ color: '#ff002173' }}>{t('Max Ask')} ▼</div>}>

                    <Statistic
                        title={<>{t('Price')} <BtnCopy value={maxAsk.price} /></>}
                        value={maxAsk.price}
                        valueStyle={{ color: '#dc4446' }}
                    />

                    <Statistic
                        value={percentChangeAsk.toFixed(2)}
                        suffix={'%'}
                        valueStyle={{ color: '#dc4446' }}
                    />
                    <Progress percent={askAdvantage} size="small" strokeColor={colors.down} showInfo={false} style={{ transform: 'rotate(180deg)' }} />

                    <Statistic
                        title={<>{t('Volume')} <BtnCopy value={sumVolumeAsk_ ?? maxAsk.volume} /></>}
                        value={sumVolumeAsk_ ?? maxAsk.volume}
                        valueStyle={{ color: '#dc4446' }}
                        precision={2}
                    />
                </Card>
            </Col>
        </Row>
        <Progress percent={volumeAdvantage} size="small" strokeColor={colors.up} trailColor={colors.down} showInfo={false} />

        {/* chart */}
        <Row ref={chartContainerRef} style={{ height: '100%' }}>
            <Bar
                ref={chartRef}
                style={{ width: '100%', height: chartHeight + 'px' }}
                height={chartHeight}

                data={{
                    labels,
                    datasets: [
                        {
                            label: 'ask',
                            data: dataAsks,
                            backgroundColor: colors.down
                        },
                        {
                            label: 'bid',
                            data: dataBids,
                            backgroundColor: colors.up,
                        },
                        {
                            type: 'line',
                            label: 'Asks Accumulation',
                            data: dataAsksAccumulation,
                            fill: true, // This makes the line chart behave like an area chart
                            backgroundColor: colors.downTransparent,
                            // borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                            tension: 0.4,
                        },
                        {
                            type: 'line',
                            label: 'Bids Accumulation',
                            data: dataBidsAccumulation.reverse(),
                            fill: true, // This makes the line chart behave like an area chart
                            backgroundColor: colors.upTransparent,
                            // borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                            tension: 0.4,
                        },
                    ]
                }}

                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: t(title)
                        },
                    },
                    indexAxis: 'y',

                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            reverse: true, // This reverses the order of the y-axis
                            ticks: {
                                color: function (data) {
                                    if (data.tick.value === _currentPrice.index)
                                        return colors.orange
                                    if (data.tick.value === maxAsk.index)
                                        return colors.down
                                    if (data.tick.value === maxBid.index)
                                        return colors.up
                                    return colors.gray
                                },
                                font: function (data) {
                                    const style = { weight: 'bold' }
                                    if (data.tick.value === _currentPrice.index)
                                        return style;
                                    if (data.tick.value === maxAsk.index)
                                        return style;
                                    if (data.tick.value === maxBid.index)
                                        return style;
                                    return {}
                                },
                            },
                        },
                    },
                    // **********
                    // đánh dấu khối lượng cao nhất và khối lượng thất nhất
                    animation: {
                        duration: 100,
                        onComplete: function (x) {
                            const chart = x.chart;
                            let { ctx } = chart;
                            // Loop through each data in the datasets
                            chart.data.datasets.forEach((dataset, i) => {
                                // ask : i=0
                                // bid : i=1
                                let meta = chart.getDatasetMeta(i);
                                meta.data.forEach((bar: Element & { height: number }, index) => {
                                    let data = dataset.data[index];

                                    if (typeof data === "number") {
                                        const { x, y } = bar.tooltipPosition(false);

                                        ctx.fillStyle = styles.color;
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'middle';

                                        // giá hiện tại
                                        if (i === 0 && _currentPrice.index === index) {
                                            ctx.fillText(`◄${millify(currentPrice)}`, x, y);
                                        }
                                        // giá khối lượng bán cao nhất
                                        else if (i === 0 && maxAsk.index === index && maxAsk.volume > 0) {
                                            ctx.fillText(`▼${millify(maxAsk.price)}`, x, y);
                                        }
                                        // giá khối lượng bán thấp nhất
                                        else if (i === 1 && maxBid.index === index && maxBid.volume > 0) {
                                            ctx.fillText(`▲${millify(maxBid.price)}`, x, y);
                                        }
                                    }
                                });
                            });
                        }
                    }
                }}

            />
        </Row>

    </Flex>
        : <Row>
            no data
            <Skeleton active />
        </Row>
};

PriceVolumeChart.defaultProps = defaultProps

export default PriceVolumeChart;
