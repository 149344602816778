import axios from 'axios';
import { StableCoins, WRAPCOINS } from './AltcoinSeason';
import { EventEmitter } from 'events';

class Coinmarketcap extends EventEmitter {
    API_KEY
    constructor(API_KEY = "84d67503-836f-49a3-8c23-9ee8fb81fd97") {
        super()
        this.API_KEY = API_KEY;
    }

    async getTopLargestCap(LIMIT = 100) {
        let limit = LIMIT * 2;
        const response = await axios.get(
            `https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=${limit}&convert=USD`,
            {
                headers: {
                    'X-CMC_PRO_API_KEY': this.API_KEY,
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );

        let coins = response.data.data.map((coin: { name: any; symbol: any; quote: { USD: { market_cap: any; price: any; volume_24h: any; percent_change_24h: any; }; }; }) => ({
            name: coin.name,
            symbol: coin.symbol,
            marketCap: Number(coin.quote.USD.market_cap),
            price: coin.quote.USD.price,
            volume24h: coin.quote.USD.volume_24h,
            percentChange24h: coin.quote.USD.percent_change_24h,
        })).filter((c: { symbol: string; }) => !StableCoins.includes(c.symbol) && !WRAPCOINS.includes(c.symbol))

        coins.sort((a: { marketCap: number; }, b: { marketCap: number; }) => b.marketCap - a.marketCap)
        return coins.slice(0, LIMIT);
    }

    async getInfo(symbol: any) {
        const response = await axios.get(
            `https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=${symbol}`,
            {
                headers: {
                    'X-CMC_PRO_API_KEY': this.API_KEY,
                },
            }
        );
        return response.data.data;
    }

}

export default Coinmarketcap;