import React, { useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";
import { Button, Card, Checkbox, Flex, Progress, Space, Switch, Tag } from 'antd';
import i18n from '../services/i18n';

import { change, } from '../store/settings';
import Exchange, { RealTimeframes, TIMEFRAMES } from '../utils/Exchanges';
import Binance from '../utils/Binance';

const { log } = console;


type Props = {
    [name: string]: any,
    timeframes?: RealTimeframes,
    selected: string[],
    onChange?: (timeframe: string) => void
}


const TimeframesSelect: React.FC<Props> = ({ timeframes, selected, onChange, }) => {
    const dispatch = useStoreDispatch();
    const { t } = i18n
    const settings = useStore((state) => state.settings);
    const mounted = useRef(false);
    const [count, setcount] = useState<number>(0)

    const [Timeframes, setTimeframes] = useState<RealTimeframes>({})
    const services = useStore((state) => state.services);

    let exchange: Exchange

    useEffect(() => {
        if (!mounted.current) {
            exchange = new Binance()

            let wait = setInterval(() => {
                if (exchange && exchange.isConnected) {
                    setInterval(() => {
                        updateTime()
                    }, 1000);
                    clearInterval(wait)
                }
            }, 500)
        }
        mounted.current = true;
    }, []);

    const updateTime = () => {
        if (!timeframes || Object.entries(timeframes).length === 0) {
            if (exchange && exchange.isConnected && exchange.Timeframes) {
                setTimeframes(exchange.Timeframes)
            } else
                setTimeframes({})
        } else
            setTimeframes(timeframes)
        setcount(Date.now());
    }

    const onSelectAll = (e) => {
        Object.entries(Timeframes).map(([timeframe,]) => {
            if (e.target.checked) {
                if (!selected.includes(timeframe))
                    onChange(timeframe)

            } else if (selected.includes(timeframe))
                onChange(timeframe)
        })
    }

    const onSelectHideOther = (e) => {
        dispatch(change({ "hideOthers": e.target.checked }))
    }


    const onSelectScap = () => {
        let list = [TIMEFRAMES.m1, TIMEFRAMES.h1, TIMEFRAMES.h8]
        Promise.all(Object.entries(Timeframes).map(([timeframe,]) => {
            if (list.includes(timeframe) && !selected.includes(timeframe))
                onChange(timeframe)

            if (!list.includes(timeframe) && selected.includes(timeframe))
                onChange(timeframe)
        })).then(r => {
            onChange(list[0])
            setTimeout(() => {
                onChange(list[0])
            }, 500);
        })
        changeFutureOnly()
    }

    const onSelectFuture = () => {
        let list = [TIMEFRAMES.h1, TIMEFRAMES.h8, TIMEFRAMES.w, TIMEFRAMES.M1]
        Promise.all(Object.entries(Timeframes).map(([timeframe,]) => {
            if (list.includes(timeframe) && !selected.includes(timeframe))
                onChange(timeframe)

            if (!list.includes(timeframe) && selected.includes(timeframe))
                onChange(timeframe)
        })).then(r => {
            onChange(list[0])
            setTimeout(() => {
                onChange(list[0])
            }, 500);
        })
        changeFutureOnly()
    }

    const onSelectSpot = () => {
        let list = [TIMEFRAMES.d1, TIMEFRAMES.w, TIMEFRAMES.M1, TIMEFRAMES.Y1]
        Promise.all(Object.entries(Timeframes).map(async ([timeframe,]) => {
            if (list.includes(timeframe) && !selected.includes(timeframe))
                onChange(timeframe)

            if (!list.includes(timeframe) && selected.includes(timeframe))
                onChange(timeframe)
        })).then(r => {
            onChange(list[0])
            setTimeout(() => {
                onChange(list[0])
            }, 500);
        })
        changeFutureOnly(false)
    }

    const changeFutureOnly = (checked = true) => {
        services?.altcoinSeason?.setFutureOnly(checked).then(() => {
            setcount(count + 1)
            services?.altcoinSeason?.getFutureOnly()
        })
    }

    return (<>
        <div style={{ scrollbarWidth: 'thin' }}>
            <Checkbox onChange={onSelectAll} indeterminate={selected.length > 0 && selected.length < Object.keys(Timeframes).length} checked={selected.length === Object.keys(Timeframes).length}>
                {t("all")}
            </Checkbox>
            <Checkbox onChange={onSelectHideOther} checked={settings?.hideOthers}>
                {t("Hide others")}
            </Checkbox>

            <Button onClick={onSelectScap} style={{ padding: "0px 5px" }}>
                {t("Scap")}
            </Button>
            <Button onClick={onSelectFuture} style={{ padding: "0px 5px" }}>
                {t("Future")}
            </Button>
            <Button onClick={onSelectSpot} style={{ padding: "0px 5px" }}>
                {t("Spot")}
            </Button>&nbsp;
        </div>

        <Flex style={{ overflowX: "scroll", scrollbarWidth: 'thin' }}>
            {Object.entries(Timeframes).map(([timeframe, info]) =>

                <Card style={{ textAlign: "center", alignContent: "center", display: (settings?.hideOthers && !selected.includes(timeframe)) ? "none" : "block" }}>
                    <Space.Compact direction="vertical">

                        <Progress type="circle" size={'small'} strokeColor={TIMEFRAMES.toColor(timeframe)}
                            percent={Math.floor(info.leftPercent)}
                            format={() =>
                                <Switch checked={selected.includes(timeframe)} onChange={() => onChange(timeframe)}
                                    checkedChildren={timeframe} unCheckedChildren={timeframe} />}
                        />

                        <Tag bordered={true} style={{ margin: "auto", marginTop: "0.5em" }}>{info.leftString || " "}</Tag>
                    </Space.Compact>
                </Card>
            )}
        </Flex>
    </>);
};

export default TimeframesSelect;
