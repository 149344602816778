import React, { useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";
import { LineChartOutlined, SearchOutlined, PlayCircleOutlined, FilterOutlined, ClearOutlined, DownloadOutlined, } from '@ant-design/icons';
import { Badge, Button, Checkbox, DatePicker, DatePickerProps, Flex, FloatButton, Form, Input, InputNumber, InputRef, Modal, Row, Slider, Space, Switch, Table, Tag, Tooltip, message, notification, } from 'antd';
import Highlighter from 'react-highlight-words';
import millify from "millify";
import i18n from '../services/i18n';
import { downloadFile } from '../utils/std';


import { change, settingsEvent, } from '../store/settings';
import { startAll, } from '../store/services';
import AltcoinSeasonPointsChart from '../components/AltcoinSeasonPointsChart';
import { AltcoinSeasonPoint, PairPointTypes } from '../utils/AltcoinSeason';
import { TIMEFRAMES, PairInfo } from '../utils/Exchanges';
import BtnCopy from '../components/BtnCopy';
import TimeframesSelect from '../components/TimeframesSelect';
import { default_listenTimeframes } from '../services/AltcoinSeasonService';

import { ColumnType } from 'antd/es/table';
import { ColumnsType, FilterConfirmProps } from 'antd/es/table/interface';

import "./Signals.scss"
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';

const { log, error, } = console

type RowType = {
    symbol: string, point: number,
}
type RowIndex = keyof RowType;


const Signals: React.FC = () => {
    const dispatch = useStoreDispatch();
    const { t } = i18n
    const settings = useStore((state) => state.settings);
    const services = useStore((state) => state.services);

    const [states, setStates] = useState<any>({
        isChangeColumns: true,
        timeframes: {},
        timeframesMA: {},
        valueTypes: {
            changed: 'changed',
            volume: 'volume',
            MA: 'MA'
        }
    })

    const [s, sets] = useState({ MessageTone: new Audio('/sounds/MessageTone.mp3') })
    const [volumeAutoCalFinish, setvolumeAutoCalFinish] = useState(50)

    const mounted = useRef(false);
    const [count, setcount] = useState<number>(0)
    const [isConnected, setisConnected] = useState(false)
    const [pairStatus, setpairStatus] = useState("")

    const [dataSource, setdataSource] = useState<any[]>([])
    const [columns, setcolumns] = useState([])


    let [listenTimeframes, setlistenTimeframes] = useState(default_listenTimeframes)
    let [amountPairs, setamountPairs] = useState(200)

    let [points, setpoints] = useState<{ [timeframe: string]: AltcoinSeasonPoint[] }>({})
    let [subscribePoints, setsubscribePoints] = useState(Date.now())
    let [subscribePairs, setsubscribePairs] = useState(Date.now())

    const [formFilter] = Form.useForm();

    // do componentDidMount logic
    useEffect(() => {
        if (!mounted.current) {
            log(s.MessageTone)
            s.MessageTone.volume = volumeAutoCalFinish / 100;
            settingsEvent.on("loaded", (settings) => {
                if (!isNaN(settings?.volumeAutoCalFinish)) {
                    setvolumeAutoCalFinish(settings.volumeAutoCalFinish)
                    s.MessageTone.volume = settings.volumeAutoCalFinish / 100;
                    setcount(count + 1)
                }
            })

            services?.altcoinSeason?.getListenTimeframes().then((tfs: string[]) => {
                if (tfs) {
                    setlistenTimeframes(tfs)

                    // if (settings?.startTime && settings?.startTime !== services?.altcoinSeason?.startTime) {
                    //     let startTime = dayjs().subtract(TIMEFRAMES.toMiliSecond(tfs[0]) * 1000)
                    //     services?.altcoinSeason?.setStartTime(startTime)
                    //     // dispatch(change({ "startTime": startTime.valueOf() }))
                    // }
                }
            })

            services?.altcoinSeason
                .once("pairs", pairs => {
                    message.success(pairs.length + " " + t("pairs"))
                })
                .on("init", ({ symbol, pairs, klines, timeframe, countSymbolsKlines }) => {
                    setpairStatus(" -" + (services?.altcoinSeason.amountPairs - countSymbolsKlines) + "/" + services?.altcoinSeason.amountPairs + " " + symbol)
                    showPairs()
                })
                .on("point", ({ timeframe, point }) => {
                    points[timeframe] = point
                    setpoints(points);

                }).on("points", points => {
                    showPairs()
                    setpoints(points);
                    setamountPairs(services?.altcoinSeason?.amountPairs || amountPairs)
                    setTimeout(() => {
                        setpairStatus("")
                    }, 5000);
                    setsubscribePoints(Date.now())
                    ringDoneCalPoints()

                }).on("newCandles", ({ symbol: symbol, index, length }) => {
                    setpairStatus(" -" + (length - index) + "/" + length + " " + symbol)
                    showPairs()

                }).on("newTimeframe", (info: PairInfo) => {
                    showPairs()
                })
        }

        mounted.current = true;
    }, []);

    useEffect(() => {
        if (settings?.startTime && settings?.startTime !== services?.altcoinSeason?.startTime) {
            services?.altcoinSeason?.setStartTime(settings?.startTime)
        }
    }, [settings?.startTime])

    const startAltcoinSeason = () => {
        dispatch(startAll()).then(() => {
            setTimeout(() => {
                setisConnected(services?.altcoinSeason?.exchange?.isConnected)
            }, 1000);
        })
    }

    const [exchangeButtons, setexchangeButtons] = useState<JSX.Element[]>([])
    const [pair, setpair] = useState<PairInfo>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (symbol: string) => {
        let pair: PairInfo = services?.altcoinSeason?.Pairs[symbol]
        setpair(pair)
        setsubscribePairs(Date.now())
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (pair) {
            let linkBinance = services?.altcoinSeason?.futureOnly ? `https://www.binance.com/futures/${pair.baseAsset}${pair.quoteAsset}` : `https://www.binance.com/trade/${pair.baseAsset}_${pair.quoteAsset}?type=spot`
            let linkBybit = services?.altcoinSeason?.futureOnly ? `https://www.bybit.com/trade/usdt/${pair.baseAsset}${pair.quoteAsset}` : `https://www.bybit.com/trade/spot/${pair.baseAsset}/${pair.quoteAsset}`
            let exchangeButtons = [
                <Button type='link' href={linkBinance} target="_blank" icon={<img src='/images/binance.png' height={23} />}></Button>,
                <Button type='link' href={linkBybit} target="_blank" icon={<img src='/images/bybit.svg' height={10} />} style={{ border: "1px solid #424242", backgroundColor: "#4a8209" }}></Button>
            ]
            setexchangeButtons(exchangeButtons)
        }
    }, [pair])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: RowIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    /**
     * Lọc theo cột
     * @param dataIndex 
     */
    const getColumnSearchProps = (dataIndex: RowIndex): ColumnType<RowType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t("Search") + " " + dataIndex}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("Search")}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("Reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        {t("Filter")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    useEffect(() => {
        Object.entries(services?.altcoinSeason?.timeframes).forEach(([timeframe, options]) => {
            Object.entries(options).forEach(([key, value]) => {
                formFilter.setFieldValue(`timeframe.${timeframe}.${key}`, value)
            })
        })
    }, [columns])

    const ringDoneCalPoints = () => {
        if (volumeAutoCalFinish) {
            s.MessageTone.play();
        }
    }

    const onformTimeframesChange = (e) => {
        log(e)
        let path = e.target.id.split(".")
        if (path[0] === "timeframe") {
            let value = formFilter.getFieldValue(e.target.id);
            if (!isNaN(value) && typeof (value) !== 'boolean')
                value = Number(value)

            services?.
                altcoinSeason
                .changeTimeframes(e.target.id.slice("timeframe.".length), value)
                .then(() => {
                    Object.entries(services?.altcoinSeason?.timeframes).forEach(([timeframe, options]) => {
                        Object.entries(options).forEach(([key, value]) => {
                            formFilter.setFieldValue(`timeframe.${timeframe}.${key}`, value)
                        })
                    })
                })
        }
    }

    const onFilterTimeframes = (value, record, dataIndex): boolean => {
        log(value)
        let symbol = record[dataIndex]

        let pairs: { [symbol: string]: PairInfo } = services?.altcoinSeason?.Pairs
        if (pairs) {
            log(pairs[symbol].points)
        }
        return true
    }

    const handleSearchByTimeframe = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: RowIndex,
    ) => {
        // duyệt các pair, tính lại điểm hiện tại theo cài đặt
        Object.entries(services?.altcoinSeason?.Pairs).forEach(([symbol, info]) => {
            services?.altcoinSeason?.alert(symbol, info.points, true)
        })
        showPairs(false)
        confirm();
        setSearchedColumn(dataIndex);
    };

    const handleResetByTimeframe = (clearFilters: () => void) => {
        formFilter.resetFields();
        clearFilters();
    };

    /**
     * Lọc: nếu trong khoảng thời gian dài dữ liệu nằm dưới hoặc nằm trên đường giới hạn min max
     */
    const getColumnFilterByTimeframe = (dataIndex: RowIndex): ColumnType<RowType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            // lấy phần tử đầu tiên
            let pair: PairInfo = Object.values(services?.altcoinSeason?.Pairs)[0]
            if (pair) {
                return (<Form form={formFilter} onChange={onformTimeframesChange}
                    onSubmitCapture={() => handleSearchByTimeframe(selectedKeys as string[], confirm, dataIndex)}>
                    <Flex vertical style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        {
                            Object.entries(pair.points).map(([timeframe, points]) => {
                                return (
                                    <Space.Compact style={{ alignItems: "center" }}>

                                        <Form.Item name={`timeframe.${timeframe}.active`} valuePropName="checked">
                                            <Checkbox style={{ minWidth: "4em" }}>{timeframe}</Checkbox>
                                        </Form.Item>

                                        <Form.Item name={`timeframe.${timeframe}.smooth`}>
                                            <InputNumber
                                                placeholder={t("Amount candles")}
                                                changeOnWheel={true}
                                            />
                                        </Form.Item>

                                        <Form.Item name={`timeframe.${timeframe}.min`}>
                                            <InputNumber
                                                placeholder={t("Min")} style={{ maxWidth: "10em" }}
                                                changeOnWheel={true}
                                            />
                                        </Form.Item>

                                        <Tooltip title={t("In range")}>
                                            <Form.Item name={`timeframe.${timeframe}.inRange`} valuePropName="checked">
                                                <Checkbox />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item name={`timeframe.${timeframe}.max`}>
                                            <InputNumber
                                                placeholder={t("Max")} style={{ maxWidth: "10em" }}
                                                changeOnWheel={true}
                                            />
                                        </Form.Item>
                                    </Space.Compact>
                                )
                            })
                        }

                        {/* <Input
                            ref={searchInput}
                            placeholder={t("Search") + " " + dataIndex}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearchByTimeframe(selectedKeys as string[], confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }}
                        /> */}
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearchByTimeframe(selectedKeys as string[], confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                {t("Search")}
                            </Button>
                            <Button
                                onClick={() => clearFilters && handleResetByTimeframe(clearFilters)}
                                size="small"
                                style={{ width: 90 }}
                            >
                                {t("Reset")}
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    confirm({ closeDropdown: false });
                                    setSearchedColumn(dataIndex);
                                }}
                            >
                                {t("Filter")}
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    close();
                                }}
                            >
                                {t("close")}
                            </Button>
                        </Space>
                    </Flex>
                </Form>)
            }
            return <></>
        },
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} width={"5em"} />
        ),
        onFilter: (value, record) => onFilterTimeframes(value, record, dataIndex),
    });

    // setData
    const showPairs = (isChangeColumns = true) => {
        let pairs: {
            [symbol: string]: {
                point?: number
                points?: {
                    [timeframe: string]: {
                        volume: number,
                        changed: number,

                        // khoảng cách đường MA với giá đóng cửa
                        MA?: number,
                    }
                },
                quoteVolume?: number,
                baseAsset?: string,
                quoteAsset?: string,
            }
        } = services?.altcoinSeason?.currentPoints

        if (pairs) {
            let timeframes = {}
            let timeframesMA = {}

            let dataSource = Object.entries(pairs).map(([symbol, info], i) => {
                // 2 điều kiện min max 
                let isOks: boolean[] = []

                let { points, quoteVolume } = info
                const allPoints = {
                    changed: [],
                    volume: [],
                    MA: []
                }

                let data_points: {
                    [name: `points.${string}.${string}`]: number
                } = Object.entries(points).reduce((s, v) => {
                    let [timeframe, value] = v
                    timeframes[timeframe] = timeframe

                    // Lọc hiển thị theo điều kiện giá trị của timeframe trong hoặc ngoài khoảng min max 
                    if (services.altcoinSeason.timeframes[timeframe] && services.altcoinSeason.timeframes[timeframe].active) {
                        let { min, max, inRange } = services.altcoinSeason.timeframes[timeframe]

                        if (inRange) {
                            isOks.push((value.volume <= max) && (value.volume >= min))
                        } else {
                            isOks.push((value.volume > max) || (value.volume < min))
                        }
                    }

                    s[`points.${timeframe}.changed`] = value.changed
                    if (!isNaN(value.changed)) {
                        allPoints.changed.push(value.changed)
                    }

                    s[`points.${timeframe}.volume`] = value.volume
                    if (!isNaN(value.volume)) {
                        allPoints.volume.push(value.volume)
                    }

                    if (!isNaN(value.MA)) {
                        s[`points.${timeframe}.MA`] = value.MA
                        allPoints.MA.push(value.MA)
                        timeframesMA[timeframe] = timeframe
                    }
                    return s
                }, {})

                const avgs = Object.entries(allPoints).reduce((
                    s: { [avg: `avgs.${string}`]: number },
                    v: [name: `points.${string}.${string}`, points: number[]]
                ) => {

                    const [name, points] = v;
                    if (points.length) {
                        const parts = name.split('.');
                        const valueType = parts[parts.length - 1].trim();
                        s[`avgs.${valueType}`] = points.reduce((sum: number, point: number) => sum + point, 0) / points.length;
                        states.valueTypes[valueType] = valueType;
                    }
                    return s;
                }, {})

                // nếu thỏa mãn 2 điều kiện min max thì hiển thị
                if (isOks.every(v => v === true))
                    return {
                        "#": i + 1,
                        "symbol": symbol,

                        "view": {
                            symbol,
                            baseAsset: info.baseAsset,
                            quoteAsset: info.quoteAsset
                        },

                        ...avgs,

                        ...data_points,

                        "quoteVolume": quoteVolume
                    }


            }).filter(v => v)
            setStates({ ...states, isChangeColumns, timeframes, timeframesMA })
            setdataSource(dataSource)

        }
    }

    useEffect(() => {
        if (dataSource && dataSource.length > 0) {

            const sorter = (key, a, b) => a[key] - b[key];
            const sorterString = (key, a, b) => {
                return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : (a[key].toUpperCase() > b[key].toUpperCase() ? 1 : 0)
            };

            function renderPointCell(value, record) {
                let color = isNaN(value) ? "" : value >= settings?.altcoinSeason?.max ? "red" : value <= settings?.altcoinSeason?.min ? "green" : "orange"
                return {
                    children: <Tag color={color}>{isNaN(value) ? value : value.toFixed()}</Tag>
                };
            }
            function renderMACell(value, record) {
                let color = isNaN(value) ? "" : value < -5 ? "green" : value > 5 ? "red" : "orange"
                return {
                    children: <Tag color={color}>{isNaN(value) ? value : value.toFixed()}</Tag>
                };
            }

            let points_columns = Object.values(states.timeframes).map((timeframe: string) => {
                let children = [
                    {
                        title: <Tooltip title={t("volume")} color='blue'><div>volume</div></Tooltip>,
                        dataIndex: `points.${timeframe}.volume`,
                        key: `points.${timeframe}.volume`,
                        sorter: (a, b) => sorter(`points.${timeframe}.volume`, a, b),
                        render: renderPointCell
                    }, {
                        title: <Tooltip title={t("level of price change")} color='blue'><div>changed</div></Tooltip>,
                        dataIndex: `points.${timeframe}.changed`,
                        key: `points.${timeframe}.changed`,
                        sorter: (a, b) => sorter(`points.${timeframe}.changed`, a, b),
                        render: renderPointCell
                    },
                ]

                if (states.timeframesMA[timeframe]) {
                    children.push({
                        title: <Tooltip title={t("% distance MA with close price")} color='blue'><div>SMA %</div></Tooltip>,
                        dataIndex: `points.${timeframe}.MA`,
                        key: `points.${timeframe}.MA`,
                        sorter: (a, b) => sorter(`points.${timeframe}.MA`, a, b),
                        render: renderMACell
                    })
                }

                return {
                    title: <label style={{ color: TIMEFRAMES.toColor(timeframe) }}>{timeframe}</label>,
                    children,
                }
            })

            let avg_points_columns = Object.keys(states.valueTypes).map((valueType: string) => {
                return {
                    title: <Tooltip title={t(valueType)} color='blue'><div>{valueType}</div></Tooltip>,
                    dataIndex: `avgs.${valueType}`,
                    key: `avgs.${valueType}`,
                    sorter: (a, b) => sorter(`avgs.${valueType}`, a, b),
                    render: valueType === 'MA' ? renderMACell : renderPointCell
                }
            });


            let columns: ColumnsType = [
                {
                    title: "#",
                    dataIndex: "#",
                    key: "#",
                    sorter: (a, b) => sorter("#", a, b),
                },
                {
                    title: <Tooltip title={t("symbol")} color='blue'><div>{t("symbol")}</div></Tooltip>,
                    dataIndex: "symbol",
                    key: "symbol",
                    sorter: (a, b) => sorterString("symbol", a, b),
                    ...getColumnSearchProps("symbol"),
                    render: (value, record) => {
                        return {
                            children: <>{value} <BtnCopy value={value} /></>
                        };
                    },
                },
                {
                    title: t("view"),
                    dataIndex: "view",
                    key: "view",
                    render: (value, record) => {
                        let linkBinance = services?.altcoinSeason?.futureOnly ? `https://www.binance.com/futures/${value.baseAsset}${value.quoteAsset}` : `https://www.binance.com/trade/${value.baseAsset}_${value.quoteAsset}?type=spot`
                        let linkBybit = services?.altcoinSeason?.futureOnly ? `https://www.bybit.com/trade/usdt/${value.baseAsset}${value.quoteAsset}` : `https://www.bybit.com/trade/spot/${value.baseAsset}/${value.quoteAsset}`

                        return {
                            children:
                                <Flex style={{ alignItems: "center" }}>
                                    <Button type='link' href={linkBinance} target="_blank" icon={<img src='/images/binance.png' height={23} />}></Button>
                                    <Button type='link' href={linkBybit} target="_blank" icon={<img src='/images/bybit.svg' height={10} />} style={{ border: "1px solid #424242", backgroundColor: "#4a8209" }}></Button>
                                    <Button type='link' href={`/pairs/${value.baseAsset}-${value.quoteAsset}`} target="_blank" icon={<LineChartOutlined style={{ fontSize: 30 }} />} style={{ border: "1px solid #424242" }}></Button>
                                    <Button onClick={() => showModal(value.symbol)} icon={<LineChartOutlined />} />
                                </Flex>
                        }
                    }
                },
                {
                    title: <Tooltip title={t("Average points")} color='blue'><div>{t("Averages")}</div></Tooltip>,
                    children: avg_points_columns
                },
                {
                    title: <Tooltip title={t("points")} color='blue'><div>{t("points")}</div></Tooltip>,
                    ...getColumnFilterByTimeframe(`symbol`),
                    children: points_columns
                },
                {
                    title: <Tooltip title={t("quoteVolume")} color='blue'><div>quote volume $</div></Tooltip>,
                    dataIndex: "quoteVolume",
                    key: "quoteVolume",
                    sorter: (a, b) => sorter("quoteVolume", a, b),
                    render: (value, record) => ({
                        children: <>{millify(value)}</>
                    })
                },
            ]

            setcolumns(columns)
        }
    }, [dataSource, services?.altcoinSeason?.futureOnly, settings?.altcoinSeasonPair?.min, settings?.altcoinSeasonPair?.max, settings?.altcoinSeason?.min, settings?.altcoinSeason?.max])

    /**
     * khi timeframes được chọn thay đổi, lưu lại
     * @param timeframe 
     */
    const onTimeframesChanged = (timeframe: string) => {
        let index = listenTimeframes.findIndex(v => v === timeframe)
        if (index < 0)
            listenTimeframes.push(timeframe)
        else
            listenTimeframes.splice(index, 1)
        listenTimeframes.sort((a, b) => TIMEFRAMES.toMiliSecond(a) - TIMEFRAMES.toMiliSecond(b))
        setlistenTimeframes(listenTimeframes)
        setcount(Date.now())

        if (index < 0 && services?.altcoinSeason?.exchange)
            services?.altcoinSeason?.addTimeframe(timeframe)
        else
            services?.altcoinSeason?.setListenTimeframes(listenTimeframes)
    }

    useEffect(() => {
        setamountPairs(services?.altcoinSeason?.amountPairs)
    }, [services?.altcoinSeason?.amountPairs])

    const onAmountPairs = (value) => {
        let v = Math.abs(Math.round(Number(value)))
        if (v < 1)
            v = 1
        setamountPairs(v)
        services?.altcoinSeason?.setAmountPairs(v)
    }

    useEffect(() => {
        if (typeof (settings?.altcoinSeason?.autoUpdate) === "boolean")
            services.altcoinSeason.autoUpdate = settings?.altcoinSeason?.autoUpdate
    }, [settings?.altcoinSeason?.autoUpdate])

    const setAutoUpdate = (checked: boolean) => {
        dispatch(change({ "altcoinSeason.autoUpdate": checked }))
            .then((r: any) => {
                if (r.error) {
                    notification.error({ message: "change altcoinSeason autoUpdate" })
                    error(r.error)
                }
            })
    }

    const setAlertMessage = (checked: boolean) => {
        services?.altcoinSeason?.setSendAlert(checked).then(() => {
            setcount(count + 1)
            // services?.altcoinSeason?.getFutureOnly()
        })
    }

    const changeFutureOnly = (checked) => {
        services?.altcoinSeason?.setFutureOnly(checked).then(() => {
            setcount(count + 1)
            services?.altcoinSeason?.getFutureOnly()
        })
    }

    const changeStartTime = (
        value: DatePickerProps['value'] | RangePickerProps['value'], _) => {

        try {
            services?.altcoinSeason?.setStartTime(value)
            dispatch(change({ "startTime": value.valueOf() }))
        } catch (err) {
            console.error(err);
            let before: Dayjs = dayjs(err.before)//.add(settings?.timezone.deviation)
            let message = <>{t(err.message)} <br /> {before.format('YYYY/M/D H:m:s Z')}</>
            notification.error({ message })
        }
    }

    const changeisStartBefore = () => {
        services?.altcoinSeason?.changeisStartBefore().then(e => setcount(count + 1))
    }

    const clearFilters = () => {
        services?.altcoinSeason.deactiveTimeframes().then(() => {
            if (isConnected)
                showPairs(true)
        })
    }

    const changeVolumeAutoCalFinish = (volume: number) => {
        dispatch(change({ "volumeAutoCalFinish": volume }))
        setvolumeAutoCalFinish(volume)
        if (s.MessageTone)
            s.MessageTone.volume = volume / 100;
        setcount(count + 1)
    }

    const downloadAllPairs = (e) => {
        let header = []
        let data = dataSource.map((row => {
            let { view: _, ...rest } = row
            if (Object.keys(rest).length > header.length)
                header = Object.keys(rest)
            return Object.values(rest).join(",")
        }))
        data.unshift(header.join(","))
        downloadFile(
            data.join("\n"),
            "list" + dayjs(Date.now()).format("YYYY-MM-DD HH:mm")
        )
    }

    const cleanData = () => {
        services?.altcoinSeason?.cleanData().then((r) => {
            message.success(t("Clean app success"))
            document.location.reload()
        });
    }

    return (<>
        <Badge.Ribbon text={t(isConnected ? "connected" : "closed")} color={isConnected ? "green" : "red"}>
        </Badge.Ribbon >
        <Space wrap>
            <Button icon={<ClearOutlined />} onClick={cleanData}>{t("Clean data")}</Button>
            <Tooltip title={t("The starting time of the candle to calculate")}>
                <DatePicker onChange={changeStartTime} showTime value={dayjs(settings?.startTime)} />
            </Tooltip>

            <Tooltip title={
                services?.altcoinSeason?.isStartBefore ?
                    t("The coins are listed on the exchange before a certain time") :
                    t("The coins are listed on the exchange after a certain time")
            }>
                <Button shape="circle" icon={services?.altcoinSeason?.isStartBefore ? ">" : "<"} onClick={changeisStartBefore} />
            </Tooltip>

            <Switch
                checked={services?.altcoinSeason?.futureOnly}
                onChange={changeFutureOnly}
                checkedChildren={t("Future")}
                unCheckedChildren={t("Spot")}
            />

            {/* Start */}
            <Space.Compact>
                <Tooltip title={t("Limit pair watch")}>
                    <InputNumber
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        changeOnWheel={true}
                        placeholder="Input a number"
                        value={amountPairs}

                        onChange={onAmountPairs}
                        onPressEnter={startAltcoinSeason}
                    />
                </Tooltip>

                <Button onClick={startAltcoinSeason} disabled={isConnected} icon={<PlayCircleOutlined />} type='primary' >{t("Start")}</Button>
            </Space.Compact>

            <Tooltip title={pairStatus} color={"orange"} open={pairStatus !== ""}>
                <Button onClick={() => showPairs()} disabled={!isConnected}>{t("Pairs")}</Button>
            </Tooltip>

            <Tooltip title={t("Auto calculate Altcoin season when close candle")}>
                <Switch
                    checked={settings?.altcoinSeason?.autoUpdate}
                    onChange={setAutoUpdate}
                    checkedChildren={t("Auto update")}
                    unCheckedChildren={t("Auto update")}
                />
            </Tooltip>

            <Tooltip title={<div>{t("Sound alert when auto calculate finish")}  <br /><Slider value={volumeAutoCalFinish} onChange={changeVolumeAutoCalFinish} /></div>}>
                <Button shape="circle" icon={volumeAutoCalFinish === 0 ? "🔇" : "🔊"} />
            </Tooltip>

            <Tooltip title={t("Auto send message alert to telegram")}>
                <Switch
                    checked={services?.altcoinSeason?.sendAlert}
                    onChange={setAlertMessage}
                    checkedChildren={"🔔 " + t("Alert")}
                    unCheckedChildren={"🔔 " + t("Alert")}
                />
            </Tooltip>

        </Space>

        <TimeframesSelect timeframes={services?.altcoinSeason?.exchange?.Timeframes || {}} selected={listenTimeframes} onChange={onTimeframesChanged} />

        {/* title="Altcoin season Performance Bitcoin" */}
        <AltcoinSeasonPointsChart pair={services?.altcoinSeason?.Pairs?.["BTCUSDT"]} points={points} subscribe={subscribePoints} />
        <Row><br /><br /></Row>

        <Badge.Ribbon text={<>
            {t("Altcoin Pairs")}:
        </>} placement='start'>
            <Row><br /><br /></Row>
        </Badge.Ribbon>

        <Badge.Ribbon text={<>
            {dataSource.length} &nbsp;

            <Tooltip title={t("Download")}>
                <DownloadOutlined style={{ cursor: "pointer" }} onClick={downloadAllPairs} />
            </Tooltip>&nbsp;

            <Tooltip title={t("Clear filter")}>
                <span onClick={clearFilters}>
                    <ClearOutlined style={{ cursor: "pointer" }} /><FilterOutlined style={{ cursor: "pointer" }} />
                </span>
            </Tooltip></>} placement='start'>

            <Table dataSource={dataSource} columns={columns} scroll={{ x: 100 }} pagination={{ pageSize: 25, position: ['topRight', 'bottomRight'] }} bordered />

        </Badge.Ribbon>

        <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={"100%"} style={{ padding: 0, height: "100%" }}>
            <AltcoinSeasonPointsChart pair={pair} points={pair?.points} subscribe={subscribePairs} DataTypes={"PairPointTypes"} exchangeButtons={exchangeButtons} />
        </Modal>

        <FloatButton
            badge={{
                count: pairStatus
            }}
            description={pairStatus}
            shape="square"
        />
    </>);
};

export default Signals;
