/**
 * https://binance-docs.github.io/apidocs/futures/en/#partial-book-depth-streams
 * Kết nối tới sàn, lấy dữ liệu stream về. Tách ra thành vùng giá, khối lượng
 */

import Exchange, { ExchangeName } from "../utils/Exchanges";
import BinanceFuture from "../utils/BinanceFuture";
import { Service } from ".";
const { log } = console;



export type PriceVolume = {
    price: number,
    volume: number
}

export type PriceVolumes = {
    [price: number]: number /* volume */;
};

export const volumeToColor = (volume: number, maxVolume: number, color: "red" | "green" | "blue" = "green") => {
    const intensity = Math.min(255, Math.floor((volume / maxVolume) * 255));
    // log(intensity, volume, maxVolume)
    switch (color) {
        case "red":
            return `ff0000${intensity}`; // Red color with varying intensity
        case "green":
            return `00ff00${intensity}`; // green color with varying intensity
        case "blue":
            return `0000ff${intensity}`; // Red color with varying intensity
    }
};

export function transformPriceVolumesToSeries(priceVolumes: PriceVolumes) {
    return Object.entries(priceVolumes).map(([price, volume]) => ({
        time: price,
        value: volume,
    }));
}

export type PairDepth = {
    asks: PriceVolumes
    bids: PriceVolumes

    sumVolumeBid: number
    sumVolumeAsk: number

    exchanges?: Exchange[]
}

type Top = {
    first: PriceVolume
    // second: PriceVolume
    // third: PriceVolume
}

function updateTop(top: Top, value: PriceVolume) {
    if (value.volume > top.first.volume) {
        // top.third = top.second;
        // top.second = top.first;
        top.first = value;
    }
    // else if (value.volume > top.second.volume) {
    //     top.third = top.second;
    //     top.second = value;
    // } else if (value.volume > top.third.volume) {
    //     top.third = value;
    // }
}

class HeatService extends Service {
    exchange: Exchange;
    maxDepthCount = 100;

    /** giới hạn giá, là khi cập nhật dữ liệu giá depth,
     * đặt giới hạn để khi cập nhật giá khối lượng trong hàm `getPair`,
     * loại bỏ những giá nằm ngoài hoặc trong min, max
     **/
    limitDepthPrices = {
        min: -Infinity, // number
        max: Infinity  // number
    }

    private pairs: { [symbol: string]: PairDepth } = {}

    start(exchange: ExchangeName.Binance) {
        switch (exchange) {
            case ExchangeName.Binance:
                this.exchange = new BinanceFuture()
                break;

            default:
                this.exchange = new BinanceFuture()
                break;
        }
    }

    /**
     * Theo dõi dữ liệu depth mua bán  1 cặp tiền nào đó, nếu chưa có thì khởi tạo
     * @param symbol cặp tiền cần theo dõi
     * @param unsubscribeOthers tự động hủy theo dõi những cặp tiền khác ko 
     */
    getPair(symbol: string, unsubscribeOthers = true): Promise<PairDepth> {
        if (!symbol || symbol.length < 1)
            return;
        return new Promise<PairDepth>((resolve) => {

            symbol = symbol.toLowerCase();

            const wait = (timeout: number) => {
                setTimeout(async () => {
                    if (!this.exchange?.subscribe)
                        wait(timeout)
                    else {
                        // hủy những cái khác
                        if (unsubscribeOthers)
                            this.unsubscribeOthers(symbol);

                        // nếu pair này chưa có thì tạo
                        if (!this.pairs[symbol]) {
                            let stream = await this.exchange.subscribe(symbol)
                            // kiểm tra lại nếu pair này chưa có thì tạo
                            if (!this.pairs[symbol]) {
                                this.pairs[symbol] = {
                                    asks: {}, bids: {},
                                    sumVolumeBid: 0, sumVolumeAsk: 0,
                                    exchanges: [stream]
                                }
                                stream.on(undefined, (r: { a: [price: string, volume: string][], b: [price: string, volume: string][] }) => {
                                    if (this.pairs[symbol]) {

                                        /**
                                         * b: bid bán
                                         * a: ask mua
                                         * lấy b - a
                                         * duyệt các phần tử trong b, tìm trong a, nếu = giá, 
                                         *  nếu b > a, lấy b - a đưa vào b
                                         *  nếu a > b, lấy a - b đưa vào a
                                         * chỉ lấy 3 giá có khối lượng cao nhất
                                         */
                                        const asks: PriceVolumes = this.pairs[symbol].asks ?? {},
                                            bids: PriceVolumes = this.pairs[symbol].bids ?? {};

                                        if (Object.values(asks).length + Object.values(bids).length > this.maxDepthCount) {
                                            this.pairs[symbol].asks = {};
                                            this.pairs[symbol].bids = {};
                                            this.pairs[symbol].sumVolumeAsk = 0;
                                            this.pairs[symbol].sumVolumeBid = 0;
                                            return;
                                        }

                                        // r.b.forEach((bp: [price: string, volume: string]) => {
                                        //     const [bid_price, bid_volume] = [Number(bp[0]), Number(bp[1])];
                                        //     bids[bid_price] = bid_volume

                                        //     // So sánh ask với bid
                                        //     r.a.findIndex((ap: [price: string, volume: string]) => {
                                        //         const [ask_price, ask_volume] = [Number(ap[0]), Number(ap[1])];
                                        //         asks[ask_price] = ask_volume

                                        //         // nếu b > a, lấy b - a đưa vào b
                                        //         if (bid_price === ask_price) {
                                        //             if (bid_volume > ask_volume) {
                                        //                 bids[bid_price] = bid_volume - ask_volume
                                        //             }
                                        //             // nếu a > b, lấy a - b đưa vào a
                                        //             else if (bid_volume < ask_volume) {
                                        //                 asks[ask_price] = ask_volume - bid_volume
                                        //             }
                                        //             else {
                                        //                 delete asks[ask_price]
                                        //                 delete bids[bid_price]
                                        //             }

                                        //             return true
                                        //         }
                                        //     })
                                        // });

                                        let topAsks: Top = {
                                            first: {
                                                price: 0,
                                                volume: -Infinity
                                            },
                                            // second: {
                                            //     price: 0,
                                            //     volume: -Infinity
                                            // },
                                            // third: {
                                            //     price: 0,
                                            //     volume: -Infinity
                                            // },
                                        }
                                        let topBids: Top = {
                                            first: {
                                                price: 0,
                                                volume: -Infinity
                                            },
                                            // second: {
                                            //     price: 0,
                                            //     volume: -Infinity
                                            // },
                                            // third: {
                                            //     price: 0,
                                            //     volume: -Infinity
                                            // },
                                        }

                                        r.b.forEach((bp: [price: string, volume: string]) => {
                                            const [bid_price, bid_volume] = [Number(bp[0]), Number(bp[1])];

                                            // So sánh ask với bid
                                            r.a.findIndex((ap: [price: string, volume: string]) => {
                                                const [ask_price, ask_volume] = [Number(ap[0]), Number(ap[1])];

                                                // nếu b > a, lấy b - a đưa vào b
                                                if (bid_price === ask_price) {
                                                    if (bid_volume > ask_volume) {
                                                        updateTop(topBids, {
                                                            price: bid_price,
                                                            volume: bid_volume - ask_volume
                                                        })
                                                    }
                                                    // nếu a > b, lấy a - b đưa vào a
                                                    else if (bid_volume < ask_volume) {
                                                        updateTop(topAsks, {
                                                            price: ask_price,
                                                            volume: ask_volume - bid_volume
                                                        })
                                                    }

                                                    return true
                                                } else {
                                                    updateTop(topBids, {
                                                        price: bid_price,
                                                        volume: bid_volume
                                                    })
                                                    updateTop(topAsks, {
                                                        price: ask_price,
                                                        volume: ask_volume
                                                    })
                                                }
                                            })
                                        });

                                        Object.values(topAsks).forEach((pv) => {
                                            if (pv.price <= (this.limitDepthPrices.max ?? Infinity)) {
                                                asks[pv.price] = pv.volume;
                                                this.pairs[symbol].sumVolumeAsk += pv.volume
                                            }
                                        })
                                        Object.values(topBids).forEach((pv) => {
                                            if (pv.price >= this.limitDepthPrices.min ?? Infinity) {
                                                bids[pv.price] = pv.volume;
                                                this.pairs[symbol].sumVolumeBid += pv.volume
                                            }
                                        })

                                    }
                                })
                            }
                        }
                        resolve(this.pairs[symbol])
                    }
                }, timeout);
            };
            wait(1000)
        })
    }

    /**
     * Hủy theo dõi những cặp tiền khác cặp này
     */
    unsubscribeOthers(symbol: string) {
        if (!symbol || symbol.length < 1)
            return;
        symbol = symbol.toLowerCase();

        for (const [_symbol, pairDepth] of Object.entries(this.pairs)) {
            if (_symbol !== symbol && pairDepth.exchanges) {
                for (const exchange of pairDepth.exchanges) {
                    exchange?.off(undefined, () => { })
                    exchange?.unsubscribe(symbol)
                    exchange?.disconnect()
                }
                delete this.pairs[_symbol]
            }
        }
    }
}

export default HeatService;