/**
 * https://binance-docs.github.io/apidocs/futures/en/#live-subscribing-unsubscribing-to-streams
**/

import axios from 'axios';
import Exchange, { PairInfo, ExchangeName } from './Exchanges';
import { FIATS, StableCoins } from './AltcoinSeason';


const { log, error, } = console
export enum SymbolType {
    FUTURE = 'FUTURE',
}

export enum ContractType {
    PERPETUAL = 'PERPETUAL',
    CURRENT_MONTH = 'CURRENT_MONTH',
    NEXT_MONTH = 'NEXT_MONTH',
    CURRENT_QUARTER = 'CURRENT_QUARTER',
    NEXT_QUARTER = 'NEXT_QUARTER',
    PERPETUAL_DELIVERING = 'PERPETUAL_DELIVERING',
}

export enum ContractStatus {
    PENDING_TRADING = 'PENDING_TRADING',
    TRADING = 'TRADING',
    PRE_DELIVERING = 'PRE_DELIVERING',
    DELIVERING = 'DELIVERING',
    DELIVERED = 'DELIVERED',
    PRE_SETTLE = 'PRE_SETTLE',
    SETTLING = 'SETTLING',
    CLOSE = 'CLOSE',
}

export enum OrderStatus {
    NEW = 'NEW',
    PARTIALLY_FILLED = 'PARTIALLY_FILLED',
    FILLED = 'FILLED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED',
    EXPIRED_IN_MATCH = 'EXPIRED_IN_MATCH',
}

export enum OrderType {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET',
    STOP = 'STOP',
    STOP_MARKET = 'STOP_MARKET',
    TAKE_PROFIT = 'TAKE_PROFIT',
    TAKE_PROFIT_MARKET = 'TAKE_PROFIT_MARKET',
    TRAILING_STOP_MARKET = 'TRAILING_STOP_MARKET',
}

export enum OrderSide {
    BUY = 'BUY',
    SELL = 'SELL',
}

export enum PositionSide {
    BOTH = 'BOTH',
    LONG = 'LONG',
    SHORT = 'SHORT',
}

export enum TimeInForce {
    GTC = 'GTC', // Good Till Cancel (GTC order validity is 1 year from placement)
    IOC = 'IOC', // Immediate or Cancel
    FOK = 'FOK', // Fill or Kill
    GTX = 'GTX', // Good Till Crossing (Post Only)
    GTD = 'GTD', // Good Till Date
}

export enum WorkingType {
    MARK_PRICE = 'MARK_PRICE',
    CONTRACT_PRICE = 'CONTRACT_PRICE',
}

export enum ResponseType {
    ACK = 'ACK',
    RESULT = 'RESULT',
}

export enum KlineIntervals {
    MIN_1 = '1m',
    MIN_3 = '3m',
    MIN_5 = '5m',
    MIN_15 = '15m',
    MIN_30 = '30m',
    HOUR_1 = '1h',
    HOUR_2 = '2h',
    HOUR_4 = '4h',
    HOUR_6 = '6h',
    HOUR_8 = '8h',
    HOUR_12 = '12h',
    DAY_1 = '1d',
    DAY_3 = '3d',
    WEEK_1 = '1w',
    MONTH_1 = '1M',
}

export enum StpMode {
    NONE = 'NONE',
    EXPIRE_TAKER = 'EXPIRE_TAKER',
    EXPIRE_BOTH = 'EXPIRE_BOTH',
    EXPIRE_MAKER = 'EXPIRE_MAKER',
}

export enum PriceMatch {
    NONE = 'NONE', // No price match
    OPPONENT = 'OPPONENT', // Counterparty best price
    OPPONENT_5 = 'OPPONENT_5', // The 5th best price from the counterparty
    OPPONENT_10 = 'OPPONENT_10', // The 10th best price from the counterparty
    OPPONENT_20 = 'OPPONENT_20', // The 20th best price from the counterparty
    QUEUE = 'QUEUE', // The best price on the same side of the order book
    QUEUE_5 = 'QUEUE_5', // The 5th best price on the same side of the order book
    QUEUE_10 = 'QUEUE_10', // The 10th best price on the same side of the order book
    QUEUE_20 = 'QUEUE_20', // The 20th best price on the same side of the order book
}


export interface MarkPrice {
    "e": string,    // "markPriceUpdate",     // Event type
    "E": number,    // 1562305380000,         // Event time
    "s": string,    // "BTCUSDT",             // Symbol
    "p": string,    // "11794.15000000",      // Mark price
    "i": string,    // "11784.62659091",      // Index price
    "P": string,    // "11784.25641265",      // Estimated Settle Price, only useful in the last hour before the settlement starts
    "r": string,    // "0.00038167",          // Funding rate
    "T": number,    // 1562306400000          // Next funding time
}

class BinanceFuture extends Exchange {
    name = ExchangeName.BinanceFuture

    ws: WebSocket

    listenKey: string
    apiKey: string
    secretKey: string
    url: string


    streams: {
        [pair: string]: BinanceFuture
    } = {}

    constructor(url = "wss://fstream.binance.com/") {
        super()

        // POST https://fapi.binance.com/fapi/v1/listenKey to recreate listenKey and use new listenKey to build connection
        // https://dev.binance.vision/t/how-to-get-listenkey-in-websocket/9679/3


        this.url = url
    }

    /**
     * Mở kết nối tới server 
     */
    async connect(apiKey?: string, secretKey?: string,) {
        if (apiKey && secretKey) {
            this.apiKey = apiKey
            this.secretKey = secretKey

            const listenKey = await BinanceFuture.ListenKey(this.apiKey);
            log(listenKey);

            this.listenKey = listenKey;
            this.ws = new WebSocket(this.url + "ws/" + listenKey);
            log("Start connect", this.url + "ws/" + listenKey);
        } else {
            this.ws = new WebSocket(this.url + "ws");
            log("Start connect", this.url);
        }



        this.ws.onerror = (err: any) => {
            this.isConnected = false;
            if (err)
                error(err);
        };
        // mở websocket server
        this.ws.onopen = (e: any) => {
            this.isConnected = true;
            let loop = setInterval(() => {
                if (this.ws.readyState == 1) {
                    this.emit("connected", e, this);
                    clearInterval(loop);
                }
            }, 50);
        };
        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        this.ws.onmessage = (msg: any) => {
            let data = JSON.parse(msg.data);
            // log(data);
            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data);
            }
            this.emit(data.id, data);
        };
        // Khi mất kết nối với Binance thì tắt chương trình
        this.ws.onclose = (r: any) => {
            this.isConnected = false;
            log(r);
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài");
            }
            error("WebSocket.onclosed", r);
            this.emit("closed", r);
        };

    }

    reconnect() {
        this.disconnect()
        this.ws = new WebSocket(this.url)

        this.ws.onerror = (err: any) => {
            if (err) {
                error(err);
                this.emit("error", err)
            }
        };

        // mở websocket server
        this.ws.onopen = (e: any) => {
            let loop = setInterval(() => {
                if (this.ws.readyState === 1) {
                    this.emit("connected", e)
                    clearInterval(loop)
                    // let ping = setInterval(() => {
                    //     this.ws.send(JSON.stringify({
                    //         method: 'PING',
                    //         id: 'PING' + Date.now(),
                    //     }))
                    //     if (this.ws.readyState === WebSocket.CLOSED)
                    //         clearInterval(ping)
                    // }, 3000)
                }
            }, 50);
        }

        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        this.ws.onmessage = (msg: any) => {
            let data = JSON.parse(msg.data)
            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data)
            }
            this.emit(data.id, data)
        }

        // Khi mất kết nối với Binance thì tắt chương trình
        this.ws.onclose = (r: any) => {
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài")
            }
            // error("WebSocket.onclosed", r);
            // this.emit("closed", r)
        }

        return this.ws;
    }

    static stream(stream: string, url = "wss://fstream.binance.com/"): BinanceFuture {
        let ex = new BinanceFuture()
        ex.url = url + "ws/" + stream
        ex.ws = new WebSocket(ex.url)

        ex.ws.onerror = (err: any) => {
            error(err);
            ex.emit("error", err);
        };

        // mở websocket server
        ex.ws.onopen = (e: any) => {
            let loop = setInterval(() => {
                if (ex.ws.readyState === 1) {
                    ex.emit("connected", e, ex)
                    clearInterval(loop)
                    // let ping = setInterval(() => {
                    //     this.ws.send(JSON.stringify({
                    //         method: 'PING',
                    //         id: 'PING' + Date.now(),
                    //     }))
                    //     if (this.ws.readyState === WebSocket.CLOSED)
                    //         clearInterval(ping)
                    // }, 3000)
                }
            }, 50);
        }

        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        ex.ws.onmessage = (msg: any) => {
            let data = JSON.parse(msg.data)

            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data)
            }
            ex.emit(data.id, data)
        }

        // Khi mất kết nối với Binance thì tắt chương trình
        ex.ws.onclose = (r: any) => {
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài")
            }
            error("WebSocket.onclosed", r);
            ex.emit("closed", r)
        }

        return ex;
    }

    /**
     * nhận thông tin khi có lệnh market khớp lệnh xong với cặp tiền
     * @param symbol tên cặp tiền
     * @param url đường tới máy chủ websocket
     */
    static forceOrder(symbol: string, url = "wss://fstream.binance.com/"): BinanceFuture {
        if (!symbol || symbol === "*")
            return BinanceFuture.stream("!forceOrder@arr")

        return BinanceFuture.stream(symbol.toLocaleLowerCase() + "@forceOrder")
    }


    static ListenKey(apiKey: string, url = "https://api.binance.com/api/v3/userDataStream") {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url,
            headers: {
                'X-MBX-APIKEY': apiKey
            }
        };
        return axios.request(config).then(r => r.data.listenKey)
    }

    Send(object: any) {
        let loop = setInterval(() => {
            if (this.ws?.readyState == 1) {
                this.ws.send(JSON.stringify(object))
                clearInterval(loop)
            }
        }, 50);
    }

    disconnect() {
        try {
            this.ws.close()
            this.ws.onopen = this.ws.onmessage = this.ws.onclose = this.ws.onerror = null;
        } catch (err) { }
    }

    /**
     * https://binance-docs.github.io/apidocs/futures/en/#all-market-liquidation-order-streams
     * đăng kí theo dõi depth hoặc aggTrade
     * @param symbol tên cặp tiền
     * @param method "depth" | "aggTrade"
     */
    subscribe(symbol: string, method = "depth", speed = 500): Promise<BinanceFuture> {
        if (!symbol || symbol.length < 1)
            return;
        symbol = symbol.toLowerCase()
        return new Promise(async (rs) => {

            let streamName = `${symbol}@${method}@${speed}ms`;

            // nếu có rồi thì tắt kết nối lại
            if (this.streams[streamName]) {
                this.streams[streamName]?.unsubscribe(symbol, method)
                this.streams[streamName]?.disconnect()
                this.streams[streamName] = undefined
            }
            let stream = BinanceFuture.stream(streamName, this.url)

            stream.once(undefined, () => {
                this.streams[streamName] = stream
                rs(stream)
            })
        })
    }

    unsubscribe(symbol: string, method = "depth", speed = 500) {
        if (!symbol || symbol.length < 1)
            return;
        symbol = symbol.toLowerCase()
        let id = symbol.toLowerCase() + Date.now().toString().slice(-4)
        let streamName = `${symbol}@${method}@${speed}ms`

        this.streams[streamName]?.Send({
            "method": "UNSUBSCRIBE",
            "params": [
                streamName,
            ],
            "id": id
        })
        this.Send({
            "method": "UNSUBSCRIBE",
            "params": [
                streamName,
            ],
            "id": id
        })
        this.off(undefined, () => { })
    }

    async getLIST_SUBSCRIPTIONS() {
        let id = "LISTSUBSCRIPTIONS" + Date.now().toString().slice(-4)
        return new Promise((rs, rj) => {
            this.on(id, (r) => {
                rs(r.result)
            })

            this.Send({
                "method": "LIST_SUBSCRIPTIONS",
                "id": id
            })
        })
    }

    static async getServerTime(): Promise<number> {
        const r = await axios.get("https://api.binance.com/api/v3/time");
        return r.data.serverTime;
    }
    /**
      * lấy thông tin toàn bộ trên sàn trong 24h
      */
    async getAll(futureOnly = false) {
        const url = futureOnly ? "https://fapi.binance.com/fapi/v1/ticker/24hr" : `https://api.binance.com/api/v3/ticker/24hr`
        return axios.get(url, { responseType: 'json', })
            .then(response => response.data)
    }

    /**
     * lấy trên future các cặp tiền 
     * @param {string[]} quoteAssets /USDT /BUSD
     * @returns 
     */
    async getFutureAllSymbolsWiths(quoteAssets: string[] = ["USDT"]): Promise<PairInfo[]> {
        // lấy tất cả các đồng spot để lọc những đồng có future nhưng ko có spot 
        let all = (await this.getAll()).filter(s => !s.quoteVolume.startsWith("0"))

        let symbols = all.map((k) => k.symbol).filter(s => quoteAssets.some(v => s.endsWith(v) &&
            !s.match(/1000|BEAR|BULL|UP|DOWN/) &&
            !s.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`)
        ))

        const r = await axios.get('https://fapi.binance.com/fapi/v1/ticker/24hr');
        const pairs = r.data.filter((pair) =>
            quoteAssets.some(v => pair.symbol.endsWith(v) &&
                !pair.symbol.match(/1000|BEAR|BULL|UP|DOWN/) &&
                !pair.symbol.match(`^(${[...FIATS, ...StableCoins].join("|")})(.+)$`) &&
                symbols.includes(pair.symbol)
            ));
        pairs.forEach(p => {
            quoteAssets.findIndex(q => {
                if (p.symbol.endsWith(q)) {
                    p.quoteAsset = q;
                    p.baseAsset = p.symbol.replace(q, "");
                    return true;
                }
            })
        })
        // sắp xếp theo giảm dần quoteVolume
        return pairs.sort((b, a) => a.quoteVolume - b.quoteVolume) //symbols.filter((v: string) => !v.match(/1000/))
    }

    streamPrice(symbol: string, callback?: (trade: MarkPrice) => void): WebSocket {
        const wsUrl = `wss://fstream.binance.com/ws/${symbol.trim().toLowerCase()}@markPrice`;
        // Tạo kết nối WebSocket
        const ws = new WebSocket(wsUrl);

        ws.onopen = () => {
            log(`streamPrice ${symbol} WebSocket connection opened`);
        };
        ws.onmessage = (msg) => {
            const trade = JSON.parse(msg.data);
            if (callback) {
                callback(trade)
            }
        };

        ws.onclose = () => {
            log(`streamPrice ${symbol} WebSocket connection closed`);
        };

        ws.onerror = (err) => {
            error(`streamPrice ${symbol} WebSocket error:`, err);
        };
        return ws
    }
}

export default BinanceFuture;