import React, { useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";
import { LineChartOutlined, ClearOutlined, ReloadOutlined, } from '@ant-design/icons';
import { Badge, Button, Flex, Modal, Row, Select, Space, Switch, Table, Tag, Tooltip, message, } from 'antd';
import millify from "millify";
import i18n from '../services/i18n';


import { startService } from '../store/services';
import AltcoinSeasonPointsChart from '../components/AltcoinSeasonPointsChart';
import { TIMEFRAMES, PairInfo, } from '../utils/Exchanges';
import BtnCopy from '../components/BtnCopy';
import TimeframesSelect from '../components/TimeframesSelect';
import { default_listenTimeframes } from '../services/AltcoinSeasonService';

import "./Signals.scss"
import { ColumnsType, } from 'antd/es/table/interface';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { OrdersBook } from '.';

const { log, error, warn } = console


const Pair: React.FC = () => {
    const dispatch = useStoreDispatch();
    const { t } = i18n
    const settings = useStore((state) => state.settings);
    const params = useParams()

    const services = useStore((state) => state.services);
    const mounted = useRef(false);

    const [states, setStates] = useState<any>({
        isChangeColumns: true,
        timeframes: {},
        timeframesMA: {},
        valueTypes: {
            changed: 'changed',
            volume: 'volume',
            MA: 'MA'
        }
    })

    const [symbol, setsymbol] = useState<string>()
    const [allSymbols, setallSymbols] = useState<any[]>([])

    const [count, setcount] = useState<number>(0)
    const [isConnected, setisConnected] = useState(false)
    const [pairStatus, setpairStatus] = useState("")

    const [dataSource, setdataSource] = useState<any[]>([])
    const [columns, setcolumns] = useState([])
    const [futureOnly, setfutureOnly] = useState(true)

    let [listenTimeframes, setlistenTimeframes] = useState(default_listenTimeframes)

    let [subscribePairs, setsubscribePairs] = useState(dayjs(Date.now()).format("DD/MM/YY H:m:s"))


    // do componentDidMount logic
    useEffect(() => {
        if (!mounted.current) {

            services?.altcoinSeason?.getListenTimeframes().then(tfs => setlistenTimeframes(tfs))

            let symbol = params.pair.replace("-", "").replace("/", "").toUpperCase()

            setsymbol(symbol)

            services?.altcoinSeason
                .on("init", ({ symbol, pairs, klines, timeframe, countSymbolsKlines, allSymbols }) => {
                    setpairStatus(symbol + " " + countSymbolsKlines + "/" + services?.altcoinSeason.amountPairs)
                    showPairs(symbol)
                    setallSymbols(allSymbols)
                    setTimeout(() => {
                        setpairStatus("")
                    }, 5000);
                }).on("newCandles", ({ symbol, info, index, length }) => {
                    setpairStatus("")
                    setpair(info)
                    setsubscribePairs(dayjs(Date.now()).format("DD/MM/YY H:m:s"))
                    setTimeout(() => {
                        showPairs(symbol)
                    }, 500);
                }).on("newTimeframe", (info: PairInfo) => {
                    setpair(info)
                    showPairs(symbol)
                }).on("allSymbols", ({ allSymbols }) => {
                    setallSymbols(allSymbols)
                })

        }
        mounted.current = true;
    }, []);

    const [exchangeButtons, setexchangeButtons] = useState<JSX.Element[]>([])
    useEffect(() => {
        if (symbol === params.pair.replace("-", "").replace("/", "").toUpperCase())
            startAltcoinSeason();
    }, [symbol])

    useEffect(() => {
        setisConnected(services?.altcoinSeason?.exchange?.isConnected)
    }, [services?.altcoinSeason?.exchange?.isConnected])

    const startAltcoinSeason = () => {
        dispatch(startService({ altcoinSeason: { watch: symbol } }))
    }

    const [pair, setpair] = useState<PairInfo>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (symbol: string) => {
        let pair: PairInfo = services?.altcoinSeason?.Pairs[symbol]
        setpair(pair)
        setsubscribePairs(dayjs(Date.now()).format("DD/MM/YY H:m:s"))
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (pair && allSymbols && allSymbols.length > 0) {
            const _pair = allSymbols.find(p => p.symbol === pair.symbol)
            let linkBinance = futureOnly ? `https://www.binance.com/futures/${pair.symbol}` : `https://www.binance.com/trade/${pair.symbol}?type=spot`
            let linkBybit = futureOnly ? `https://www.bybit.com/trade/usdt/${pair.symbol}` : `https://www.bybit.com/trade/spot/${pair.symbol}`

            if (_pair) {
                linkBinance = futureOnly ? `https://www.binance.com/futures/${_pair.baseAsset}${_pair.quoteAsset}` : `https://www.binance.com/trade/${_pair.baseAsset}_${_pair.quoteAsset}?type=spot`
                linkBybit = futureOnly ? `https://www.bybit.com/trade/usdt/${_pair.baseAsset}${_pair.quoteAsset}` : `https://www.bybit.com/trade/spot/${_pair.baseAsset}/${_pair.quoteAsset}`
            }

            let exchangeButtons = [
                <Button type='link' href={linkBinance} target="_blank" icon={<img src='/images/binance.png' height={23} />}></Button>,
                <Button type='link' href={linkBybit} target="_blank" icon={<img src='/images/bybit.svg' height={10} />} style={{ border: "1px solid #424242", backgroundColor: "#4a8209" }}></Button>
            ]
            setexchangeButtons(exchangeButtons);
        }
    }, [pair, futureOnly, allSymbols])

    useEffect(() => {
        if (pair?.points && Object.values(pair?.points).length > 0) {
            showPairs(symbol)
        }
    }, [pair?.points])

    // setData
    const showPairs = async (symbol: string) => {
        let pairs: {
            [symbol: string]: {
                point?: number
                points?: {
                    [timeframe: string]: {
                        volume: number,
                        changed: number,

                        // khoảng cách đường MA với giá đóng cửa
                        MA?: number,
                    }
                },
                quoteVolume?: number,
                baseAsset?: string,
                quoteAsset?: string,
            }
        } = services?.altcoinSeason?.currentPoints

        if (pairs) {
            let pair: PairInfo = services?.altcoinSeason?.Pairs[symbol]
            setpair(pair)

            let timeframes = {}
            let timeframesMA = {}

            let dataSource = Object.entries(pairs).map(([symbol, info], i) => {

                let { points, quoteVolume } = info
                const allPoints = {
                    changed: [],
                    volume: [],
                    MA: []
                }

                let data_points = Object.entries(points).reduce((s, v) => {
                    let [timeframe, value] = v
                    timeframes[timeframe] = timeframe
                    s[`points.${timeframe}.changed`] = value.changed
                    if (!isNaN(value.changed)) {
                        allPoints.changed.push(value.changed)
                    }

                    s[`points.${timeframe}.volume`] = value.volume
                    if (!isNaN(value.volume)) {
                        allPoints.volume.push(value.volume)
                    }

                    if (!isNaN(value.MA)) {
                        s[`points.${timeframe}.MA`] = value.MA
                        allPoints.MA.push(value.MA)
                        timeframesMA[timeframe] = timeframe
                    }
                    return s
                }, {})

                const avgs = Object.entries(allPoints).reduce((
                    s: { [avg: `avgs.${string}`]: number },
                    v: [name: `points.${string}.${string}`, points: number[]]
                ) => {

                    const [name, points] = v;
                    if (points.length) {
                        const parts = name.split('.');
                        const valueType = parts[parts.length - 1].trim();
                        s[`avgs.${valueType}`] = points.reduce((sum: number, point: number) => sum + point, 0) / points.length;
                        states.valueTypes[valueType] = valueType;
                    }
                    return s;
                }, {})

                return {
                    "#": i + 1,
                    "symbol": symbol,

                    "view": {
                        symbol,
                        baseAsset: info.baseAsset,
                        quoteAsset: info.quoteAsset
                    },

                    ...avgs,

                    ...data_points,

                    "quoteVolume": quoteVolume
                }
            })
            setStates({ ...states, timeframes, timeframesMA })
            setdataSource(dataSource)
        }
    }

    useEffect(() => {
        if (dataSource && dataSource.length > 0) {

            const sorter = (key, a, b) => a[key] - b[key];
            const sorterString = (key, a, b) => {
                return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : (a[key].toUpperCase() > b[key].toUpperCase() ? 1 : 0)
            };

            function renderPointCell(value, record) {
                let color = isNaN(value) ? "" : value > settings?.altcoinSeason?.max ? "red" : value < settings?.altcoinSeason?.min ? "green" : "orange"
                return {
                    children: <Tag color={color}>{isNaN(value) ? value : value.toFixed()}</Tag>
                };
            }
            function renderMACell(value, record) {
                let color = isNaN(value) ? "" : value < -5 ? "green" : value > 5 ? "red" : "orange"
                return {
                    children: <Tag color={color}>{isNaN(value) ? value : value.toFixed()}</Tag>
                };
            }

            let points_columns = Object.values(states.timeframes).map((timeframe: string) => {
                let children = [
                    {
                        title: <Tooltip title={t("volume")} color='blue'><div>volume</div></Tooltip>,
                        dataIndex: `points.${timeframe}.volume`,
                        key: `points.${timeframe}.volume`,
                        sorter: (a, b) => sorter(`points.${timeframe}.volume`, a, b),
                        render: renderPointCell
                    }, {
                        title: <Tooltip title={t("level of price change")} color='blue'><div>changed</div></Tooltip>,
                        dataIndex: `points.${timeframe}.changed`,
                        key: `points.${timeframe}.changed`,
                        sorter: (a, b) => sorter(`points.${timeframe}.changed`, a, b),
                        render: renderPointCell
                    },
                ]

                if (states.timeframesMA[timeframe]) {
                    children.push({
                        title: <Tooltip title={t("% distance MA with close price")} color='blue'><div>SMA %</div></Tooltip>,
                        dataIndex: `points.${timeframe}.MA`,
                        key: `points.${timeframe}.MA`,
                        sorter: (a, b) => sorter(`points.${timeframe}.MA`, a, b),
                        render: renderMACell
                    })
                }

                return {
                    title: <label style={{ color: TIMEFRAMES.toColor(timeframe) }}>{timeframe}</label>,
                    children,
                }
            })

            let avg_points_columns = Object.keys(states.valueTypes).map((valueType: string) => {
                return {
                    title: <Tooltip title={t(valueType)} color='blue'><div>{valueType}</div></Tooltip>,
                    dataIndex: `avgs.${valueType}`,
                    key: `avgs.${valueType}`,
                    sorter: (a, b) => sorter(`avgs.${valueType}`, a, b),
                    render: valueType === 'MA' ? renderMACell : renderPointCell
                }
            });


            let columns: ColumnsType = [
                {
                    title: "#",
                    dataIndex: "#",
                    key: "#",
                },
                {
                    title: <Tooltip title={t("symbol")} color='blue'><div>{t("symbol")}</div></Tooltip>,
                    dataIndex: "symbol",
                    key: "symbol",
                    render: (value, record) => {
                        return {
                            children: <>{value} <BtnCopy value={value} /></>
                        };
                    },
                },
                {
                    title: t("view"),
                    dataIndex: "view",
                    key: "view",
                    render: (value, record) => {
                        let linkBinance = services?.altcoinSeason?.futureOnly ? `https://www.binance.com/futures/${value.baseAsset}${value.quoteAsset}` : `https://www.binance.com/trade/${value.baseAsset}_${value.quoteAsset}?type=spot`
                        let linkBybit = services?.altcoinSeason?.futureOnly ? `https://www.bybit.com/trade/usdt/${value.baseAsset}${value.quoteAsset}` : `https://www.bybit.com/trade/spot/${value.baseAsset}/${value.quoteAsset}`

                        return {
                            children:
                                <Flex style={{ alignItems: "center" }}>
                                    <Button type='link' href={linkBinance} target="_blank" icon={<img src='/images/binance.png' height={23} />}></Button>
                                    <Button type='link' href={linkBybit} target="_blank" icon={<img src='/images/bybit.svg' height={10} />} style={{ border: "1px solid #424242", backgroundColor: "#4a8209" }}></Button>
                                    <Button type='link' href={`/pairs/${value.baseAsset}-${value.quoteAsset}`} target="_blank" icon={<LineChartOutlined style={{ fontSize: 30 }} />} style={{ border: "1px solid #424242" }}></Button>
                                    <Button onClick={() => showModal(value.symbol)} icon={<LineChartOutlined />} />
                                </Flex>
                        }
                    }
                },
                {
                    title: <Tooltip title={t("Average points")} color='blue'><div>{t("Averages")}</div></Tooltip>,
                    children: avg_points_columns
                },
                {
                    title: <Tooltip title={t("points")} color='blue'><div>{t("points")}</div></Tooltip>,
                    children: points_columns
                },
                {
                    title: <Tooltip title={t("quoteVolume")} color='blue'><div>quote volume $</div></Tooltip>,
                    dataIndex: "quoteVolume",
                    key: "quoteVolume",
                    render: (value, record) => ({
                        children: <>{millify(value)}</>
                    })
                },
            ]

            setcolumns(columns)
        }
    }, [dataSource, services?.altcoinSeason?.futureOnly])

    const onTimeframesChanged = (timeframe: string) => {
        let index = listenTimeframes.findIndex(v => v === timeframe)
        if (index < 0)
            listenTimeframes.push(timeframe)
        else
            listenTimeframes.splice(index, 1)
        listenTimeframes.sort((a, b) => TIMEFRAMES.toMiliSecond(a) - TIMEFRAMES.toMiliSecond(b))
        setlistenTimeframes(listenTimeframes)
        setcount(Date.now())

        if (index < 0 && services?.altcoinSeason?.exchange)
            services?.altcoinSeason?.addTimeframe(timeframe)
        else
            services?.altcoinSeason?.setListenTimeframes(listenTimeframes)
    }

    const onSelectSymbol = (s: string) => {
        window.location.href = s
    }

    const cleanData = () => {
        services?.altcoinSeason?.cleanData().then((r) => {
            message.success(t("Clean app success"))
            document.location.reload()
        });
    }

    return (<>
        <Badge.Ribbon text={t(isConnected ? "connected" : "closed")} color={isConnected ? "green" : "red"}>
            <Space wrap>
                <Select options=
                    {allSymbols.map((s, i) => ({ value: `${s.baseAsset}-${s.quoteAsset}`, label: `${s.baseAsset}-${s.quoteAsset}`, }))}
                    onChange={onSelectSymbol} value={symbol} showSearch
                />
                <Tooltip title={pairStatus} color={"orange"} open={pairStatus !== ""}>
                    <Button onClick={() => showPairs(symbol)} disabled={!isConnected}>{t("Show Chart")}</Button>
                </Tooltip>

                <Switch
                    checked={futureOnly}
                    onChange={() => setfutureOnly(!futureOnly)}
                    checkedChildren={t("Future")}
                    unCheckedChildren={t("Spot")}
                />

                <Button icon={<ClearOutlined />} onClick={cleanData}>{t("Clean data")}</Button>
                <Button icon={<ReloadOutlined />} onClick={() => document.location.reload()}>{t("Reload")}</Button>

            </Space>

            <TimeframesSelect timeframes={services?.altcoinSeason?.exchange?.Timeframes || {}} selected={listenTimeframes} onChange={onTimeframesChanged} />


        </Badge.Ribbon >

        <AltcoinSeasonPointsChart
            pair={pair}
            points={pair?.points}
            subscribe={subscribePairs}
            DataTypes={"PairPointTypes"}
            exchangeButtons={exchangeButtons}
            allSymbols={allSymbols}
        />
        <Row><br /><br /></Row>

        <Badge.Ribbon text={t("Altcoin Pairs") + ":"} placement='start'>
            <Row><br /><br /></Row>
        </Badge.Ribbon>

        <OrdersBook only={symbol} />

        <Badge.Ribbon text={subscribePairs} placement='start'>

            <Table dataSource={dataSource} columns={columns} scroll={{ x: 100 }} pagination={{ pageSize: 25 }} bordered />

        </Badge.Ribbon>

        <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={"100%"} style={{ padding: 0, height: "100%" }}>
            <AltcoinSeasonPointsChart pair={pair} points={pair?.points} subscribe={subscribePairs} DataTypes={"PairPointTypes"} />
        </Modal>
    </>);
};

export default Pair;
