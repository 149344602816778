import { useCallback, useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStore } from "../store/hooks";
import {
    LineChartOutlined, DeleteOutlined,
    FullscreenOutlined, ExpandAltOutlined, FullscreenExitOutlined,
    FundViewOutlined, BuildOutlined,
    EyeOutlined, EyeInvisibleOutlined,
} from '@ant-design/icons';
import {
    Avatar, Badge,
    Collapse, Tooltip, Flex, Segmented, Checkbox, Select, Button,
    Space, message, Menu, Switch, InputNumber,
    Row,
    Col,
    Drawer,
    Form
} from 'antd';
import i18n from '../services/i18n';

// import { IChartApi, ISeriesApi, createChart, LineStyle, LineType, CrosshairMode, } from 'lightweight-charts';

import { SettingsInitialType, change, } from '../store/settings';
import { AltcoinSeasonPoint, AltcoinSeasonPointTypes, PairPoint, PairPointTypes, PointLineStyle, SmoothType } from '../utils/AltcoinSeason';
import { CandlestickMap, PairInfo, TIMEFRAMES, TIMEZONES, TIMEZONESType, timeToTimezone } from '../utils/Exchanges';
import { getPrecision, toggleFullscreen } from '../utils/std';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import BtnCopy from './BtnCopy';

import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime"
import duration from 'dayjs/plugin/duration'

import { CandlestickData, IChartApi, ISeriesApi, SeriesMarker, Time, } from "lightweight-charts";
import "./AltcoinSeasonPointsChart.scss";
import { SMA } from 'technicalindicators';
import { PairDepth, PriceVolume, } from '../services/HeatService';
import { MarkPrice } from '@utils/BinanceFuture';
import PriceVolumeChart from './PriceVolumeChart';
import QueueAnim from 'rc-queue-anim';
import BigNumber from 'bignumber.js';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const { createChart, LineStyle, LineType, CrosshairMode } = window.LightweightCharts;
const { log, error, } = console;

const colors = {
    backgroundColor: '#171b26',
    lineColor: '#2962FF',
    textColor: '#ddd',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',
    up: '#00ff003b',
    down: '#ff00003b',
    red: '#dc4446',
    green: '#49aa19',
    blue: '#2962ff',
    orange: '#ff9800',
    gray: 'gray'
}


type Props = {
    points: {
        [timeframe: string]: AltcoinSeasonPoint[] | PairPoint[]
    }
    title?: string,
    pair?: PairInfo,

    DataTypes?: "AltcoinSeasonPointTypes" | "PairPointTypes",

    // Cái này để khi có dữ liệu trong points thay đổi mà chưa update được giao diện thì update thông qua biến này
    subscribe?: any
    exchangeButtons?: JSX.Element[]

    allSymbols?: any[]
}

type ResizeDirection = "corner" | "bottom"

const initValues: any = {
    chartHeight: "60em"
}



const AltcoinSeasonPointsChart = <PROPS extends Props>({ points, pair, subscribe, DataTypes, exchangeButtons, allSymbols }: PROPS) => {
    const [state, setstate] = useState<{
        chart: IChartApi
        candles: ISeriesApi<"Candlestick">
        lines: {
            [timeframe: string]: ISeriesApi<"Line">
        }
        rangeLines: ISeriesApi<"Line">

        liquidSeries: {
            [price: number]: ISeriesApi<"Line">
        },
        heatmap: {
            bids: ISeriesApi<"Candlestick">
            asks: ISeriesApi<"Candlestick">
        },
        precision: number, minMove: number,

        [name: string]: any
    }>({
        chart: undefined,
        // nến đồ thị giá
        candles: undefined,

        liquidSeries: {},
        isVisibleLiquidSeries: true,
        isVisibleCandles: true,
        heatmap: {
            bids: undefined,
            asks: undefined
        },

        volumeSeries: undefined,
        isShowDrawerPriceVolume: false,

        rangeLines: undefined,
        chartLabel: undefined,
        pair: undefined,
        lines: {},
        avgs: {},
        indicators: { SMAs: {} },
        precision: 8, minMove: 0.00000001
    })

    window.lines = state.lines

    const [firstTime, setfirstTime] = useState(true);
    const dispatch = useStoreDispatch();
    const { t } = i18n
    const chartContainerRef = useRef<any>()
    const titleRef = useRef<any>()
    const chartRef = useRef<any>()
    const toolsRef = useRef<any>()

    const settings = useStore<SettingsInitialType>((state) => state.settings);

    const services = useStore((state) => state.services);

    const [priceVolumeData, setpriceVolumeData] = useState<{
        price: number,
        orderType: 'ask' | 'bid',
        volume: number
    }[]>([])

    const [lineGreen, setlineGreen] = useState<number>(15)
    const [lineRed, setlineRed] = useState<number>(85)


    const [lineType, _] = useState(LineType.Simple)

    const [Timeframes, setTimeframes] = useState<{ label: string, value: string, visible: boolean }[]>(
        Object.values(TIMEFRAMES)
            .map(tf => tf)
            .filter(tf => typeof (tf) === "string" && TIMEFRAMES.toMiliSecond(tf) >= TIMEFRAMES.toMiliSecond("5m"))
            .map(tf => ({
                label: tf,
                value: tf,
                visible: true
            }))
    )

    const [checkedTimeframes, setcheckedTimeframes] = useState<string[]>(Timeframes.map(v => v.value))

    let [dataTypes, setdataTypes] = useState(
        DataTypes === "AltcoinSeasonPointTypes" ?
            [...settings?.altcoinSeason?.dataTypes]
            : [...settings?.altcoinSeasonPair?.dataTypes]
    )

    const mounted = useRef(false);

    const [timeframe_leftString, settimeframe_leftString] = useState("")
    const chartLabelRef = useRef<any>()

    const [symbol, setsymbol] = useState(pair?.symbol)
    const [currentPrice, setCurrentPrice] = useState(0)
    const [pairDepth, setpairDepth] = useState<PairDepth>()

    const [isConnected, setisConnected] = useState(false)

    // do componentDidMount logic
    useEffect(() => {
        if (!mounted.current) {
            switch (DataTypes) {
                case "PairPointTypes":
                    setlineGreen(settings?.altcoinSeasonPair?.min)
                    setlineRed(settings?.altcoinSeasonPair?.max)
                    setdataTypes([...settings?.altcoinSeasonPair?.dataTypes])
                    break;

                default:
                    setlineGreen(settings?.altcoinSeason?.min)
                    setlineRed(settings?.altcoinSeason?.max)
                    setdataTypes([...settings?.altcoinSeason?.dataTypes])
                    break;
            }

            // fullscreen Ctrl + Shift + F
            document.addEventListener("keydown", function (event) {
                if (event.ctrlKey && event.shiftKey && (event.key === "f" || event.key === "F")) {
                    fullscreen()
                }
            });

            // thời gian còn lại của timeframe nhỏ nhất hiển thị đồ thị giá
            setInterval(() => {
                settimeframe_leftString(services?.altcoinSeason?.exchange?.Timeframes?.[state.pair?.minTimeframe]?.leftString || "")
            }, 1000);

            // // khi có dữ liệu depth cập nhật thì đưa lên đồ thị
            // services?.heat?.on("depth", (symbol: string, pairDepth: PairDepth) => {
            //     setisConnected(true)
            //     debounce(() => {
            //         setisConnected(false)
            //     }, 1000)
            //     viewHeatmap(symbol, pairDepth)
            // })
        }
        mounted.current = true;
    }, []);

    // hiển thị khối lượng depth dạng line khối lượng nằm ngang
    function addVolumeLiquid(
        chart: IChartApi,
        priceLineSeries: {
            [price: number]: ISeriesApi<"Line">
        },
        price: number, volume: number,
        maxVolume: number,
        color: string,
        visible = true,
    ) {
        let lineSeries = priceLineSeries[price];
        if (!lineSeries)
            priceLineSeries[price] = lineSeries = chart.addLineSeries({
                color,
                priceLineVisible: false,
                // baseLineVisible: false,
                lastValueVisible: false,
                visible,
            })
        log({ visible }, settings?.isShowHeatmap)
        lineSeries.applyOptions({ visible })
        lineSeries.setData([])

        const from = Number(chart.timeScale().getVisibleRange().from)
        const to = Number(chart.timeScale().getVisibleRange().to)
        const timeFrom = Math.floor((to - volume * (to - from) / maxVolume) + 1);
        // log({ visible, timeFrom })
        lineSeries.setData([
            { time: timeFrom as Time, value: price },
            { time: to as Time, value: price }
        ])
    }

    useEffect(() => {
        const symbol = pair?.symbol.toLowerCase();
        if (services?.heat?.pairs[symbol]) {
            viewHeatmap(symbol, services.heat.pairs[symbol])
            setpairDepth(services.heat.pairs[symbol])
        }
    }, [
        Object.keys(services.heat?.pairs[pair?.symbol.toLowerCase()]?.asks ?? {}).length,
        Object.keys(services.heat?.pairs[pair?.symbol.toLowerCase()]?.bids ?? {}).length
    ])

    // cập nhật heatmap vào biểu đồ
    const viewHeatmap = useCallback((_symbol: string, pairDepth: PairDepth) => {

        if (pairDepth &&
            state?.heatmap?.bids?.data && state?.heatmap?.asks?.data
            && state.pair?.Klines?.length
        ) {
            const bidsDepth = Object.entries(pairDepth.bids),
                asksDepth = Object.entries(pairDepth.asks);

            let time = timeToTimezone(Number(state.pair?.Klines[state.pair?.Klines?.length - 1][CandlestickMap.OpenTime]), settings?.timezone)
            let { precision, minMove,
                isShowDrawerPriceVolume,
                // chart, liquidSeries, isVisibleLiquidSeries 
            } = state

            // ý tưởng vẽ line để biểu thị khối lượng là đúng, nhưng nhiều line quá gây nặng chương trình
            // // bid
            // const bids = Object.entries(pairDepth.bids)
            // let bidMaxVolume = bids.reduce((max, [_price, volume]) => {
            //     if (volume > max)
            //         max = volume;
            //     return max;
            // }, 0);
            // ((bidPrices: number[]) => {
            //     Object.keys(liquidSeries).forEach((price: string) => {
            //         if (!bidPrices.includes(Number(price))) {
            //             if (liquidSeries[price])
            //                 try {
            //                     chart.removeSeries(liquidSeries[price])
            //                 } catch (err) { }
            //             delete liquidSeries[price]
            //         }
            //     })
            // })(bids.map(([_price, volume]) => {
            //     const price = Number(_price)
            //     addVolumeLiquid(
            //         chart,
            //         liquidSeries,
            //         price, volume,
            //         bidMaxVolume,
            //         upColor,
            //         isVisibleLiquidSeries // settings?.isShowHeatmap
            //     )
            //     return price
            // }))

            const _priceVolumeData = []
            /* bid */
            const dataBids: any[] = bidsDepth.map(([_price, volume]) => {
                const low = Number(_price)
                if (!isNaN(low)) {
                    if (isShowDrawerPriceVolume && low > 0 && volume > 0)
                        _priceVolumeData.push({
                            price: low,
                            orderType: 'bid',
                            volume
                        })
                    let high = (low + minMove).toFixed(precision);

                    return ({
                        open: low, close: high,
                        high, low,
                        time
                    });
                }
            }).filter(v => v)
            state.heatmap.bids.data = dataBids
            state.heatmap.bids.setData(dataBids);

            /* ask */
            const dataAsks: any[] = asksDepth.map(([_price, volume]) => {
                const high = Number(_price)
                if (!isNaN(high)) {
                    if (isShowDrawerPriceVolume && high > 0 && volume > 0)
                        _priceVolumeData.push({
                            price: high,
                            orderType: 'ask',
                            volume
                        })
                    let low = (high - minMove).toFixed(precision);
                    return ({
                        open: high, close: low,
                        high, low,
                        time
                    });
                }
            }).filter(v => v)

            state.heatmap.asks.data = dataAsks
            state.heatmap.asks.setData(dataAsks);

            if (isShowDrawerPriceVolume)
                setpriceVolumeData(_priceVolumeData);

            setisConnected(services?.heat?.pairs?.[_symbol]?.exchanges?.[0]?.ws?.readyState === 1)

            // setstate({ ...state })
        }
    }, [state])

    function clearHeatmap() {
        if (pair?.symbol) {
            const symbol = pair?.symbol.toLowerCase()
            if (services?.heat?.pairs[symbol]) {
                const pair: PairDepth = services.heat.pairs[symbol];
                // const datas = {
                //     heatmap: {
                //         asks: state.heatmap?.asks?.data,
                //         bids: state.heatmap?.bids?.data
                //     },

                //     pair: {
                //         asks: pair.asks?.data,
                //         bids: pair.bids?.data
                //     }
                // }

                // delete pair.asks
                // delete pair.bids

                state.heatmap.bids.setData([]);
                state.heatmap.asks.setData([]);
                // delete state.heatmap.asks.data
                // delete state.heatmap.bids.data

                pair.asks = {};
                pair.bids = {};

                pair.sumVolumeAsk = 0;
                pair.sumVolumeBid = 0;

                // delete datas.heatmap;
                // delete datas.pair;
            }
        }
    }

    useEffect(() => {
        showChart(['1'])
        if (points) {
            const timeframes = Object.keys(points).map(tf => ({ label: tf, value: tf, visible: true })).filter(v => v.value !== pair?.minTimeframe)
            setTimeframes(timeframes)
            setcheckedTimeframes(timeframes.map(v => v.value))
        }
    }, [points, pair, subscribe, settings.timezone.offset])

    useEffect(() => {
        switch (DataTypes) {
            case "PairPointTypes":
                setdataTypes([...settings?.altcoinSeasonPair?.dataTypes])
                break;

            default:
                setdataTypes([...settings?.altcoinSeason?.dataTypes])
                break;
        }

    }, [settings?.altcoinSeason?.dataTypes, settings?.altcoinSeasonPair?.dataTypes])

    useEffect(() => {
        if (DataTypes === "PairPointTypes") {
            setlineGreen(settings?.altcoinSeasonPair?.min)
            setlineRed(settings?.altcoinSeasonPair?.max)
        } else {
            setlineGreen(settings?.altcoinSeason?.min)
            setlineRed(settings?.altcoinSeason?.max)
        }
    }, [settings?.altcoinSeasonPair?.min, settings?.altcoinSeasonPair?.max, settings?.altcoinSeason?.min, settings?.altcoinSeason?.max])

    /**
     * khi symbol mã đổi
     * cập nhật giá
     */
    useEffect(() => {
        state.pair = pair
        services.heat.getPair(pair?.symbol, true)

        if (services.heat.exchange && pair?.symbol) {
            services.heat.exchange.streamPrice(pair.symbol, (trade: MarkPrice) => {
                if (trade.s.toLowerCase() === pair.symbol.toLowerCase()) {
                    const price = Number(Number(trade.p).toFixed(state.precision + 1));

                    if (!isNaN(price)) {
                        setCurrentPrice(price);
                        const candles = state.candles.data();
                        const lastCandle = candles[candles.length - 1];
                        lastCandle.close = price;
                        const color = "#014989db";
                        lastCandle.color = lastCandle.wickColor = color;

                        if (!isNaN(lastCandle.time) && !isNaN(lastCandle.open) && !isNaN(lastCandle.high) && !isNaN(lastCandle.close) && !isNaN(lastCandle.close))
                            state.candles.update(lastCandle);
                    }
                }
            })
        }
    }, [pair?.symbol])

    // khi isShowHeatmap thay đổi, ẩn hiện heatmap
    useEffect(() => {
        state.heatmap.asks?.applyOptions({ visible: settings?.isShowHeatmap })
        state.heatmap.bids?.applyOptions({ visible: settings?.isShowHeatmap })
        // setstate({ ...state, isVisibleLiquidSeries: settings?.isShowHeatmap })
    }, [settings?.isShowHeatmap])

    /**
     * trạng thái của kết nối
     */
    useEffect(() => {
        setisConnected(services?.heat?.pairs?.[pair?.symbol?.toLowerCase()]?.exchanges?.[0]?.ws?.readyState === 1)
    }, [services?.heat?.pairs?.[pair?.symbol?.toLowerCase()]?.exchanges?.[0]?.ws?.readyState])

    async function toggleVisibleLimitDepthPrices() {
        const symbol = pair?.symbol.toLowerCase();
        let key = `limitDepthPrices.${symbol}`;
        const value = settings?.limitDepthPrices[symbol]?.active ?? false;

        let p = settings?.limitDepthPrices[symbol];

        // nếu chưa có thì thêm vào
        if (!p) {
            p = {
                active: !value,
                min: -Infinity, // %
                max: Infinity,  // %}
            }
            await dispatch(change({
                [key]: p
            }));
        }
        else {
            key += '.active'
            await dispatch(change({ [key]: !value }))
        }
    }

    async function changeLimitDepthPrices(name: 'min' | 'max', value: number) {
        log(name, value)
        const symbol = pair?.symbol.toLowerCase();
        let key = `limitDepthPrices.${symbol}`;
        let p = settings?.limitDepthPrices[symbol];
        if (!p) {
            p = {
                active: true,
                min: name === 'min' ? value : -Infinity, // %
                max: name === 'max' ? value : Infinity,  // %
            }
            await dispatch(change({
                [key]: p
            }));
        }
        else {
            key += ('.' + name)
            await dispatch(change({ [key]: value }))
        }
    }

    useEffect(() => {
        const limitDepthPrices_s = settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()];
        const limitDepthPrices = services?.heat?.limitDepthPrices;

        if (limitDepthPrices && limitDepthPrices_s) {
            limitDepthPrices.min = limitDepthPrices_s.active ? currentPrice * ((100 + limitDepthPrices_s.min) / 100) : -Infinity;
            limitDepthPrices.max = limitDepthPrices_s.active ? currentPrice * ((100 + limitDepthPrices_s.max) / 100) : Infinity;
            setstate({ ...state, count: state.count + 1 })
        }
    }, [
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.active,
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.min,
        settings?.limitDepthPrices?.[pair?.symbol.toLowerCase()]?.max,
        currentPrice
    ])

    const getLastBar = series => {
        let data = series.data()
        return data[data.length - 1];
    };
    const setTooltipHtml = (date, price) => {
        if (chartLabelRef.current)
            chartLabelRef.current.innerHTML = `
            <div style="margin: 4px 0px;">${price}</div>
            <div style="color: ${'white'}">${dayjs(date).format("DD/MM HH:mm")}</div>`;
    };

    const updateLegend = param => {
        if (state.candles && state.candles?.data && state.pair) {
            let bar = getLastBar(state.candles)
            let time = bar.time * 1000 - settings.timezone?.deviation;
            if (param && param?.seriesPrices?.size > 1) {
                param?.seriesPrices.forEach((value) => {
                    if (typeof value === "object" && Object.keys(value).every((k: string) => ["open", "high", "low", "close"].includes(k))) {
                        bar = value
                    }
                })
                time = param.time * 1000 - settings.timezone?.deviation
            }
            const price = bar.value !== undefined ? bar.value : `h:${bar.high} <br/> o:${bar.open} <br/> c:${bar.close} <br/> l:${bar.low}`;
            setTooltipHtml(time, price);
        }
    };

    const initChart = async () => {
        if (state.chart)
            return
        /** init chart */
        state.chart = createChart(chartRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            crosshair: { mode: CrosshairMode.Normal, },
            width: chartRef?.current?.clientWidth || window.innerWidth,
            height: chartRef?.current?.clientHeight || 50,
            grid: {
                vertLines: { color: '#2f2f2f' },
                horzLines: { color: '#2f2f2f' },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
            pane: 0,
            paneProperties: {
                separatorColor: "red"
            }

        });
        window.chart = state.chart

        state.rangeLines = state.chart.addLineSeries({
            color: '#ffffff45',
            lineWidth: 2,
            lineType: lineType,
            lastValueVisible: false,
            priceLineVisible: false,
            pane: 1,
        });

        state.rangeLines.minLine = state.rangeLines.createPriceLine({ price: lineGreen, color: '#008706', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        state.rangeLines.midLine = state.rangeLines.createPriceLine({ price: (lineGreen + lineRed) / 2, color: '#949494', lineWidth: 2, lineStyle: LineStyle.Dashed, axisLabelVisible: false, });
        state.rangeLines.maxLine = state.rangeLines.createPriceLine({ price: lineRed, color: '#ef5350', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        state.rangeLines.setMinMax = (min: number, max: number) => {
            state.rangeLines.minLine.applyOptions({ price: min })
            state.rangeLines.midLine.applyOptions({ price: (min + max) / 2 })
            state.rangeLines.maxLine.applyOptions({ price: max })
            const v = (min + max) / 2
            if (!isNaN(v))
                state.rangeLines.setData([{
                    value: v,
                    time: timeToTimezone(Date.now(), settings?.timezone)
                }]);
            // try {
            //     let h8s = H8s.data()
            //     h8s.forEach(v => v.open = v.close = (lineRed + lineGreen) / 2)
            //     H8s.setData(h8s)
            // } catch (err) { error(err) }
        }

        const v = (lineRed + lineGreen) / 2
        if (!isNaN(v))
            state.rangeLines.setData([{
                value: (lineRed + lineGreen) / 2,
                time: timeToTimezone(Date.now(), settings?.timezone)
            }]);

        window.addEventListener('resize', handleResize);

        state.chart.timeScale().fitContent();

        /** chart symbol, price mouse move */
        state.pair = pair;

        state.chart.subscribeCrosshairMove(updateLegend);

        updateLegend(undefined);

        setstate(state)

        return state.chart
    }

    /********************************
     * hiển thị altcoin season
     * @param {string} timeframe 
     * @param {Array} altcoinSeason 
     */
    const setData = async (timeframe: string, altcoinSeason: PairPoint[] | AltcoinSeasonPoint[]) => {

        Object.values(DataTypes === "AltcoinSeasonPointTypes" ? AltcoinSeasonPointTypes : PairPointTypes).forEach((dataType: string) => {
            let data = altcoinSeason.map((v: PairPoint | AltcoinSeasonPoint) => {
                const value = parseFloat(v?.[dataType]?.toFixed(2))
                if (!isNaN(value))
                    return ({
                        time: timeToTimezone(v.Time, settings?.timezone),
                        value
                    })
            }).filter(v => v);

            if (settings?.smooth?.type && settings?.smooth?.type !== SmoothType.None) {
                let rawPoints = SMA.calculate({ period: settings?.smooth?.period, values: data.map(v => v.value) });
                let deviation = data.length - rawPoints.length;
                for (let i = 0; i < rawPoints.length; i++) {
                    data[i + deviation].value = rawPoints[i]
                }
            }


            let lineSeries = state.lines[timeframe] ? state.lines[timeframe][dataType] : undefined;

            // nếu có thì xóa thêm lại
            if (lineSeries)
                try {
                    state.chart.removeSeries(lineSeries);
                } catch (err) { }

            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            let visible = (checkedTimeframes.includes(timeframe) && dataTypes.includes(dataType as never))

            lineSeries = state.chart.addLineSeries({
                color: TIMEFRAMES.toColor(timeframe),
                lineWidth: 2,
                lineType: lineType,
                pane: 1,
                lastValueVisible: false,
                priceLineVisible: false,
                lineStyle: PointLineStyle(dataType),
                visible: dataTypes.includes(dataType as never),
            });

            lineSeries.data = () => data
            lineSeries.setData(data)
            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            lineSeries.applyOptions({ visible })

            if (!state.lines[timeframe]) {
                state.lines[timeframe] = {};
            }
            state.lines[timeframe][dataType] = lineSeries
        })
        state.rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: timeToTimezone(Date.now(), settings?.timezone) as Time }]);
    }

    const handleResize = () => {
        if (chartRef.current)
            state.chart.applyOptions({ width: chartRef.current.clientWidth });
    };

    const zoom = (value: string) => {
        if (value === "full") {
            state.chart.priceScale().applyOptions({ autoScale: true });
            return state.chart.timeScale().fitContent();
        }
        else value = "1m";

        if (points) {
            let to = 0
            Object.values(points).forEach(p => {
                if (p[p.length - 1] && p[p.length - 1].Time > to)
                    to = p[p.length - 1].Time
            })

            let rangeTime = TIMEFRAMES.toMiliSecond(value);

            state.chart.timeScale().setVisibleLogicalRange({
                from: timeToTimezone((to - rangeTime), settings?.timezone), // 2h trước
                to: timeToTimezone(to, settings?.timezone)
            })
        }
    }

    const showChart = (key: string | string[]) => {
        if (key.length > 0) {
            setTimeout(async () => {
                if (chartRef?.current && !state.chart)
                    await initChart()

                // hiển thị đường altcoin season vào đồ thị
                if (points) {
                    Object.entries(points).forEach(([timeframe, point]) => {
                        setData(timeframe, point)
                    })
                }

                // hiển thị nến và đường trung bình vào đồ thị 
                if (pair && pair.Klines && pair.Klines.length > 0) {
                    if (DataTypes === "AltcoinSeasonPointTypes" && symbol && symbol.replace("-", "").toUpperCase() !== pair.symbol.toUpperCase()) {
                        return onSelectSymbol(symbol)
                    }

                    let data = pair.Klines.map(c => {

                        let candle = {
                            time: timeToTimezone(Number(c[CandlestickMap.OpenTime]), settings?.timezone),
                            open: Number(c[CandlestickMap.Open]),
                            high: Number(c[CandlestickMap.High]),
                            low: Number(c[CandlestickMap.Low]),
                            close: Number(c[CandlestickMap.Close]),
                        }

                        if (!isNaN(candle.time) && !isNaN(candle.open) && !isNaN(candle.high) && !isNaN(candle.close) && !isNaN(candle.close))
                            return candle;
                    }).filter(v => v)
                    // nếu nến chưa được khởi tạo thì khởi tạo mới
                    if (!state.candles) {
                        const { precision, minMove } = getPrecision(pair.Klines[pair.Klines.length - 1][CandlestickMap.Close])
                        state.precision = precision
                        state.minMove = minMove
                        state.candles = state.chart.addCandlestickSeries({
                            pane: 0,
                            priceFormat: {
                                type: 'price',
                                precision,
                                minMove,
                            },
                        })
                    }
                    if (!state.heatmap.bids) {
                        state.heatmap.bids = state.chart.addCandlestickSeries({ visible: settings?.isShowHeatmap, upColor: colors.up, downColor: colors.down, wickUpColor: colors.up, wickDownColor: colors.down, borderVisible: false });
                        state.heatmap.bids.data = []
                        window.heatmap = state.heatmap
                    }
                    if (!state.heatmap.asks) {
                        state.heatmap.asks = state.chart.addCandlestickSeries({ visible: settings?.isShowHeatmap, upColor: colors.up, downColor: colors.down, wickUpColor: colors.up, wickDownColor: colors.down, borderVisible: false });
                        state.heatmap.asks.data = []
                        window.heatmap = state.heatmap
                    }

                    if (!state.volumeSeries) {
                        state.volumeSeries = state.chart.addHistogramSeries({
                            priceFormat: {
                                type: 'volume',
                            },
                            priceScaleId: '',
                            scaleMargins: {
                                top: 0.8,
                                bottom: 0,
                            },
                        });
                        window.volumeSeries = state.volumeSeries
                        state.volumeSeries.data = []
                    }

                    state.candles.data = () => data
                    state.candles.setData(data)
                    setsymbol(`${pair?.baseAsset}-${pair?.quoteAsset}`)
                    window.chart = state.chart
                    window.candles = state.candles

                    if (!state.indicators.SMAs)
                        state.indicators.SMAs = {}
                    Object.entries(pair.smas).forEach(([timeframe, sma]) => {
                        if (!state.indicators.SMAs[timeframe])
                            // log(state.indicators.SMAs[timeframe])
                            state.indicators.SMAs[timeframe] = state.chart.addLineSeries({
                                color: TIMEFRAMES.toColor(timeframe),
                                lineWidth: 1,
                                lineType: LineType.Curve,
                                lastValueVisible: false,
                                priceLineVisible: false,
                                lineStyle: LineStyle.Solid,
                                pane: 0,
                            })

                        let data = (sma as {
                            time: number,
                            value: number
                        }[]).map(v => ({
                            time: timeToTimezone(v.time, settings?.timezone),
                            value: v.value
                        })).filter(v => !isNaN(v.time) && !isNaN(v.value))

                        state.indicators.SMAs[timeframe].data = () => data;
                        state.indicators.SMAs[timeframe].setData(data)
                    })
                    state.pair = pair
                    if (firstTime) {
                        zoom(pair.minTimeframe)
                        setfirstTime(false);
                    }
                    setstate({ ...state })
                }
            }, 500);
        }
    }

    const onChangeTimezone = (_timezone: number) => {
        TIMEZONES.findIndex((tz: TIMEZONESType) => {
            if (tz.offset === _timezone) {
                dispatch(change({ timezone: tz }))
                return true;
            }
        })
    }

    const onDataTypes = (e: { target: { value: AltcoinSeasonPointTypes | PairPointTypes; checked: boolean; }; }) => {
        let { value, checked } = e.target

        let _dataTypes = [...dataTypes]
        if (checked)
            _dataTypes.push(value)
        else
            _dataTypes.splice(_dataTypes.indexOf(value), 1)

        switch (DataTypes) {
            case "PairPointTypes":
                dispatch(change({ "altcoinSeasonPair.dataTypes": [...new Set(_dataTypes)] }))
                break;

            default:
                dispatch(change({ "altcoinSeason.dataTypes": [...new Set(_dataTypes)] }))
                break;
        }

        setdataTypes([...new Set(_dataTypes)])
        // ẩn hiện từng line
        Object.entries(state.lines).forEach(([timeframe, dataTypeLines]) => {
            dataTypeLines[value].applyOptions({ visible: checked && checkedTimeframes.includes(timeframe) })
        })
    }

    let checkAll = Timeframes.length === checkedTimeframes.length
    let indeterminate = checkedTimeframes.length > 0 && checkedTimeframes.length < Timeframes.length

    const onCheckAllTimeframes = (e: CheckboxChangeEvent) => {
        setcheckedTimeframes(e.target.checked ? Timeframes.map(v => v.value) : []);
    };

    useEffect(() => {
        Object.entries(state.lines).forEach(([timeframe, ls]) => {
            state?.indicators?.SMAs?.[timeframe]?.applyOptions({ visible: checkedTimeframes.includes(timeframe) })

            Object.entries(ls).forEach(([dataType, line]) => {
                line.applyOptions({ visible: checkedTimeframes.includes(timeframe) && dataTypes.includes(dataType) })
            })
        })
        // setcheckAll(Timeframes.length === checkedTimeframes.length)
        // setindeterminate(checkedTimeframes.length !== Timeframes.length)
        // setindeterminate(checkedTimeframes.length > 0 && checkedTimeframes.length < Timeframes.length)
    }, [checkedTimeframes])

    const onCheckTimeframes = (list: CheckboxValueType[]) => {
        setcheckedTimeframes(list)
    };

    let fullscreenchangeListened = false;
    const [isFullscreen, setisFullscreen] = useState(false)
    const fullscreen = (isExpand = false) => {
        if (chartContainerRef.current && chartRef.current) {
            if (isExpand && chartContainerRef.current) {
                chartContainerRef.current.style.width = window.innerWidth + 'px'
                chartContainerRef.current.style.height = window.innerHeight + 'px'

                chartContainerRef.current.scrollIntoView()

            } else if (!fullscreenchangeListened) {
                chartContainerRef.current.addEventListener("fullscreenchange", () => {
                    if (document.fullscreenElement) {
                        let toolsRefHeight = toolsRef?.current?.offsetHeight || toolsRef?.current?.clientHeight || 0
                        chartRef.current.style.setProperty("height", window.innerHeight - toolsRefHeight + "px")
                        state.chart.applyOptions({ height: window.innerHeight - toolsRefHeight })
                    } else {
                        chartRef.current.style.setProperty("height", "50em")
                        state.chart.applyOptions({ height: chartRef.current?.offsetHeight })
                    }
                });

                toggleFullscreen(chartContainerRef.current).then(() => {
                    setisFullscreen(document.fullscreenElement ? true : false)
                })
            }
        }
    }

    const onLineGreenRed = (name: string, value: number) => {
        let min = lineGreen
        let max = lineRed
        if (name === "lineGreen") {
            min = Number(value)
            setlineGreen(min)
        }
        if (name === "lineRed") {
            max = Number(value)
            setlineRed(max)
        }
        log(name, value, min, max, lineGreen, lineRed)
        state.rangeLines.setMinMax(min, max)
        switch (DataTypes) {
            case "PairPointTypes":
                dispatch(change({ "altcoinSeasonPair.min": min, "altcoinSeasonPair.max": max }))
                break;

            default:
                dispatch(change({ "altcoinSeason.min": min, "altcoinSeason.max": max }))
                break;
        }
    }

    const onChangeSmoothPeriod = (value: number) => {
        dispatch(change({ "smooth.period": value }))
    }

    const onChangeisShowHeatmap = (checked: boolean) => {
        dispatch(change({ "isShowHeatmap": checked }))
    }

    /**
     * làm mượt dữ liệu 
     */
    const smooth = (smoothType: string) => {
        dispatch(change({ "smooth.type": smoothType }))
        Object.entries(state.lines).forEach(([timeframe, lines]) => {
            try {
                let _points: PairPoint[] | AltcoinSeasonPoint[];
                let pairPointTypes = Object.keys(PairPointTypes);
                if (!Object.values(points)[0])
                    return;

                if (pairPointTypes.every(t => Object.values(points)[0][0][t] !== undefined))
                    _points = services?.altcoinSeason?.smooth(
                        "PairPointTypes",
                        points[timeframe],
                        settings?.smooth.period,
                        smoothType);
                else
                    _points = services?.altcoinSeason?.smooth(
                        "AltcoinSeasonPointTypes",
                        points[timeframe],
                        settings?.smooth.period,
                        smoothType);

                Object.entries(lines).forEach(([dataType, line]) => {
                    let data = _points.map(p => ({
                        time: Math.floor((p.Time + settings?.timezone.deviation) / 1000),
                        value: p[dataType]
                    })).filter(v => !isNaN(v.time) && !isNaN(v.value))
                    line.data = () => data
                    line.setData(data)
                })

            } catch (err) {
                error(err)
                message.error(err)
            }
        })
    }

    /**
     * Tính trung bình các đường cùng kiểu dữ liệu nếu đang được hiển thị
     * @param {string} dataType
     */
    const calAvg = (dataType: PairPointTypes | AltcoinSeasonPointTypes, checked: boolean) => {

        // ẩn những đường trung bình
        if (!checked) {
            if (state.avgs[dataType])
                state.avgs[dataType].applyOptions({ visible: false })
            return;
        }

        let datas: ({ time: number, value: number }[])[] = []
        // lấy dữ liệu các timeframe mà đang được hiển thị
        Object.values(state.lines).forEach(timeframeLines => {
            Object.entries(timeframeLines).forEach(([_dataType, line]) => {
                if (dataType === _dataType && line.options().visible == true) {
                    // lấy các dòng dữ liệu đó và đưa vào mảng tính
                    datas.push(line.data())
                }
            })
        })

        // tạo đường kẻ
        if (!state.avgs[dataType])
            state.avgs[dataType] = state.chart.addLineSeries({
                color: TIMEFRAMES.toColor(dataType),
                lineWidth: 3,
                lineType: lineType,
                pane: 1,
                lastValueVisible: false,
                priceLineVisible: false,
                lineStyle: PointLineStyle(dataType),
                visible: true,
            });

        state.avgs[dataType].applyOptions({ visible: true })

        if (checked && datas.length > 0) {
            // độ dài của dòng dữ liệu nhiều nhất
            let len = 0
            datas.forEach(data => {
                data.reverse()
                if (len < data.length)
                    len = data.length
            })
            let avgData: { time: number, value: number }[] = []
            for (let i = 0; i < len; i++) {
                let time = 0, countTimeframe = 0

                // tính tổng / số timeframe đang hiển thị
                let value = datas.reduce((s, v) => {
                    if (v[i]) {
                        s += v[i].value;
                        time = v[i].time;
                        countTimeframe++;
                    }
                    return s
                }, 0) / countTimeframe;
                if (time !== 0)
                    avgData.unshift({ time, value })
            }

            state.avgs[dataType].setData(avgData.filter(v => !isNaN(v.time) && !isNaN(v.value)))
        }
    }

    const onSelectSymbol = (symbol: string) => {
        window.location.href = symbol
        // let pair = services?.altcoinSeason?.Pairs[symbol.replace("-", "")]
        // if (pair) {
        //     setsymbol(symbol)
        //     let Klines = pair?.Klines

        //     let data = Klines.map(c => {

        //         let candle = {
        //             time: timeToTimezone(Number(c[CandlestickMap.OpenTime]), settings?.timezone),
        //             open: Number(c[CandlestickMap.Open]),
        //             high: Number(c[CandlestickMap.High]),
        //             low: Number(c[CandlestickMap.Low]),
        //             close: Number(c[CandlestickMap.Close]),
        //         }

        //         if (!isNaN(candle.time) && !isNaN(candle.open) && !isNaN(candle.high) && !isNaN(candle.close) && !isNaN(candle.close))
        //             return candle;
        //     })
        //     // let last = dayjs(Klines[Klines.length - 1][CandlestickMap.OpenTime]).format("DD/MM HH:mm")

        //     state.candles.data = () => data
        //     state.candles.setData(data)

        //     Object.entries(pair.smas).forEach(([timeframe, sma]) => {
        //         let data = sma.map(v => ({
        //             time: timeToTimezone(v.time, settings?.timezone),
        //             value: v.value
        //         }))

        //         state.indicators.SMAs[timeframe].data = () => data;
        //         state.indicators.SMAs[timeframe].setData(data);

        //     })
        // } else {
        //     message.error(symbol + " " + t("Symbol is not exist"))
        // }
    }

    function toggleVisibleCandles() {
        setstate({ ...state, isVisibleCandles: !state.isVisibleCandles })
        state.candles?.applyOptions({ visible: !state.isVisibleCandles })
    }



    // resizable
    const [maxWidth, setMaxWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateMaxWidth = () => {
            if (chartContainerRef.current && chartContainerRef.current.parentElement) {
                setMaxWidth(chartContainerRef.current.parentElement.offsetWidth);
            }
        };

        window.addEventListener('resize', updateMaxWidth);
        updateMaxWidth();

        return () => window.removeEventListener('resize', updateMaxWidth);
    }, []);

    const startResize = (e: any, direction: ResizeDirection) => {
        log(direction)
        e.preventDefault();
        const onMouseMove = (event: MouseEvent) => resize(event, direction);
        const onMouseUp = () => {
            document.removeEventListener('touchmove', onMouseMove);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('touchend', onMouseUp);
        };

        document.addEventListener('touchmove', onMouseMove);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchend', onMouseUp);
        document.addEventListener('mouseup', onMouseUp);
    };

    const resize = (e: MouseEvent, direction: string) => {
        if (chartContainerRef.current) {
            if (direction === 'corner' || direction === 'bottom') {
                const newHeight = e.clientY - chartContainerRef.current.getBoundingClientRect().top;
                if (newHeight > 0) {
                    chartContainerRef.current.style.height = `${newHeight}px`;
                    chartContainerRef.current.style.height = `${newHeight}px`;
                }
            }

            if (direction === 'corner') {
                const newWidth = e.clientX - chartContainerRef.current.getBoundingClientRect().left;
                if (newWidth > 0 && newWidth <= maxWidth) {
                    chartContainerRef.current.style.width = `${newWidth}px`;
                    chartContainerRef.current.style.width = `${newWidth}px`;
                }
            }
        }
    };

    useEffect(() => {
        if (state.chart && chartContainerRef.current?.offsetWidth) {
            const chartWidth = chartContainerRef.current.offsetWidth
            chartRef.current.style.width = chartWidth + 'px';
            state.chart.applyOptions({ width: chartWidth });
        }
    }, [chartContainerRef.current?.offsetWidth])

    useEffect(() => {
        if (state.chart && chartContainerRef.current?.offsetHeight) {
            const chartHeight = chartContainerRef.current.offsetHeight - toolsRef.current.offsetHeight;
            chartRef.current.style.height = chartHeight + 'px';
            state.chart.applyOptions({ height: chartHeight });
        }
    }, [chartContainerRef.current?.offsetHeight])

    function toggleDrawerPriceVolume() {
        setstate({ ...state, isShowDrawerPriceVolume: !state.isShowDrawerPriceVolume })
    }

    function onMaxAsk(max: PriceVolume & { index: number; }): void {
        if (state?.heatmap?.asks) {
            const series: ISeriesApi<"Candlestick"> = state?.heatmap?.asks
            // Function to update marks
            const newMarkers: SeriesMarker<Time>[] = series.data.map((bar: CandlestickData<Time>) => {
                if (bar.open === max.price) {
                    return ({
                        time: bar.time,
                        position: 'aboveBar',
                        color: 'red',
                        shape: 'arrowDown',
                        text: '                ' + max.price.toString(),
                    }) as SeriesMarker<Time>;
                }
                return null;
            }).filter(marker => marker !== null);

            series.setMarkers(newMarkers);
        }
    }

    // tìm trong heat hiện tại, có giá đó thì thêm mark, không thì bỏ mark
    function onMaxBid(max: PriceVolume & { index: number; }): void {
        if (state?.heatmap?.bids) {
            const series: ISeriesApi<"Candlestick"> = state?.heatmap?.bids
            // Function to update marks
            const newMarkers: SeriesMarker<Time>[] = series.data.map((bar: CandlestickData<Time>) => {
                if (bar.open === max.price) {
                    return ({
                        time: bar.time,
                        position: 'belowBar',
                        color: 'green',
                        shape: 'arrowUp',
                        text: '                ' + max.price.toString(),
                    }) as SeriesMarker<Time>;
                }
                return null;
            }).filter(marker => marker !== null);

            series.setMarkers(newMarkers);
        }
    }

    // render ***************************************
    return (<>
        <Flex style={{ paddingTop: "1em", overflowX: "scroll", scrollbarWidth: 'thin', alignItems: "center" }} wrap="wrap" gap="large" ref={titleRef}>

            <Tooltip title={isFullscreen ? t("Exit Full screen") : t("Full screen")}>
                <Button type="dashed" icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={fullscreen} />
            </Tooltip>

            <Tooltip title={t("Expand")}>
                <Button type="dashed" icon={<ExpandAltOutlined />} onClick={() => fullscreen(true)} />
            </Tooltip>

            {points ? Object.entries(points).map(([timeframe, points], i) => {
                let value = Number(points[points.length - 1]?.Performed || points[points.length - 1]?.volume)
                let count = value?.toFixed(0);

                let color = value > (settings?.altcoinSeasonPair?.max || 85) ? "red" : (value < (settings?.altcoinSeasonPair?.min || 15) ? "green" : "orange")
                if (DataTypes === "AltcoinSeasonPointTypes")
                    color = value > (settings?.altcoinSeason?.max || 85) ? "red" : (value < (settings?.altcoinSeason?.min || 15) ? "green" : "orange")

                return (<>
                    <Badge count={count} color={color} overflowCount={99999} key={"points" + i}>
                        <Avatar shape="square" size="large" style={{ backgroundColor: TIMEFRAMES.toColor(timeframe), }} key={"points_avatar" + i}>
                            {timeframe}
                        </Avatar>
                    </Badge>
                </>)
            }) : ""}

            {pair?.symbol}
            <BtnCopy value={pair?.symbol} />
            {pair?.symbol ? (<>
                <a href={"/pairs/" + pair.baseAsset + "-" + pair.quoteAsset} target="_blank"><FundViewOutlined style={{ fontSize: 30 }} /></a>
            </>) : ""}

            {exchangeButtons ? exchangeButtons.map(v => v) : ""}
        </Flex>

        <Collapse defaultActiveKey={1} items={[{
            key: 1,
            label: <><LineChartOutlined /> {t("Chart")} {pair?.symbol}</>,
            children:
                <div className="resizable" ref={chartContainerRef} style={{ maxWidth, minHeight: `${50 + 1}em` }}>
                    {/* chart label */}
                    <div className='chartLabel'>
                        <div>
                            {services?.altcoinSeason?.Pairs ?
                                <Select options=
                                    {allSymbols && allSymbols.length
                                        ? allSymbols.map((s: any) =>
                                            ({ value: `${s?.baseAsset}-${s?.quoteAsset}`, label: `${s?.baseAsset}-${s?.quoteAsset}`, })
                                        )
                                        : [{ value: pair?.symbol, label: pair?.symbol, }]
                                    }
                                    onChange={onSelectSymbol}
                                    showSearch style={{ minWidth: "10em", width: "fit-content" }}
                                    value={pair?.symbol}
                                />
                                : ""}&nbsp;
                            {state.isVisibleCandles ? <EyeInvisibleOutlined onClick={toggleVisibleCandles} /> : <EyeOutlined onClick={toggleVisibleCandles} />} &nbsp;
                            {/* thông tin timeframe và thời gian còn lại đóng nến */}
                            {pair?.minTimeframe} -{timeframe_leftString} &nbsp;
                        </div>

                        {/* heatmap */}
                        <Space.Compact style={{ opacity: 0.7 }}>
                            <Tooltip title={t("heatmap")}>
                                <Badge dot status={isConnected ? "success" : "error"}>
                                    <Switch
                                        size='small'
                                        onChange={onChangeisShowHeatmap}
                                        checked={settings?.isShowHeatmap}
                                        checkedChildren={<BuildOutlined />}
                                        unCheckedChildren={<BuildOutlined />}
                                    />&nbsp;
                                </Badge>
                                <DeleteOutlined style={{ cursor: 'pointer' }} onClick={clearHeatmap} />
                            </Tooltip>&nbsp;

                            <Tooltip title={t("toggle Drawer Price Volume")}>
                                <Switch
                                    size='small'
                                    onChange={toggleDrawerPriceVolume}
                                    checked={state.isShowDrawerPriceVolume}
                                    checkedChildren={<BuildOutlined />}
                                    unCheckedChildren={<BuildOutlined />}
                                />
                            </Tooltip>
                        </Space.Compact>

                        {/* hiển thị thông tin nến open, close, high, low, time */}
                        <div ref={chartLabelRef} style={{ width: "fit-content" }}></div>
                    </div>

                    <div className='chartLabel' style={{ right: '0px', bottom: toolsRef.current?.offsetHeight + 'px', padding: 1 + 'px' }}>
                        <Tooltip title={isFullscreen ? t("Exit Full screen") : t("Full screen")}>
                            <Button type="dashed" icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={() => fullscreen()} size='small' />
                        </Tooltip>

                        <Tooltip title={t("Expand")}>
                            <Button type="dashed" icon={<ExpandAltOutlined />} onClick={() => fullscreen(true)} size='small' />
                        </Tooltip>
                    </div>

                    <div ref={chartRef} style={{ height: initValues.chartHeight, width: "100%" }} />

                    {/* tools */}
                    <Flex ref={toolsRef} wrap="wrap" gap="large" style={{ alignItems: "center" }}>

                        <Tooltip title={isFullscreen ? t("Exit Full screen") : t("Full screen")}>
                            <Button type="dashed" icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={() => fullscreen()} />
                        </Tooltip>

                        <Tooltip title={t("Expand")}>
                            <Button type="dashed" icon={<ExpandAltOutlined />} onClick={() => fullscreen(true)} />
                        </Tooltip>

                        {/* zoom */}
                        <Segmented options={['zoom', 'full']} onChange={zoom} />

                        {DataTypes ? Object.keys(DataTypes === "AltcoinSeasonPointTypes" ? AltcoinSeasonPointTypes : PairPointTypes).map((dataType: string) => (
                            <Checkbox onChange={onDataTypes} value={dataType}
                                checked={dataTypes.includes(dataType as never)}
                                key={dataType}>
                                {t(dataType)}</Checkbox>
                        )) : ""}

                        {/* timeframes */}
                        <Checkbox.Group value={checkedTimeframes} onChange={onCheckTimeframes}>
                            {Timeframes.map(tf => (
                                tf.value !== pair?.minTimeframe ?
                                    < Tooltip title={"MA " + TIMEFRAMES.toPeriod(tf.value, pair?.minTimeframe)} >
                                        <Checkbox value={tf.value} style={{ color: TIMEFRAMES.toColor(tf.value) }}>
                                            {tf.label}
                                        </Checkbox>
                                    </Tooltip>
                                    : undefined
                            )).filter(v => v)}
                        </Checkbox.Group>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllTimeframes} checked={checkAll}>
                            {t("all")}
                        </Checkbox>

                        {/* Tính trung bình những đường đang hiển thị */}
                        <Menu items={[
                            {
                                label: t("Average"),
                                key: 'average',
                                // icon: <SettingOutlined />,
                                children:
                                    Object.keys(DataTypes === "AltcoinSeasonPointTypes" ? AltcoinSeasonPointTypes : PairPointTypes)
                                        .map((dataType: PairPointTypes | AltcoinSeasonPointTypes) => {
                                            return {
                                                label: <Tooltip title={`${t("Average")} ${t(dataType)}`}>
                                                    <Checkbox onChange={e => calAvg(dataType, e.target.checked)}>{t(dataType)}</Checkbox>
                                                </Tooltip>,
                                                key: dataType,
                                            }
                                        })
                            }
                        ]} />

                        {/* heatmap */}
                        <Space.Compact>
                            <Tooltip title={t("heatmap")}>
                                <Badge dot status={isConnected ? "success" : "error"}>
                                    <Switch
                                        size='small'
                                        onChange={onChangeisShowHeatmap}
                                        checked={settings?.isShowHeatmap}
                                        checkedChildren={<BuildOutlined />}
                                        unCheckedChildren={<BuildOutlined />}
                                    />&nbsp;
                                </Badge>
                                <DeleteOutlined style={{ cursor: 'pointer' }} onClick={clearHeatmap} />&nbsp;
                            </Tooltip>&nbsp;

                            <Tooltip title={t("toggle Drawer Price Volume")}>
                                <Switch
                                    size='small'
                                    onChange={toggleDrawerPriceVolume}
                                    checked={state.isShowDrawerPriceVolume}
                                    checkedChildren={<BuildOutlined />}
                                    unCheckedChildren={<BuildOutlined />}
                                />
                            </Tooltip>
                        </Space.Compact>

                        {/* làm mượt dữ liệu */}
                        <Space.Compact>
                            <Tooltip title={t("Period to smooth data")}>
                                <InputNumber
                                    placeholder={t("Smooth period")} style={{ maxWidth: "5em" }}
                                    changeOnWheel={true}
                                    value={settings?.smooth?.period || 3}
                                    onChange={onChangeSmoothPeriod}
                                />
                            </Tooltip>
                            <Segmented value={settings?.smooth?.type} options={Object.values(SmoothType).map(v => v)} onChange={smooth} />
                        </Space.Compact>

                        {/* đường giới hạn xanh đỏ */}
                        <Space.Compact>
                            <InputNumber
                                name='lineGreen' placeholder={t("Line green")}
                                changeOnWheel={true}
                                style={{ color: 'green', maxWidth: "5em" }}
                                value={lineGreen}
                                onChange={v => onLineGreenRed("lineGreen", v)}
                            />
                            <InputNumber
                                name='lineRed' placeholder={t("Line red")}
                                changeOnWheel={true}
                                style={{ color: 'red', maxWidth: "5em" }}
                                value={lineRed}
                                onChange={v => onLineGreenRed("lineRed", v)}
                            />
                        </Space.Compact>

                        {/* timezone */}
                        <Select options=
                            {TIMEZONES.map(_tz => (
                                { value: _tz.offset, label: _tz.offset + " · " + _tz.name }
                            ))}
                            onChange={onChangeTimezone} defaultValue={settings?.timezone?.offset} showSearch
                        />
                    </Flex>

                    <div className="resizer resizer-corner"
                        onMouseDown={(e) => { log('corner'); startResize(e, 'corner') }}
                        onTouchStart={(e) => { log('corner'); startResize(e, 'corner') }}
                    ></div>
                    <div className="resizer resizer-bottom"
                        onMouseDown={(e) => startResize(e, 'bottom')}
                        onTouchStart={(e) => startResize(e, 'bottom')}
                    ></div>
                </div >
            ,
        }]} onChange={showChart} style={{ padding: 0 }} />

        <Drawer
            title={<>
                {t("Price Volume")}&nbsp;
                {pair?.symbol}&nbsp;
                <BtnCopy value={pair?.symbol} />
            </>}
            placement={"left"}
            mask={false}
            onClose={toggleDrawerPriceVolume}
            open={state.isShowDrawerPriceVolume}
        >
            <Space align='baseline'>
                <Tooltip title={t("clean Price Volume")}>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: 28 }} onClick={clearHeatmap} />&nbsp;
                </Tooltip>

                {pair?.symbol ? (<>
                    <a href={"/pairs/" + pair.baseAsset + "-" + pair.quoteAsset} target="_blank"><FundViewOutlined style={{ fontSize: 30 }} /></a>
                </>) : ""}

                {exchangeButtons ? exchangeButtons.map(v => v) : ""}

                <Tooltip title={t("heatmap")}>
                    <Badge dot status={isConnected ? "success" : "error"}>
                        <Switch
                            size='small'
                            onChange={onChangeisShowHeatmap}
                            checked={settings?.isShowHeatmap}
                            checkedChildren={<BuildOutlined />}
                            unCheckedChildren={<BuildOutlined />}
                        />
                    </Badge>
                </Tooltip>
            </Space>


            <Row style={{ margin: '1em 0 5px 0' }}>
                <Switch checked={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.active} onChange={toggleVisibleLimitDepthPrices} size="small" />&nbsp;

                {t('Change price limits')}

                <QueueAnim>

                    {settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.active
                        ? <Row key='1'>

                            <Col span={12}>
                                <Form.Item
                                    label={<i style={{ color: colors.green }}>Bid</i>}
                                    style={{ padding: 0, color: colors.green }}
                                >
                                    <InputNumber name={"minDepthPrice"}
                                        value={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.min}
                                        changeOnWheel={true}
                                        style={{ width: "100%" }}
                                        formatter={(value) => BigNumber(value ?? 0).toFormat()} // 
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        suffix='%'
                                        onChange={v => changeLimitDepthPrices('min', v)}
                                    />
                                    <i>{services?.heat?.limitDepthPrices?.min.toFixed(state.precision)}</i>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={<i style={{ color: colors.red }}>Ask</i>}
                                    style={{ padding: 0, color: colors.red }}
                                >
                                    <InputNumber name={"maxDepthPrice"}
                                        value={settings?.limitDepthPrices[pair?.symbol.toLowerCase()]?.max}
                                        changeOnWheel={true}
                                        style={{ width: "100%" }}
                                        formatter={(value) => BigNumber(value ?? 0).toFormat()} // 
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        suffix='%'
                                        onChange={v => changeLimitDepthPrices('max', v)}
                                    />
                                    <i>{services?.heat?.limitDepthPrices?.max.toFixed(state.precision)}</i>
                                </Form.Item>
                            </Col>

                        </Row>
                        : null
                    }
                </QueueAnim>

            </Row>
            
            <PriceVolumeChart
                data={priceVolumeData}
                currentPrice={currentPrice}

                sumVolumeAsk={pairDepth?.sumVolumeAsk}
                sumVolumeBid={pairDepth?.sumVolumeBid}

                onMaxBid={onMaxBid}
                onMaxAsk={onMaxAsk}
            />
        </Drawer>
    </>)

}

AltcoinSeasonPointsChart.defaultProps = {
    DataTypes: "AltcoinSeasonPointTypes"
}

export default AltcoinSeasonPointsChart;