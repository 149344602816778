import { EventEmitter } from 'events';
import { v4 as uuid } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import AltcoinSeason, { StableCoins, filterDuplicateBaseAssets, FIATS } from './AltcoinSeason';
import Coinmarketcap from './Coinmarketcap';
import Exchange, { TIMEFRAMES, Kline, CandlestickMap, ExchangeName, } from './Exchanges';
import { PairInfo } from '@services/AltcoinSeasonService';
// import { w3cwebsocket } from 'websocket';

const { log, error } = console


class Bybit extends Exchange {
    name = ExchangeName.Bybit

    ws: WebSocket
    
    constructor(url = "wss://stream.bybit.com/v5/public/linear") {
        super()
        this.ws = new WebSocket(url)
        log("Start connect", url)

        this.ws.onerror = (err: any) => {
            this.isConnected = false;
            error(err);
            this.emit("error", err)
        };

        // mở websocket server
        this.ws.onopen = (e: any) => {
            this.isConnected = true
            let loop = setInterval(() => {
                if (this.ws.readyState == 1) {
                    this.emit("connected", e, this)
                    clearInterval(loop)
                    let ping = setInterval(() => {
                        this.ws.send(JSON.stringify({
                            method: 'PING',
                            id: 'PING' + Date.now(),
                        }))
                        if (this.ws.readyState === WebSocket.CLOSED)
                            clearInterval(ping)
                    }, 3000)
                }
            }, 50);
        }

        // khi có tin nhắn từ Binance thì gọi sự kiện Emitter, phân loại sự kiện theo id
        this.ws.onmessage = (msg: any) => {
            // console.log(msg.data);
            let data = JSON.parse(msg.data)
            if (data.error) {
                if (![-1099].includes(data.error.code))
                    error(data)
            }
            this.emit(data.id, data)
        }

        // Khi mất kết nối với Binance thì tắt chương trình
        this.ws.onclose = (r: any) => {
            this.isConnected = false;
            if (r.code == 1008) {
                // sentAlertTelegram("truy vấn getKlines id quá dài", "altcoinSeason", Settings)
                throw new Error("truy vấn getKlines id quá dài")
            }
            console.error("WebSocket.onclosed", r);
            this.emit("closed", r)
        }

    }

}

export default Bybit;