import { EventEmitter } from 'events';
import { SMA, } from 'technicalindicators'

import dayjs from 'dayjs';
import Exchange, { Kline, TIMEFRAMES, CandlestickMap, ORDERTYPE, } from './Exchanges';
import Coinmarketcap from './Coinmarketcap';
import Binance from './Binance';
import { percentChange } from './std';
// import { LineStyle } from 'lightweight-charts';

const { LineStyle, } = window.LightweightCharts

const { log, error } = console;

export enum AltcoinSeasonPointTypes {
    Performed = "Performed",
    VolumeChange = "VolumeChange",
    NumberOfTrades = "NumberOfTrades",
    PriceChange = "PriceChange",
}

export type AltcoinSeasonPoint = {
    Time: number
    Performed: number
    VolumeChange: number
    NumberOfTrades: number
    PriceChange: number
    Changes?: any[]
}

export enum PairPointTypes {
    changed = "changed",
    volume = "volume",
    trade = "trade",
}

export type PairPoint = {
    Time: number
    changed: number
    volume: number
    trade: number
}

export function PointLineStyle(name: string) {
    switch (name) {
        case PairPointTypes.volume:
            return LineStyle.Dashed;

        case PairPointTypes.changed:
            return LineStyle.Solid

        case PairPointTypes.trade:
            return LineStyle.Dotted

        case AltcoinSeasonPointTypes.Performed:
            return LineStyle.Solid

        case AltcoinSeasonPointTypes.NumberOfTrades:
            return LineStyle.Dotted

        case AltcoinSeasonPointTypes.VolumeChange:
            return LineStyle.Dashed


        default:
            return LineStyle.Dashed
    }
}

export const StableCoins = ['USDT', 'BUSD', 'USDC', 'DAI', 'TUSD', 'FDUSD', 'USDP', 'UST', 'USDD', 'GUSD', 'USTC', 'USDJ', 'FRAX', 'SUSD', 'AEUR']
export const FIATS = ["USD", "GBP", "EUR", "BIDR", "BRL", "RUB", "TRY", "UAH", "NGN", "ZAR", "IDRT", "PLN", "RON", "ARS"]
export const WRAPCOINS = ["WBTC", "WETH", "WBNB", "WNXM"]
/**
 *  filter Duplicate Base Assets, example: 
const symbols = ['BTCUSDT','BTCUSDT','BTCBUSD','BTCBUSD', 'ETHBUSD', 'XRPUSDT', 'BNBBUSD', 'LTCBUSD', 'ETHUSDT', 'LINKBUSD', 'XRPBUSD'];
 * @param {string[]} symbols danh sách các cặp tiền
 * @param {string[]} QuoteAssets danh sách các quote tiền /USDT, /BUSD...
 * @returns {string[]} symbols
 */
export function filterDuplicateBaseAssets(symbols: string[] = [], QuoteAssets: string[] = StableCoins): string[] {
    let matchQuoteAssets = QuoteAssets.join("|");
    // lọc trùng nhưng ưu tiên theo thứ tự 
    let filtered: any = {}
    symbols.forEach((symbol: string, index, self) => {
        let match = symbol.match(`(.+)(${matchQuoteAssets})$`)
        if (match) {
            const [base, quote] = match.slice(1);

            if (!filtered[base] || QuoteAssets.indexOf(quote) < QuoteAssets.indexOf(filtered[base]))
                filtered[base] = quote;

            return [base, quote];
        }
    })
    return Object.entries(filtered).map(([base, quote]) => base + quote)
}

export function filterDuplicateBaseAssetsNoPriority(symbols: string[], QuoteAssets = StableCoins) {
    let matchQuoteAssets = QuoteAssets.join("|");
    let pattern = `(.+)(${matchQuoteAssets})$`

    return symbols.filter((symbol: string, index, self) => {
        if (symbol && symbol.match(pattern)) {
            let matched = symbol.match(pattern)
            if (matched) {
                const [baseAsset, quoteAsset] = matched.slice(1);
                return index === self.findIndex((t) => {
                    matched = t.match(pattern)
                    if (matched) {
                        const [_baseAsset, _quoteAsset] = matched.slice(1);
                        return baseAsset === _baseAsset
                    }
                })
            }
        }
    })
}

export enum SmoothType {
    None = "None", SMA = "SMA",// EMA = "EMA", WMA = "WMA"
}

class AltcoinSeason extends EventEmitter {
    Settings: { [key: string]: any } = {}
    exchange: Exchange

    /**
     * object này chứa các cặp tiền, bên trong chứa các timeframe, mỗi timeframe sẽ chứa các nến đã được lấy từ sàn về
     */
    pairs: { [symbol: string]: { [timeframe: string]: Kline[]; }; } | any;
    coinmarketcap = new Coinmarketcap()

    /**
     * @param {any} Data dữ liệu timeframe đã truy vấn
     * là any với key là {timeframe : SymbolsKlines}
     * SymbolsKlines là any với key là {Symbol : Klines}
     */
    Data: { [timeframe: string]: { [symbol: string]: Kline[] } } = {}

    /**
     * @param {any[]} Symbols
     * Dữ liệu 24h của các đồng coin lấy từ CoinmarketCap
     */
    Symbols: { [key: string]: any }[] = []
    Dev: any;

    constructor(exchange = new Binance()) {
        super()
        this.exchange = exchange
    }

    // lưu altcoinSeason đã tính được 
    saveData(name: string, data: any[]) {
        // console.info({ "saveData": name, "length": data.length, })
        localStorage.removeItem(name)
        localStorage.setItem(name, JSON.stringify(data))
    }

    /**
     * tính Altcoin season chuẩn Holger
     * lấy Top coins và BTCUSDT
     * lấy hết nến 1m, 1h, 1d, 1w, 1M
     * số coin tăng giá có % tăng nhiều hơn BTCUSDT: n
     * tổng số lượng coin: A
     * p = n/A*100
     * @param {Object} SymbolsKlines danh sách các cặp tiền và nến
     * @param {string} timeframe khung thời gian
     * @param {Object} options thông số tùy chọn khi tính toán
     */
    calAltcoinSeasonByHolger(SymbolsKlines: { [symbol: string]: Kline[] } = {},
        timeframe: string,
        options: { amountOfTops?: number, SymbolAltcoin?: string } = { amountOfTops: 50, SymbolAltcoin: "BTCUSDT" }): AltcoinSeasonPoint[] {

        let SYMBOL = "false";
        let openTime = 0;
        let AltcoinSeasonPoints: AltcoinSeasonPoint[] = []
        // duyệt tất cả các SymbolsKlines, tìm xem cặp nào có nến mới nhất và dài nhất, đánh dấu cặp đó làm mốc chuẩn
        for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
            let Kline = Klines.length > 1 ? Klines[Klines.length - 2] : Klines[Klines.length - 1];
            if (SYMBOL === "false" || (
                // openTime < Number(Kline[CandlestickMap.OpenTime]) &&
                Klines.length > SymbolsKlines[SYMBOL].length)) {

                openTime = Number(Kline[CandlestickMap.OpenTime]);
                SYMBOL = Symbol;
            }
        }

        let LastChanges: {
            Symbol: string;
            changed: number;
            QuoteAssetVolume: number;
            volume: number;
            Close: number;
            Open: number;
            OpenTime: number;
            CloseTime: number;
            NumberOfTrades: number;
            [key: string]: any,
        }[] = []

        // duyệt các phần tử trong cặp chuẩn
        for (const _Kline of SymbolsKlines[SYMBOL]) {
            const OpenTime = Number(_Kline[CandlestickMap.OpenTime]);

            let Changes = []
            let PriceChange = 0

            let _TotalVolumeChange = 0;
            let _TotalVolume = 0;
            let TotalNumberOfTrades = 0;
            let TotalNumberOfTradesChange = 0;

            // mỗi nến, tìm nến ở các cặp Symbols khác, có chung OpenTime, đưa vào mảng và tính altcoin seasion 
            for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
                let i = Klines.findIndex(Kline => Number(Kline[CandlestickMap.OpenTime]) == OpenTime)
                if (i >= 0) {
                    // chu kì, ví dụ 5m sẽ là 5 nến 1m, lấy Close của i trừ Open của _i
                    let _i = i - TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe))

                    if (_i >= 0) {
                        let changed = percentChange(Number(Klines[i][CandlestickMap.Close]), Number(Klines[_i][CandlestickMap.Open]))
                        PriceChange += changed
                        let volume = 0
                        let QuoteAssetVolume = 0
                        let NumberOfTrades = 0
                        for (let index = _i; index <= i; index++) {
                            volume += Number(Klines[index][CandlestickMap.Volume])
                            QuoteAssetVolume += Number(Klines[index][CandlestickMap.QuoteAssetVolume])
                            NumberOfTrades += Number(Klines[index][CandlestickMap.NumberOfTrades])
                        }
                        if (changed < 0) {
                            _TotalVolumeChange += QuoteAssetVolume;
                            TotalNumberOfTradesChange += NumberOfTrades;
                        }
                        _TotalVolume += QuoteAssetVolume;
                        TotalNumberOfTrades += NumberOfTrades;

                        Changes.push({
                            "Symbol": Symbol, changed, QuoteAssetVolume, volume,
                            Close: Number(Klines[i][CandlestickMap.Close]),
                            Open: Number(Klines[_i][CandlestickMap.Open]),
                            OpenTime: Number(Klines[_i][CandlestickMap.OpenTime]),
                            CloseTime: Number(Klines[i][CandlestickMap.CloseTime]),
                            NumberOfTrades,
                        })
                    }
                }
            }

            if (Changes.length > 0) {
                // xếp giảm dần
                Changes.sort((a, b) => b.QuoteAssetVolume - a.QuoteAssetVolume);

                // lấy 50 đồng có khối lượng giao dịch lớn nhất, xếp tăng dần theo mức độ tăng giá
                // Changes = Changes.slice(0, options.amountOfTops || 50).sort((a, b) => b.changed - a.changed)
                LastChanges = [];

                let BTC = Changes.find(s => s.Symbol === (options.SymbolAltcoin || "BTCUSDT"))

                let Performed = (!BTC) ? 0 : ((Changes.reduce((count, s) => {
                    if (s.changed < BTC.changed)
                        count++;
                    return count;
                }, 1)) / Changes.length) * 100

                let VolumeChange = (_TotalVolumeChange / _TotalVolume) * 100;
                let NumberOfTrades = (TotalNumberOfTradesChange / TotalNumberOfTrades) * 100;

                if (!isNaN(Performed)) {
                    AltcoinSeasonPoints.push({
                        Time: OpenTime,
                        Performed,
                        VolumeChange,
                        NumberOfTrades,
                        PriceChange,
                        Changes: []
                    })
                }
            }
        }

        AltcoinSeasonPoints.sort((a, b) => a.Time - b.Time)
        // Tính market cap cho cái cuối cùng
        AltcoinSeasonPoints[AltcoinSeasonPoints.length - 1].Changes = LastChanges.map(s => {
            let c = this.Symbols.find(c => s.Symbol.startsWith(c.symbol))
            if (c) {
                s.marketCap = c.marketCap
            }
            return s;
        });
        this.emit("calAltcoinSeasonFinished", { timeframe: timeframe, AltcoinSeasons: AltcoinSeasonPoints })
        return AltcoinSeasonPoints;
    }


    /**
     * Làm mượt dữ liệu đã tính từ hàm calPair
     * @param {{Time, changed, volume}[]} points 
     * @param {number} period số lượng nến để làm mượt
     * @param {string} smoothType kiểu làm mượt
     * @returns {any{Time, changed, volume}[]}
     */
    smoothPoints(
        points: AltcoinSeasonPoint[],
        period: number,
        smoothType: string = SmoothType.SMA): AltcoinSeasonPoint[] {

        let Performeds, VolumeChanges, NumberOfTrades, PriceChanges;
        switch (smoothType) {
            case SmoothType.SMA:
                Performeds = SMA.calculate({ period: period, values: points.map(v => v.Performed) })
                VolumeChanges = SMA.calculate({ period: period, values: points.map(v => v.VolumeChange) })
                NumberOfTrades = SMA.calculate({ period: period, values: points.map(v => v.NumberOfTrades) })
                PriceChanges = SMA.calculate({ period: period, values: points.map(v => v.PriceChange) })

                break;

            default:
                return points;
        }

        let deviation_volumes = points.length - Performeds.length;
        let new_points: AltcoinSeasonPoint[] = points.slice(deviation_volumes).map(v => ({ Time: v.Time, Performed: 0, VolumeChange: 0, NumberOfTrades: 0, PriceChange: 0 }))

        Performeds.forEach((v, i) => {
            new_points[i].Performed = v
        })

        VolumeChanges.forEach((v, i) => {
            new_points[i].VolumeChange = v
        })

        NumberOfTrades.forEach((v, i) => {
            new_points[i].NumberOfTrades = v
        })

        PriceChanges.forEach((v, i) => {
            new_points[i].PriceChange = v
        })
        return new_points
    }


    /**
     * Tính altcoin season của 1 cặp tiền
     * @param {string} pair 
     * @param {string} minTimeframe 
     * @param {number} StartTime default 30 days
     * @param {number} EndTime default now
     * @returns 
     */
    async calPairAltcoin(pair: string = "ETHUSDT", minTimeframe: string = "1m", StartTime: number = dayjs().valueOf() - 2592000000, EndTime: number = dayjs().valueOf()): Promise<any> {
        log(pair, minTimeframe, StartTime, "-", EndTime)
        let Klines = await this.exchange.getKlines(pair, StartTime, EndTime, minTimeframe)
        if (!this.pairs[pair])
            this.pairs[pair] = { [minTimeframe]: Klines }
        else
            this.pairs[pair] = { ...this.pairs[pair], [minTimeframe]: Klines }

        return TIMEFRAMES.allUpUnits(minTimeframe).map(tf => {
            // tại mỗi thời điểm, tính xem biên thay đổi giá của nến hiện tại xếp hạng bao nhiêu so với cây nến trước đó
            let amountCandle = Math.floor(TIMEFRAMES.toMiliSecond(tf) / TIMEFRAMES.toMiliSecond(minTimeframe));
            if (amountCandle > 2)
                return {
                    name: tf,
                    points: this.calPair(Klines, amountCandle),
                    Klines: Klines,
                }
        }).filter(v => v)
    }

    /**
     * Hàm tính altcoin season 1 cặp tiền theo sự biến đổi giá và khối lượng trong số lượng cây nến
     * @param {Number[]} Klines Nến lấy từ sàn
     * @param {Number} amountCandle Số đơn vị nến dùng để tính, ví dụ 8h/5m = 160
     * @returns @param {{Time, changed, volume}[]}
     */
    calPair1(Klines: Kline[] = [], amountCandle: number = 96): PairPoint[] {
        let lastIndex = Klines.length - 1
        let toIndex = amountCandle - 2
        let points: PairPoint[] = []
        // lập danh sách nến theo thứ tự hiện tại lùi về trước
        for (let index = lastIndex; index > toIndex; index--) {
            let jTo = index - amountCandle + 1

            let count_changed = 0,
                count_volume = 1,
                volume_down = 0,
                volume_sum = 0,
                count_trade = 1;

            let mark = (Number(Klines[jTo][CandlestickMap.Open]) + Number(Klines[jTo][CandlestickMap.Close])) / 2,
                current = (Number(Klines[index][CandlestickMap.Open]) + Number(Klines[index][CandlestickMap.Close])) / 2;

            // // nếu hiện tại chênh bao nhiêu giá so với nến đầu tiên
            // let changed = Math.abs(current - mark)
            // tăng hay giảm
            let isDown = current > mark ? 1 : (-1)

            // let current_changed = percentChange(mark, current);

            let current_volume = Number(Klines[index][CandlestickMap.QuoteAssetVolume]);

            let current_trade = Number(Klines[index][CandlestickMap.NumberOfTrades]);

            // Tính RSI
            let sumUp = 0, sumDown = 0,
                lastUp = 0, lastDown = 0;

            let priceChanged = Number(Klines[index][CandlestickMap.Close]) - Number(Klines[index][CandlestickMap.Open])
            if (priceChanged > 0)
                lastUp = priceChanged
            if (priceChanged < 0)
                lastDown = priceChanged * (-1)

            // đếm nến
            for (let j = index - 1; j > jTo; j--) {
                // nếu giá của nến được xét chênh ít hơn so với nến hiện tại thì tăng 1 đếm
                // if (Math.abs(Number(Klines[j][CandlestickMap.Close]) - mark) < changed)
                //     count_changed++

                // // nếu độ lệch của cặp nến được xét nhỏ hơn cặp nên hiện tại thì tăng 1 đếm
                // if (Math.abs(Number(Klines[j][CandlestickMap.Close]) - Number(Klines[j - 1][CandlestickMap.Close])) < current_changed)
                //     count_changed++

                // nếu khối lượng USDT của nến được xét ít hơn nến hiện tại, tăng 1 đếm
                if (Number(Klines[j][CandlestickMap.QuoteAssetVolume]) < current_volume)
                    count_volume++

                // nếu giá giảm thì cộng volume 
                // nếu giá tăng thì cộng thêm phần trăm giá dịch chuyển
                if (Number(Klines[j][CandlestickMap.Close]) > Number(Klines[j][CandlestickMap.Open])) {
                    volume_down += Number(Klines[j][CandlestickMap.Volume]);
                }
                volume_sum += Number(Klines[j][CandlestickMap.Volume]);

                // Tính RSI
                let priceChanged = Number(Klines[j][CandlestickMap.Close]) - Number(Klines[j][CandlestickMap.Open])
                if (priceChanged > 0)
                    sumUp += priceChanged
                if (priceChanged < 0)
                    sumDown += priceChanged * (-1)
            }


            if (Number(Klines[jTo][CandlestickMap.QuoteAssetVolume]) < current_volume)
                count_volume++

            if (Number(Klines[jTo][CandlestickMap.NumberOfTrades]) < current_trade)
                count_trade++
            let _changed = percentChange(mark, current) // count_changed / (amountCandle - 1) * 100 * isDown;

            // let _volume = count_volume / amountCandle * 100 * isDown;
            let _volume = volume_down / volume_sum * 100

            let rsi = 100 - 100 / (1 + (((sumUp + lastUp) / amountCandle) / ((sumDown + lastDown) / amountCandle)));

            // sắp xếp theo thứ tự giảm dần
            let point: PairPoint = {
                Time: <number>Klines[index][CandlestickMap.OpenTime],

                // hiệu năng tăng giảm
                changed: _changed,

                // hiệu năng khối lượng
                volume: _volume,

                // số lượng giao dịch
                trade: rsi// count_trade / amountCandle * 100 * isDown,
            }

            points.push(point)
        }
        return points.sort((a, b) => a.Time - b.Time)
    }

    /**
     * Ví dụ Xét trong 1 đoạn 10 cây nến:
     * [ 1 2 3 4 5 6 7 8 9 10 ]
     * Cây nến đầu tiên có giá đóng là 1$
     * Xét 9 nến còn lại so với nến ban đầu
     *  Nếu cây nến đang xét đóng cửa cao hơn:
     *      - Tính % cao hơn 
     */
    calPair(Klines: Kline[] = [], amountCandle: number = 96): PairPoint[] {
        let lastIndex = Klines.length - 1
        let toIndex = amountCandle - 2
        let points: PairPoint[] = []
        // lập danh sách nến theo thứ tự hiện tại lùi về trước
        for (let index = lastIndex; index > toIndex; index--) {
            let jTo = index - amountCandle + 1

            let count_changed = 0,
                count_volume = 0;

            let mark = (Number(Klines[jTo][CandlestickMap.Open]) + Number(Klines[jTo][CandlestickMap.Close])) / 2,
                current = (Number(Klines[index][CandlestickMap.Open]) + Number(Klines[index][CandlestickMap.Close])) / 2;

            let mark_volume = Number(Klines[jTo][CandlestickMap.Volume]);

            let current_volume = Number(Klines[index][CandlestickMap.Volume]);
            const sum_volume = current_volume
                + Klines.slice(jTo, index).reduce((s, Kline) => s += Number(Kline[CandlestickMap.Volume]), 0)
            const avg_volume = sum_volume / amountCandle

            // mức thay đổi giá của nến hiện tại và nến đầu tiên
            const current_changed = percentChange(mark, current)
            const current_volume_changed = percentChange(avg_volume, current_volume)

            // Tính RSI
            let sumUp = 0, sumDown = 0,
                lastUp = 0, lastDown = 0;

            let priceChanged = Number(Klines[index][CandlestickMap.Close]) - Number(Klines[index][CandlestickMap.Open])
            if (priceChanged > 0)
                lastUp = priceChanged
            if (priceChanged < 0)
                lastDown = priceChanged * (-1)

            // đếm nến
            for (let j = index - 1; j > jTo; j--) {

                // tính giá thay đổi
                const current_ = (Number(Klines[j][CandlestickMap.Open]) + Number(Klines[j][CandlestickMap.Close])) / 2
                const current_changed_ = percentChange(mark, current_)

                if (current_changed > current_changed_)
                    count_changed++;

                // tính khối lượng thay đổi
                const current_volume_ = Number(Klines[j][CandlestickMap.Volume])
                const current_volume_changed_ = percentChange(avg_volume, current_volume_)

                if (current_volume_changed > current_volume_changed_)
                    count_volume++;

                // Tính RSI
                let priceChanged = Number(Klines[j][CandlestickMap.Close]) - Number(Klines[j][CandlestickMap.Open])
                if (priceChanged > 0)
                    sumUp += priceChanged
                if (priceChanged < 0)
                    sumDown += priceChanged * (-1)
            }

            let _changed = count_changed / (amountCandle - 1) * 100;

            let _volume = current_changed >= 0 ?
                count_volume / (amountCandle - 1) * 100
                : (amountCandle - count_volume) / (amountCandle - 1) * 100
            // count_volume / (amountCandle - 1) * 100;

            let rsi = 100 - 100 / (1 + (((sumUp + lastUp) / amountCandle) / ((sumDown + lastDown) / amountCandle)));

            // sắp xếp theo thứ tự giảm dần
            let point: PairPoint = {
                Time: <number>Klines[index][CandlestickMap.OpenTime],

                // hiệu năng tăng giảm
                changed: _changed,

                // hiệu năng khối lượng
                volume: _volume,

                // số lượng giao dịch
                trade: rsi // count_trade / amountCandle * 100 * isDown,
            }

            points.push(point)
        }
        return points.sort((a, b) => a.Time - b.Time)
    }

    /**
     * Làm mượt dữ liệu đã tính từ hàm calPair
     * @param {{Time, changed, volume}[]} points 
     * @param {number} period số lượng nến để làm mượt
     * @param {string} smoothType kiểu làm mượt
     * @returns {any{Time, changed, volume}[]}
     */
    smoothPair(
        points: PairPoint[],
        period: number,
        smoothType: string = SmoothType.SMA): PairPoint[] {

        let volumes, changeds, trades;
        switch (smoothType) {
            case SmoothType.SMA:
                volumes = SMA.calculate({ period: period, values: points.map(v => v.volume) });
                changeds = SMA.calculate({ period: period, values: points.map(v => v.changed) });
                trades = SMA.calculate({ period: period, values: points.map(v => v.trade) });

                break;

            default:
                return points;
        }

        let deviation_volumes = points.length - volumes.length;
        let new_points: PairPoint[] = points.slice(deviation_volumes).map(v => ({ Time: v.Time, volume: 0, changed: 0, trade: 0 }))
        volumes.forEach((v, i) => {
            new_points[i].volume = v
        })

        changeds.forEach((v, i) => {
            new_points[i].changed = v
        })

        trades.forEach((v, i) => {
            new_points[i].trade = v
        })
        return new_points
    }

    /**
     * tính altcoin season của 1 cặp tiền khi đóng nến, tự động tính luôn những timeframe cùng đơn vị nhỏ nhất
     * @param {string} pair 
     * @param {string} timeframe 
     * @param {number} durring default 6 tháng, độ dài để tính toán
     */
    calPairWhenCloseKlineContinuous(pair: string = "ETHUSDT", minTimeframe: string = "1d", durring: number = 15552000000) {
        return this.timer(minTimeframe, async () => {
            let Klines = this.pairs[pair]?.[minTimeframe]
            let now = Date.now()
            if (!Klines) {
                await this.calPairAltcoin(pair, minTimeframe, now - durring, now)
                Klines = this.pairs[pair][minTimeframe]
            }
            now = Date.now()
            let last = Klines[Klines.length - 1][CandlestickMap.OpenTime];

            // nếu thời gian ở đoạn cuối mà chưa gần với hiện tại thì lấy dữ liệu mới bổ sung vào
            if (last + TIMEFRAMES.toMiliSecond(minTimeframe) < now) {
                // lấy dữ liệu mới
                let klines = await this.exchange.getKlines(pair, last, now, minTimeframe)
                // ghép dữ liệu cũ
                if (Klines[Klines.length - 1][CandlestickMap.OpenTime] === klines[0][CandlestickMap.OpenTime])
                    Klines[Klines.length - 1] = klines[0]
                if (klines.length > 1)
                    for (let i = 1; i < klines.length; i++) {
                        Klines.push(klines[i]);
                    }

                // lấy các timeframe lớn hơn, tính hết
                return TIMEFRAMES.upUnits(minTimeframe).map(tf => {
                    // tại mỗi thời điểm, tính xem biên thay đổi giá của nến hiện tại xếp hạng bao nhiêu so với cây nến trước đó
                    let amountCandle = Math.floor(TIMEFRAMES.toMiliSecond(tf) / TIMEFRAMES.toMiliSecond(minTimeframe));
                    if (amountCandle > 2) {
                        let data = {
                            name: tf,
                            points: this.calPair(Klines, amountCandle),
                            Klines: Klines,
                        }
                        this.emit("calPairWhenCloseKlineContinuous", data)
                        return data;
                    }
                }).filter(v => v)
            }
        })
    }

    async calAltcoinSeasonsWhenCloseKlineByHolger(Symbols: string[], TimeFrames: { Name: string, during: number }[] = [{ Name: "5m", during: 86400000 }], options = { amountOfTops: 50 }) {
        let initTf: { [key: string]: { Name: string, during: number } } = {}
        // khi đóng nến, lọc các timeframe có cùng downUnit, tính luôn các timeframe còn lại lớn hơn cùng cấp
        let _timeframes = TimeFrames.reduce((pre, v) => {
            pre[TIMEFRAMES.downUnit(v.Name)] = v;
            return pre;
        }, initTf)
        return await Promise.all(Object.entries(_timeframes).map(async ([key, tf]) => ({
            name: tf.Name,
            loop: this.timer(key, async () => {
                let downUnit = TIMEFRAMES.downUnit(tf.Name)
                let StartTime = dayjs().subtract(tf.during, 'milliseconds').valueOf()

                let EndTime = dayjs().valueOf()
                if (Symbols.length === 0)
                    Symbols = this.Symbols.map(s => s.symbol)

                if (!Symbols.find(s => s == "BTCUSDT"))
                    Symbols.push("BTCUSDT")

                let SymbolsKlines = this.Data[downUnit]

                // nếu timeframe này chưa có Data thì lấy mới dữ liệu từ server
                if (!SymbolsKlines) {
                    SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, downUnit);
                    this.Data[downUnit] = SymbolsKlines
                } else
                    // nếu đã có dữ liệu thì bắt đầu lấy dữ liệu từ điểm thời gian cuối cùng , sau đó ghép vào 
                    try {
                        let Klines = Object.values(SymbolsKlines)[0]
                        StartTime = Number(Klines[Klines.length - 2][CandlestickMap.OpenTime]);
                        // nếu timeframe downUnit trước đó đã lấy dữ liệu rồi thì ko cần lấy dữ liệu mới nữa
                        if (EndTime - StartTime >= TIMEFRAMES.toMiliSecond(downUnit)) {
                            let _SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, downUnit);
                            for (let [Symbol, Klines] of Object.entries(_SymbolsKlines)) {
                                Klines.forEach(newItem => {
                                    const index = SymbolsKlines[Symbol].findIndex(old => { return old[CandlestickMap.OpenTime] === newItem[CandlestickMap.OpenTime] })
                                    if (index > 0)
                                        SymbolsKlines[Symbol][index] = newItem
                                    else SymbolsKlines[Symbol].push(newItem)
                                })
                            }
                        }
                    } catch (err) { }

                let AltcoinSeasons = await this.calAltcoinSeasonByHolger(SymbolsKlines, tf.Name, options)

                this.saveData(tf.Name, AltcoinSeasons)
                this.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf })

                // tính altcoin season của những timeframe lớn hơn
                TIMEFRAMES.upUnits(key).forEach(async v => {
                    let _AltcoinSeasons = await this.calAltcoinSeasonByHolger(SymbolsKlines, v, options)
                    this.saveData(v, _AltcoinSeasons)
                    this.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons: _AltcoinSeasons, SymbolsKlines, TimeFrame: { Name: v, during: tf.during } })
                })
            })
        })))
    }

    /**
     * hẹn giờ khi đóng nến thì làm gì đó
     * @param {string} Timeframe khung thời gian
     * @param {function} callback hàm thực thi
     * @returns {setInterval[]} vòng lặp
     */
    timer(Timeframe: string = TIMEFRAMES.h1, callback: Function): Promise<NodeJS.Timer> {
        return new Promise(async (rs, rj) => {
            let now = Date.now()

            // lấy cây nến cuối
            let Klines = await this.exchange.getKlines('BTCUSDT', now - TIMEFRAMES.toMiliSecond(Timeframe), now, Timeframe, 1);

            if (Klines.length > 0) {
                let openTime = Number(Klines[Klines.length - 1][CandlestickMap.OpenTime])                
                let TimeLeft = Date.now() - openTime
                
                setTimeout(() => {
                    callback(TimeLeft, Timeframe)

                    // vòng lặp theo chu kì nến, thực thi callback
                    let loop = setInterval(() => {
                        callback(TIMEFRAMES.toMiliSecond(Timeframe), Timeframe)
                    }, TIMEFRAMES.toMiliSecond(Timeframe))
                    rs(loop);

                }, TimeLeft);

            } else rj("Error get " + Timeframe)
        })
    }

    /**
     * tính altcoin season liên tục
     * @param {Array} Symbols danh sách các cặp tiền
     * @param {Array} TimeFrames mảng các timeframes
     */
    async calAltcoinSeasonsContinuous(Symbols: Array<any> = [], TimeFrames: Array<any> = this.Settings.TimeFramesListen) {
        let _TimeFrames = TimeFrames.map(tf => {
            let StartTime = dayjs().subtract(24, "hours").valueOf(), EndTime = dayjs().valueOf()
            if (["4h", "6h", "8h"].includes(tf))
                StartTime = dayjs().subtract(7, "days").valueOf();

            return {
                Name: tf,
                StartTime: StartTime,
                EndTime: EndTime,
            }
        })
        await this.calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols, _TimeFrames);
        await this.calAltcoinSeasonsContinuous(Symbols, TimeFrames);
    }

    // nếu 30m & 6h hoặc 5m & 30m cùng mà cùng dưới 15 hoặc trên 85 thì báo động
    alertAltcoinSeasonsBuySell(timeframes = ["5m", "30m", "6h"]): void {
        // this.on("calAltcoinSeasonFinished", (r) => {
        //     if (timeframes.includes(r.timeframe)) {
        //         try {
        //             let alt = r.AltcoinSeasons.slice(-1)[0]
        //             let NowTz7 = dayjs(Date.now()).format("DD/MM hh:mm tz")

        //             let maxConsensus = 2, countConsensusBuy = 0, countConsensusSell = 0;
        //             timeframes.forEach(tf => {
        //                 if (this.AltcoinSeasonsNow[tf].AltcoinSeason < 15)
        //                     countConsensusBuy++;

        //                 if (this.AltcoinSeasonsNow[tf].AltcoinSeason > 85)
        //                     countConsensusSell++;
        //             });

        //             if (countConsensusBuy >= maxConsensus)
        //                 this.sentAlertTelegram("Tesla Xu hướng 🟢LONG ↗️ " + NowTz7, this.Dev ? "altcointest" : "altcoinSeason", this.Settings);

        //             if (countConsensusSell >= maxConsensus)
        //                 this.sentAlertTelegram("Tesla Xu hướng 🔴SHORT ↘️ " + NowTz7, this.Dev ? "altcointest" : "altcoinSeason", this.Settings);

        //             // if (alt.AltcoinSeason < 15) {
        //             //     sentAlertTelegram("Xu hướng 🟢LONG ↗️ " + r.timeframe + " = " + Math.round(alt.AltcoinSeason) + " | " + NowTz7, Dev ? "altcointest" : "altcoinSeason", Settings);
        //             // }

        //             // if (alt.AltcoinSeason > 85) {
        //             //     sentAlertTelegram("Xu hướng 🔴SHORT ↘️ " + r.timeframe + " = " + Math.round(alt.AltcoinSeason) + " | " + NowTz7, Dev ? "altcointest" : "altcoinSeason", Settings);
        //             // }
        //         } catch (error) { }
        //     }
        // })
    }

    /**
     * Lọc những altcoin seasonPair có thời gian nằm trên hoặc dưới trong thời gian dài
     * nếu nằm trên max trong amountCandle nến thì trả về SELL
     * nếu nằm trên min trong amountCandle nến thì trả về BUY
     * @param {PairPoint[]} points
     * @param {number} amountCandle số lượng nến tối thiểu
     * @param {number} min giới hạn tối thiểu
     * @param {number} max giới hạn tối đa
     */
    filterPointsByAmountAndRange(points: PairPoint[], amountCandle: number, min?: number, max?: number): ORDERTYPE {
        if (points.length < amountCandle)
            return undefined;

        let order = 0
        let start = points.length - amountCandle + 1
        for (let i = start; i < points.length; i++) {
            if (points[i].volume > max && points[i].changed > max) {
                order += 1
            } else if (points[i].volume < min && points[i].volume < min) {
                order -= 1
            }
        }

        if (order === amountCandle)
            return ORDERTYPE.SELL
        if (order < 0 && Math.abs(order) === amountCandle)
            return ORDERTYPE.BUY

        return undefined;
    }
}

export default AltcoinSeason;